import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

export default function SimpleBackdrop({ open, zIndex = 111 }) {
  const useStyles = makeStyles(() => ({
    backdrop: {
      zIndex: zIndex,
      color: '#fff',
    },
  }));

  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
