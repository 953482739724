import React from "react";
import Dropzone from 'react-dropzone';
import { Backup, Close } from '@material-ui/icons';
import { DropContainer, UploadMsg } from "./styledDropzone";

export default function DropZone({accept = "", heightZone = 15, textDefault = "", handleUpload = () => {}}) {

    function renderDragMessage(isDragActive, isDragReject){
        if(!isDragActive){
            return (
                <UploadMsg height={heightZone}>
                    <p>{textDefault}</p><br/>
                    <Backup size={25} color="#c0c0c0"/>
                </UploadMsg>
            );
        }

        if(isDragReject){
            return (
                <UploadMsg height={heightZone} style={{backgroundColor: '#e57878'}}>
                    <p>Arquivo Invalido!</p><br/>
                    <Close size={25} color="#c0c0c0"/>
                </UploadMsg>
            );
        }

        return (
            <UploadMsg height={heightZone} style={{backgroundColor: '#78e5d5'}}>
                <p>Solte o arquivo!</p><br/>
                <Backup size={25}/>
            </UploadMsg>
        );
    }

    return(
        <Dropzone accept={accept} onDropAccepted={handleUpload}>
            { ({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <DropContainer { ...getRootProps() }>
                    <input { ...getInputProps() } />
                    { renderDragMessage(isDragActive, isDragReject) }
                </DropContainer>
            ) }
        </Dropzone>
    );
}