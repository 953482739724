import styled from 'styled-components';

export const DropContainer = styled.div`
    border: 1px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
`;

export const UploadMsg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
`;

export const ListContainer = styled.ul`
    margin-top: 15px;
    list-style: none;
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const FileInfo = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

export const Preview = styled.img`
    width: 36px;
    height: 36px;
    margin-right: 10px;
`;

export const TxtInfo = styled.div`
    display: flex;
    flex-direction: column;
`;