import React, { useState, Fragment } from 'react';
import qs from 'querystring';
import { FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress  from '@material-ui/core/CircularProgress';
import api from '../../services/api';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        minWidth: 24,
        color: '#222'
    }
});

export default function DrawerAddUser({ state, setState }) {
    const classes = useStyles();
    const [load, setLoad] = useState(false);
    const [disabled, setDisabled] = useState(false);
    
    const [nome, setNome] = useState('');
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [uf, setUf] = useState('PA');
    const [cidade, setCidade] = useState('');
    const [tipo, setTipo] = useState('');

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    const controlInput = (event, next) => {
        if(event.key === 'Enter') document.querySelector(`#${next}`).focus();
    }

    function validUser() {
        if(usuario.length > 3 && senha.length > 2){
            return true;
        }else{
            alert('USUARIO EOU SENHA INVALIDO!');
            return false;
        }
    }

    async function saveData() {
        setDisabled(true);
        setLoad(true);
        const valid = validUser();
        if(valid){
            const login = qs.stringify({ user: usuario, senha });
            const analise = await api.post(`/login/login`, login);
            if(analise.status === 200 && analise.data){
                if(analise.data.empresa.id || analise.data.painel.id_painel){
                    alert('USUARIO JÁ EXISTE');
                }else{
                    const data = { nome, usuario, senha, uf, cidade, tipo };
                    const response = await api.post(`/painel/addUser`, qs.stringify(data));
                    if(response.status === 200){
                        alert('DADOS GRAVADOS!');
                        setState(false);
                    }
                }
            }else{
                alert('FALHA NA INTERNET');
            }
        }
        setDisabled(false);
        setLoad(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Dados da empresa" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="NOME"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        onKeyPress={(e) => controlInput(e, 'uf')}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="UF"
                        id="uf"
                        value={uf}
                        onChange={(e) => setUf(e.target.value)}
                        onKeyPress={(e) => controlInput(e, 'cidade')}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="CIDADE"
                        id="cidade"
                        value={cidade}
                        onChange={(e) => setCidade(e.target.value)}
                        onKeyPress={(e) => controlInput(e, 'tipo')}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="TIPO"
                        id="tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.target.value)}
                        onKeyPress={(e) => controlInput(e, 'usuario')}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="USUARIO"
                        id="usuario"
                        value={usuario}
                        onChange={(e) => setUsuario(e.target.value)}
                        onKeyPress={(e) => controlInput(e, 'senha')}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        id="senha"
                        variant="outlined"
                        type="password"
                        size="small"
                        label="SENHA"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        onKeyPress={(e) => controlInput(e, 'buscar')}
                    />
                </ListItem>
                <ListItem>
                    <Button 
                        onClick={() => saveData()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        id="buscar"
                        disabled={disabled}
                    >
                        SALVAR
                    </Button>
                </ListItem>
                {load && (
                    <ListItem>
                        <CircularProgress />
                        &nbsp; AGUARDE...
                    </ListItem>
                )}
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
