import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaTimes, FaTags } from 'react-icons/fa';
import Search from '../Search/Search.js';
import api from '../../services/api';
import { displayMoney } from '../../services/displayValue';
import { Primary, Secondary } from './styled';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320
    },
    icon: {
        minWidth: 24, 
        color: '#222',
    }
});

export default function DrawerProduto({ setProduto }) {
    const classes = useStyles();
    const [state, setState] = useState(false);
    const [rowsPerPage, setRowsPerPage] =  useState(15);
    const [filtro, setFiltro] = useState('');
    const [produtos, setProdutos] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        const infoEmpresa = JSON.parse(sessionStorage.getItem('infoEmpresa'));
        const response = await api.get(`/produtos/listProdutos/${infoEmpresa.id}`);
        if(response.data[0].id) setProdutos(response.data);
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    const selectProd = (prod) => {
        setProduto(prod);
        setState(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Estoque de Produtos" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <Search 
                        label="Buscar"
                        autoFocus
                        onChange={(e) => {
                            const { value } = e.target;
                            if(value.length > 2 || value === '') setFiltro(value.toUpperCase());
                        }}
                    />
                </ListItem>

                {produtos.filter((prod) => prod.descricao.includes(filtro))
                .slice(0, rowsPerPage).map((prod, index) => (
                    <ListItem button key={index} alignItems="flex-start" onClick={() => selectProd(prod)}>
                        <ListItemIcon className={classes.icon}> <FaTags /> </ListItemIcon>
                        <ListItemText disableTypography>
                            <Primary>
                                {prod.descricao}
                                {/* {`
                                    ${prod.descricao.substring(0,25)}
                                    ${(prod.descricao.length > 25) ? '...': ''}
                                `} */}
                            </Primary>
                            <Secondary>
                                {`R$ ${displayMoney(prod.preco)}`}
                            </Secondary>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
            { filtro === '' && rowsPerPage < produtos.length && (
                <Button 
                    onClick={() => setRowsPerPage(rowsPerPage + 15)}
                    fullWidth 
                    variant="contained" 
                    color="primary"
                >
                    MOSTRAR MAIS
                </Button>
            )}
        </div>
    );

    return (
        <Fragment>
            <Button 
                fullWidth 
                variant="contained" 
                color="primary"
                onClick={toggleDrawer(true)}
            > 
                Adicionar Produto 
            </Button>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
