import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alert/Alert';
import { ValidCadastro } from '../../services/validForms';
import { maskCEP, maskFone } from '../../services/mask';
import api, { apiCnpj } from '../../services/api';
import IncialForm from './IncialForm';
import DadosEmpresa from './DadosEmpresa';
import Review from './Review';
import { removeMask } from '../../services/displayValue';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.renovetecnologia.com">
                Renove Tecnologia
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function CadastroEmpresa() {
    const classes = useStyles();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(0);
    const [openLoad, setOpenLoad] = useState(false);
    
    const stateTipoPessoa = useState('1');
    const stateCnpj = useState('');
    const stateRazao = useState('');
    const stateFantasia = useState('');
    const stateIe = useState('');
    const stateResponsavel = useState('');
    const stateCpf = useState('');
    const stateEndereco = useState('');
    const stateBairro = useState('');
    const stateNumero = useState('');
    const stateComplemento = useState('');
    const stateCep = useState('');
    const stateCodigouf = useState('15');
    const stateCidade = useState("");
    const stateTelefone = useState('');
    const stateEmail = useState('');
    const stateCpfCnpjContador = useState('');
    const stateRevenda = useState('');
    const stateSenha = useState('');

    const [id_empresa, setIdEmpresa] = useState(null);
    const steps = ['CNPJ', 'Dados da Empresa', 'Dados Fiscais'];

    const statecrt = useState('1');
    const statecfop = useState({cfop: '5102', natureza: 'VENDA MERC ADQUIRIDA/RECEBIDA TERCEIRO'});
    const statecsosn = useState('102');
    const statecst = useState('40');
    const stateorigem = useState('0');
    const statenextnfe = useState('1');
    const statenextnfce = useState('1');
    const statetoken = useState('');
    const stateambiente = useState('1');
    const stateSerieNfe = useState('1');
    const stateSerieNFCe = useState('1');

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <IncialForm stateCnpj={stateCnpj} stateTipoPessoa={stateTipoPessoa} />;
            case 1:
                return(
                    <DadosEmpresa
                        stateTipoPessoa={stateTipoPessoa}
                        stateCnpj={stateCnpj}
                        stateRazao={stateRazao}
                        stateFantasia={stateFantasia}
                        stateIe={stateIe}
                        stateResponsavel={stateResponsavel}
                        stateCpf={stateCpf}
                        stateEndereco={stateEndereco}
                        stateBairro={stateBairro}
                        stateNumero={stateNumero}
                        stateComplemento={stateComplemento}
                        stateCep={stateCep}
                        stateCodigouf={stateCodigouf}
                        stateCidade={stateCidade}
                        stateTelefone={stateTelefone}
                        stateEmail={stateEmail}
                        stateCpfCnpjContador={stateCpfCnpjContador}
                        stateRevenda={stateRevenda}
                        stateSenha={stateSenha}
                    />
                );
            case 2:
                return(
                    <Review
                        tipopessoa={stateTipoPessoa[0]}
                        statecrt={statecrt}
                        statecfop={statecfop}
                        statecsosn={statecsosn}
                        statecst={statecst}
                        stateorigem={stateorigem}
                        statenextnfe={statenextnfe}
                        statenextnfce={statenextnfce}
                        statetoken={statetoken}
                        stateambiente={stateambiente}
                        stateSerieNfe={stateSerieNfe}
                        stateSerieNFCe={stateSerieNFCe}
                    />
                );
            default:
                throw new Error('Unknown step');
        }
    }

    async function consultCnpj(cnpj) {
        setOpenLoad(true);
        try {            
            const response = await apiCnpj.get(`/${removeMask(cnpj)}`);
            console.log(response);
            if(response && response.status === 200 && response.data){
                if(response.data.razao_social){
                    stateRazao[1](response.data.razao_social);
                    stateResponsavel[1](response.data.socios[0] ? response.data.socios[0].nome : "");

                    const {nome_fantasia, tipo_logradouro, logradouro, bairro, numero, cep, ddd1, telefone1, email} = response.data.estabelecimento;
                    stateFantasia[1](nome_fantasia);
                    stateEndereco[1](`${tipo_logradouro} ${logradouro}`);
                    stateBairro[1](bairro);
                    stateNumero[1](isNaN(numero) ? 0 : numero);
                    stateEmail[1](email || "");
                    maskFone(`${ddd1 || ""}${telefone1 || ""}`, stateTelefone[1]);
                    maskCEP(cep, stateCep[1]);

                    const { estado, cidade, inscricoes_estaduais } = response.data.estabelecimento;
                    stateCodigouf[1](estado.ibge_id);
                    stateCidade[1](String(cidade.ibge_id));
                    stateIe[1](inscricoes_estaduais[0] ? inscricoes_estaduais[0].inscricao_estadual : "");
                }
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoad(false);
    }

    const handleNext = async() => {
        const codCit = (stateCidade[0] === "" || stateCidade[0] === null)?"":stateCidade[0].municipio;
        const cfop = (statecfop[0] === null) ? "" : statecfop[0].cfop;
        const data = { 
            tipopessoa: stateTipoPessoa[0],
            cnpj: stateCnpj[0],
            razao: stateRazao[0], 
            ie: stateIe[0],
            endereco: stateEndereco[0], 
            bairro: stateBairro[0], 
            codigomunicipio: codCit, 
            id: null, 
            senha: stateSenha[0],
            cfop, 
            nextnfe: statenextnfe[0], 
            nextnfce: statenextnfce[0],
            serie_nfe: stateSerieNfe[0]
        };
        const valid = ValidCadastro(activeStep, data);
        if(!valid){
            if(activeStep === 0){
                setOpenLoad(true);
                const isExistsCompany = await api.post(`/empresas/infoEmpresaByCnpj`, qs.stringify({cnpj: data.cnpj}));
                console.log(isExistsCompany);
                if(isExistsCompany && isExistsCompany.status === 200){
                    const dataCompany = isExistsCompany.data;
                    if(dataCompany.length === 0){
                        if(data.tipopessoa === "1") consultCnpj(data.cnpj);
                        else setOpenLoad(false);
                        setActiveStep(activeStep + 1);
                    }else{
                        AlertInfo('error', 'Empresa já cadastrada no sistema!');
                        setOpenLoad(false);
                    }
                }
            }else if(activeStep === 1){
                setOpenLoad(true);
                let fantasia = stateFantasia[0];
                let responsavel = stateResponsavel[0];
                let cpf = stateCpf[0];
                if(stateTipoPessoa[0] === "0"){
                    fantasia = stateRazao[0];
                    responsavel = stateRazao[0];
                    cpf = stateCnpj[0];
                }
                const sql = {
                    id: null,
                    tipopessoa: stateTipoPessoa[0],
                    razao: stateRazao[0],
                    fantasia,
                    cnpj: stateCnpj[0],
                    ie: stateIe[0],
                    responsavel,
                    cpf,
                    endereco: stateEndereco[0],
                    numero: stateNumero[0],
                    bairro: stateBairro[0],
                    complemento: stateComplemento[0],
                    codigouf: stateCodigouf[0],
                    uf: stateCidade[0].uf,
                    cidade: stateCidade[0].cidades,
                    codigomunicipio: stateCidade[0].municipio,
                    cep: stateCep[0],
                    telefone: stateTelefone[0],
                    email: stateEmail[0],
                    senha: stateSenha[0],
                    cpf_cnpj_contador: stateCpfCnpjContador[0],
                    revenda: stateRevenda[0]
                }
                const response = await api.post(`/empresas/newEmpresa`, qs.stringify(sql));
                if(response.status === 200){
                    if(response.data.id_empresa){
                        setIdEmpresa(response.data.id_empresa);
                        setActiveStep(activeStep + 1);
                    }else if(response.data.error){
                        AlertInfo('error', response.data.error, '');
                    }
                }else{
                    AlertInfo('error', 'Falha na operação', '');
                }
                setOpenLoad(false);
            }else if(activeStep === 2){
                setOpenLoad(true);
                const sql = {
                    crt: statecrt[0], 
                    csosn: statecsosn[0], 
                    cst: statecst[0], 
                    origem: stateorigem[0], 
                    nextnfe: statenextnfe[0], 
                    nextnfce: statenextnfce[0], 
                    token: statetoken[0], 
                    ambiente: stateambiente[0],
                    id_empresa,
                    cfop: statecfop[0].cfop,
                    natureza: statecfop[0].natureza,
                    serie_nfe: stateSerieNfe[0],
                    serie_nfce: stateSerieNFCe[0]
                }
                const response = await api.post(`/configs/editCodigosFiscais`, qs.stringify(sql));
                if(response.status === 200){
                    const infoLogin = await api.get(`/login/login/${id_empresa}`);
                    if(infoLogin.status === 200 && infoLogin.data){
                        sessionStorage.setItem('dadosFiscais', JSON.stringify(infoLogin.data.dadosFiscais));
                        sessionStorage.setItem('dashboard', JSON.stringify(infoLogin.data.dashboard));
                        sessionStorage.setItem('dadosPagamanentoEmpresa', JSON.stringify(infoLogin.data.infoEmpresa));
                        AlertInfo('success', 'DADOS GRAVADOS COM SUCESSO!', '').then(() => {
                            history.push('/bloqueio');
                        });
                    }
                }else{
                    AlertInfo('error', 'FALHA AO GRAVAR!', '');
                    console.log(response);
                }
                setOpenLoad(false);
            }
        }else{
            AlertInfo('error', valid.error, '');
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    useEffect(() => {
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({stopLoad: true})
            window.ReactNativeWebView.postMessage(dataPost);
        }
    }, []);

    return (
        <Fragment>
            <CssBaseline />
            <SimpleBackdrop open={openLoad} />
            <AppBar position="absolute" color="default" className={classes.appBar}>
                <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                    Renove Tecnologia
                </Typography>
                </Toolbar>
            </AppBar>
            <main className={classes.layout}>
                <Paper className={classes.paper} elevation={3}>
                    <Typography component="h1" variant="h4" align="center">
                        Cadastro da Empresa
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                    <Fragment>
                        {activeStep === steps.length ? (
                            <Fragment>
                                <Typography variant="h5" gutterBottom>
                                    Thank you for your order.
                                </Typography>
                                <Typography variant="subtitle1">
                                    Your order number is #2001539. We have emailed your order confirmation, and will
                                    send you an update when your order has shipped.
                                </Typography>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {getStepContent(activeStep)}
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} className={classes.button}>
                                            Voltar
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Proximo'}
                                    </Button>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                </Paper>
                <Copyright />
            </main>
        </Fragment>
    );
}