import React, { useState, Fragment, useEffect } from 'react';
import { FaAngleLeft, FaTruck, FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Search from '../Search/Search.js';
import api from '../../services/api';
import { Primary } from './styled';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320
    },
    icon: {
        minWidth: 24, 
        color: '#222',
    }
});

export default function DrawerTransportadoraNf({ setVeiculo, setTransporte }) {
    const classes = useStyles();
    const [state, setState] = useState(false);
    const [rowsPerPage, setRowsPerPage] =  useState(15);
    const [filtro, setFiltro] = useState('');
    const [transportes, setTransportes] = useState([]);
    const [veiculos, setVeiculos] = useState([]);
    const [ativo, setAtivo] = useState({});

    useEffect(() => loadData(), []);

    async function loadData() {
        const infoEmpresa = JSON.parse(sessionStorage.getItem('infoEmpresa'));
        const response = await api.get(`/transporte/listTransportes/${infoEmpresa.id}`);
        console.log(response);
        if(response.status === 200 && response.data.transportes){
            setTransportes(response.data.transportes);
            setVeiculos(response.data.veiculos);
        }
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function selectTransp(json = {}) {
        setTransporte(ativo);
        setVeiculo(json);
        setAtivo({});
        setState(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            {!ativo.id_transporte && (
                <>
                    <List>
                        <ListItem>
                            <ListItemText primary="Transportadoras" />
                            <ListItemIcon>
                                <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem>
                            <Search 
                                label="Buscar"
                                autoFocus
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if(value.length > 2 || value === '') setFiltro(value.toUpperCase());
                                }}
                            />
                        </ListItem>

                        {transportes.filter((transp) => transp.nome.includes(filtro))
                        .slice(0, rowsPerPage).map((transp, index) => (
                            <ListItem 
                                button 
                                key={index} 
                                alignItems="flex-start" 
                                onClick={() => setAtivo(transp)}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <FaTruck />
                                </ListItemIcon>
                                <ListItemText disableTypography >
                                    <Primary>
                                        {`
                                            ${transp.nome.substring(0,25)}
                                            ${(transp.nome.length > 25) ? '...': ''}
                                        `}
                                    </Primary>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                    { filtro === '' && rowsPerPage < transportes.length && (
                        <Button 
                            onClick={() => setRowsPerPage(rowsPerPage + 15)}
                            fullWidth 
                            variant="contained" 
                            color="primary"
                        >
                            MOSTRAR MAIS
                        </Button>
                    )}
                </>
            )}

            {ativo.id_transporte && (
                <List>
                    <ListItem>
                        <ListItemText primary="Veiculo" />
                        <ListItemIcon>
                            <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                        </ListItemIcon>
                    </ListItem>
                    <ListItem>
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => setAtivo({})}
                        >
                            <FaAngleLeft />
                        </Button>
                    </ListItem>
                    {veiculos.filter(item => item.transporte_id === ativo.id_transporte).map((item, index) => (
                        <ListItem key={index} button onClick={() => selectTransp(item)} >
                            <ListItemIcon className={classes.icon}>
                                <FaTruck />
                            </ListItemIcon>
                            <ListItemText disableTypography >
                                <Primary>{ item.placa }</Primary>
                            </ListItemText>
                        </ListItem>
                    ))}
                    <ListItem button onClick={() => selectTransp({ placa: '' })} >
                        <ListItemIcon className={classes.icon}>
                            <FaTruck />
                        </ListItemIcon>
                        <ListItemText disableTypography >
                            <Primary>SEM VEICULO</Primary>
                        </ListItemText>
                    </ListItem>
                </List>
            )}
            
        </div>
    );

    return (
        <Fragment>
            <Button 
                fullWidth 
                variant="contained" 
                color="primary"
                onClick={toggleDrawer(true)}
            > 
                TRANSPORTADORA
            </Button>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
