import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SelectValueForLabel from '../../../components/Select/SelectValueForLabel';
import TableInput from '../../../components/Table/TableInput';
import DrawerParcela from '../../../components/Drawer/DrawerParcela';
import { displayNumber3, displayDataBrl, displayMoney, converterMoeda } from '../../../services/displayValue';
import { calcParcelas } from '../../../services/calc';
import { formasPagamento } from '../../../data/options';

export default function PagamentoFiscal(props) {
    const { pagamento, setPagamento, numeroParcelas, setNumeroParcelas } = props;
    const { parcelas, setParcelas, somatoria } = props;
    const [openDrawerParcela, setOpenDrawerParcela] = useState(false);
    const [infoDrawerParcela, setInfoDrawerParcela] = useState({});
    const columnsDuplicatas = [
        { id: 'n_duplicata', label: 'N°', format: displayNumber3 },
        { id: 'vencimento', label: 'VENCIMENTO', format: displayDataBrl },
        { id: 'valor_duplicata', label: 'VALOR', format: displayMoney, align: 'right' },
    ];

    useEffect(() => {
        if(pagamento === "15" || pagamento === "05"){
            if(numeroParcelas > 0){
                let newArr = [];
                const valorParcela = displayMoney(somatoria[0].total / numeroParcelas);
                const valorTotalParcelas = (converterMoeda(valorParcela) * numeroParcelas);
                let dif = (somatoria[0].total - valorTotalParcelas);
                for(var i = 1; i <= numeroParcelas; i++){
                    newArr.push({
                        n_duplicata: i,
                        vencimento: calcParcelas(i),
                        valor_duplicata: (converterMoeda(valorParcela) + dif)
                    });
                    dif = 0;
                }
                setParcelas(newArr);
            }else{
                setParcelas([]);
            }
        }
        // eslint-disable-next-line
    }, [pagamento, numeroParcelas]);

    function updateParcelas(json = {}) {
        setInfoDrawerParcela(json);
        setOpenDrawerParcela(true);
    }

    return(
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <SelectValueForLabel 
                    label="FORMA DE PAGAMENTO"
                    value={pagamento}
                    setValue={setPagamento}
                    variant="outlined" 
                    options={formasPagamento}
                    size="small"
                />
            </Grid>
            {(pagamento === "15" || pagamento === "05") && (
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            fullWidth
                            type="number"
                            label="NUMERO DE PARCELAS"
                            variant="outlined" 
                            value={numeroParcelas}
                            onChange={(e) => setNumeroParcelas(e.target.value)}
                            size="small"
                        />
                    </Grid>
                    {parcelas.length > 0 && (
                        <Grid item xs={12} sm={12}>
                            <TableInput 
                                columns={columnsDuplicatas} 
                                rows={parcelas} 
                                clickFunction={updateParcelas}
                            />
                            <DrawerParcela
                                state={openDrawerParcela}
                                setState={setOpenDrawerParcela}
                                info={infoDrawerParcela}
                                setParcelas={setParcelas}
                                valorTotal={somatoria[0].total}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}