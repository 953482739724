import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import SimpleBackdrop from '../Loading/SimpleBackdrop';
import { AlertInfo } from '../Alert/Alert';
import { speed } from '../../services/api';
import { removeMask } from '../../services/mask';

export default function ModalSendEmailNF({open, setOpen, infoNf = null, closeDrawer, configEmail }) {
    const [dadosEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [dadosFiscais] = useState(JSON.parse(sessionStorage.getItem('dadosFiscais')));
    const [openLoad, setOpenLoad] = useState(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [emails, setEmails] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [emailAdd, setEmailAdd] = useState('');
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [msgError, setMsgError] = useState("");
    // eslint-disable-next-line
    const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

    function handleAddEmail(event) {
        if(event.key === 'Enter'){
            if(emailAdd.length > 0){
                if(!regex.test(emailAdd)){
                    setIsErrorEmail(true);
                    return;
                }
                const add = {email: emailAdd.toLowerCase(), checked: true};
                setEmails([...emails, add]);
                setEmailAdd('');
                setIsErrorEmail(false);
            }
            setIsAdd(false);
        }
    }

    function handleCheckEmail(email, checked) {
        const alterEmails = emails.map(e => {
            if(e.email === email) return { email, checked };
            return e;
        });
        setEmails(alterEmails);
    }

    async function createDanfe() {
        const cnpj = removeMask(dadosEmpresa.cnpj);
        const chaveNf = infoNf.chave;
        const url = `/NFe/danfe/${infoNf.update_at}/${cnpj}/${dadosFiscais.ambiente}/${chaveNf}/1`;
        const response = await speed.get(url);
        if(response && response.status === 200){
            if(response.data && response.data.success) return true;
        }
        return false;
    }

    async function handleSendEmail() {
        if(title.length > 0 && body.length > 0){
            const emailsActive = emails.filter(e => e.checked);
            if(emailsActive.length > 0){
                setOpenLoad(true);
                const createPdf = await createDanfe();
                let icon = 'error';
                let altMsg = 'Falha na operação';
                let message = '';
                if(createPdf){
                    const emailAdressList = emailsActive.map(e => ({email: e.email, name: infoNf.nome}));
                    const { password_email, server_smtp, server_port, protocol } = configEmail;
                    const dataSend = {
                        dadosEmpresa: JSON.stringify(dadosEmpresa),
                        emailAdressList: JSON.stringify(emailAdressList),
                        data: infoNf.update_at,
                        tbAmb: dadosFiscais.ambiente,
                        chave: infoNf.chave,
                        modelo: 'NFe',
                        title, body,
                        password_email, server_port, server_smtp, protocol
                    };
                    const response = await speed.post(`/email/sendEmail`, qs.stringify(dataSend));
                    if(response && response.status === 200){
                        if(response.data && response.data.success){
                            icon = 'success';
                            altMsg = response.data.message;
                        }else if(response.data && response.data.error){
                            altMsg = response.data.message;
                            message = response.data.details;
                        }
                    }
                }else{
                    message = 'Não foram encontrados documentos para envio! Solite o suporte';
                }
                setOpen(false);
                closeDrawer(false);
                setOpenLoad(false);
                AlertInfo(icon, altMsg, message);
            }else{
                setMsgError("NENHUM E-MAIL SELECIONADO PARA ENVIO");
            }
        }else{
            setMsgError("ASSUNTO E MENSAGEM NÃO PODEM SER VAZIOS");
        }
    }

    useEffect(() => {
        if(infoNf && infoNf.email && String(infoNf.email).length > 0){
            const arrEmail = String(infoNf.email).split(',');
            const objEmail = arrEmail.map(e => ({email: e, checked: true}));
            setEmails(objEmail);
        }
    }, [infoNf]);

    useEffect(() => {
        if(configEmail && configEmail.idconfig_email){
            setTitle(configEmail.title);
            setBody(configEmail.body);
        }
    }, [configEmail, infoNf]);

    return (
        <>
            <SimpleBackdrop open={openLoad} zIndex={9999} />
            <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">DADOS DE ENVIO</DialogTitle>

                {msgError.length > 0 && (
                    <ListItem>
                        <div style={{width:'100%', background:'#a71a1a', color:'#fff', fontSize:'20pt', padding: 3}}>
                            <h6>{msgError}</h6>
                        </div>
                    </ListItem>
                )}
            
                <List>
                    <ListItem>
                        <TextField
                            label="ASSUNTO"
                            fullWidth
                            variant="outlined" 
                            value={title}
                            size='small'
                            onChange={e => setTitle(String(e.target.value).toUpperCase())}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label="MENSAGEM"
                            fullWidth
                            variant="outlined" 
                            value={body}
                            size='small'
                            onChange={e => setBody(String(e.target.value).toUpperCase())}
                        />
                    </ListItem>
                    <ListItem>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            {emails.map((email) => (
                                <FormControlLabel
                                    key={email.email}
                                    control={
                                        <Checkbox 
                                            color="primary"
                                            checked={email.checked}
                                            onChange={() => handleCheckEmail(email.email, !email.checked)}
                                        />
                                    }
                                    label={email.email}
                                />
                            ))}
                        </div>
                    </ListItem>

                    {isAdd && (
                        <ListItem>
                            <TextField
                                error={isErrorEmail}
                                label="E-mail"
                                fullWidth
                                variant="outlined" 
                                value={emailAdd}
                                size='small'
                                onChange={e => setEmailAdd(e.target.value)}
                                onKeyUp={handleAddEmail}
                                helperText={isErrorEmail ? `E-mail Incorreto` : ``}
                            />
                        </ListItem>
                    )}

                    {!isAdd && (
                        <ListItem autoFocus button onClick={() => setIsAdd(true)}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AddIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Adicional E-mail" />
                        </ListItem>
                    )}

                    <ListItem autoFocus button onClick={handleSendEmail}>
                            <ListItemAvatar>
                                <Avatar style={{backgroundColor:'#a71a1a'}}>
                                    <SendIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Enviar E-mail" />
                        </ListItem>
                </List>
            </Dialog>
        </>
    );
}
