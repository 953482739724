import moment from 'moment';

export const displayId = (id) => {
    if(String(id).length < 6) return ("000000" + id).slice(-6);
    return id;
}

export const displayNumber3 = (number) => ("000" + number).slice(-3);

export const displayMoney = (value) => {
    if(value){
        const format = Intl.NumberFormat('pt-br', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
        }).format(value);
        return format;
    }else{
        return '';
    }
}

export const converterMoeda = (value) => {
    if(value === ''){
		return 0;
	}else{
        var val = String(value);
		var real = val.replace(".","");
		var cents = real.replace(",",".");
        return Number(cents);
	}
}

export const removeMask = (mask) => {
    let regra = /[^a-z0-9]/gi;
    const value = mask.replace(regra, "");
    return value;
}

export const displayDataBrl = (data) => {
    const dt = moment(data).format('DD/MM/YYYY');
    return dt;
}