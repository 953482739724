import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from '../../services/api';

export default function AutoCompleteCfop({ value, setValue, ...rest }) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        loadCfop();
    }, []);

    async function loadCfop() {
        const response  = await api.get(`/dados/listCfop`);
        setOptions(response.data);
    }

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            options={options}
            getOptionLabel={(option) => `${option.cfop} - ${option.natureza}`}
            fullWidth
            renderInput={(params) => (
                <TextField {...params} label="CFOP" { ...rest } />
            )}
        />
    );
}
