import styled from 'styled-components';

export const TitleBar = styled.input`
    width: 100%;
    font-size: 20px;
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    color: #fff;
`;