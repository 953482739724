import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import qs from 'querystring';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import { AlertInfo, AlertConfirm } from '../../components/Alert/Alert';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import AutoCompleteCity from '../../components/Select/AutoCompleteCity';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { arrayUfCode } from '../../data/options';
import { maskCpfCnpj, maskCEP, maskFone } from '../../services/mask';
import { ValidCliente } from '../../services/validForms';
import api from '../../services/api';

const CadastroCliente = (props) => {
    const { infoCliente } = props.location.state;
    const history = useHistory();
    const [load, setLoad] = useState(false);
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [id] = useState(infoCliente.id || null);
    const [tipopessoa, setTipoPessoa] = useState(infoCliente.tipopessoa || '0');
    const [nome, setNome] = useState(infoCliente.nome || '');
    const [cpfcnpj, setCpfCnpj] = useState(infoCliente.cpfcnpj || '');
    const [ie, setIe] = useState(infoCliente.ie || '');
    const [contribuente, setContribuente] = useState(infoCliente.contribuente || '9');
    const [endereco, setEndereco] = useState(infoCliente.endereco || '');
    const [numero, setNumero] = useState(infoCliente.numero ||'');
    const [bairro, setBairro] = useState(infoCliente.bairro || '');
    const [complemento, setComplemento] = useState(infoCliente.complemento || '');
    const [arrayUf] = useState(arrayUfCode);
    const [codigouf, setCodigoUf] = useState(infoCliente.codigouf || infoEmpresa.codigouf);
    const [cidade, setCidade] = useState(infoCliente.codigomunicipio || infoEmpresa.codigomunicipio);
    const [cep, setCep] = useState(infoCliente.cep || '');
    const [telefone, setTelefone] = useState(infoCliente.telefone || '');
    const [email, setEmail] = useState(infoCliente.email || '');

    const hadleSubmit = async() => {
        const valid = ValidCliente(nome, tipopessoa, cpfcnpj, endereco, bairro, codigouf, cidade, cep);
        if(valid){
            AlertInfo('error', 'DADOS INCOMPLETOS', `${valid.error}`);
        }else{
            setLoad(true);
            const data = {
                id,
                id_empresa: infoEmpresa.id,
                tipopessoa,
                nome,
                cpfcnpj,
                ie,
                contribuente,
                endereco,
                numero,
                bairro,
                complemento,
                codigouf,
                uf: cidade.uf,
                cidade: cidade.cidades,
                codigomunicipio: cidade.municipio,
                cep,
                telefone,
                email,
            }
            api.post(`/clientes/setupCliente`, qs.stringify(data)).then(() => {
                setLoad(false);
                AlertInfo('success', 'DADOS GRAVADOS', '').then(() => history.push('/clientes'));
            }).catch((err) => {
                setLoad(false);
                AlertInfo('error', 'FALHA AO SALVAR', err);
            });
        }
    }

    const handleDeleteCliente = () => {
        let text = "A ação não podera ser mais desfeita!";
        AlertConfirm('warning', 'Deletar este cliente?', text, 'Sim Deletar', ).then((result) => {
            if (result.isConfirmed) {
                setLoad(true);
                const data = { id, id_empresa: infoEmpresa.id };
                api.post(`/clientes/deleteCliente`, qs.stringify(data)).then(() => {
                    setLoad(false);
                    AlertInfo('success', 'EXCLUIDO', '').then(() => history.push('/clientes'));
                }).catch((err) => {
                    setLoad(false);
                    AlertInfo('error', 'FALHA AO DELETAR', err);
                });
            }
        })
    }

    return(
        <Template menuActive="clientes">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                </Grid>
                <Grid item xs={false} sm={8}></Grid>
                <Grid item xs={12} sm={2}>
                    {id && (
                        <Button 
                            fullWidth 
                            variant="contained" 
                            color="primary"
                            onClick={handleDeleteCliente}
                        > 
                            <FaTrash /> &nbsp; DELETAR
                        </Button>
                    )}
                </Grid>
            </Grid>

            <br/>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <SelectValueForLabel 
                        label="TIPO PESSOA"
                        value={tipopessoa}
                        setValue={setTipoPessoa}
                        options={[
                            {value: 0, label: 'FISICA'}, 
                            {value: 1, label: 'JURIDICA'},
                            {value: 2, label: 'FISICA COM IE'}
                        ]}
                        variant="outlined" 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        autoFocus
                        fullWidth
                        label={(Number(tipopessoa) === 1) ? "RAZÃO SOCIAL" : "NOME"}
                        variant="outlined" 
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        fullWidth
                        label={(Number(tipopessoa) === 1) ? "CNPJ" : "CPF"}
                        variant="outlined" 
                        value={cpfcnpj}
                        onChange={(e) => maskCpfCnpj(e, tipopessoa, setCpfCnpj)}
                    />
                </Grid>

                {(Number(tipopessoa) > 0 && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                fullWidth
                                label="INSCRIÇÃO ESTADUAL" 
                                variant="outlined" 
                                value={ie}
                                onChange={(e) => setIe(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectValueForLabel 
                                label="CONTRIBUENTE DE ICMS"
                                value={contribuente}
                                setValue={setContribuente}
                                options={[
                                    {value: '9', label: 'NÃO CONTRIBUENTE'},
                                    {value: '1', label: 'CONTRIBUINTE'}, 
                                    {value: '2', label: 'ISENTO'},
                                ]}
                                variant="outlined" 
                            />
                        </Grid>
                    </>
                ))}

                <Grid item xs={12} sm={12}>
                    <TextField 
                        fullWidth
                        label="ENDEREÇO" 
                        variant="outlined" 
                        value={endereco}
                        onChange={(e) => setEndereco(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <TextField 
                        fullWidth
                        label="NUMERO" 
                        variant="outlined" 
                        value={numero}
                        onChange={(e) => setNumero(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField 
                        fullWidth
                        label="BAIRRO" 
                        variant="outlined" 
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField 
                        fullWidth
                        label="COMPLEMENTO" 
                        variant="outlined" 
                        value={complemento}
                        onChange={(e) => setComplemento(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <SelectValueForLabel 
                        label="UF"
                        value={codigouf}
                        setValue={setCodigoUf}
                        options={arrayUf}
                        variant="outlined" 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AutoCompleteCity 
                        codigouf={codigouf} 
                        value={cidade}
                        setValue={setCidade}
                        variant="outlined" 
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        fullWidth
                        label="CEP" 
                        variant="outlined" 
                        value={cep}
                        onChange={(e) => maskCEP(e, setCep)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField 
                        fullWidth
                        label="TELEFONE" 
                        variant="outlined" 
                        value={telefone}
                        onChange={(e) => maskFone(e, setTelefone)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        fullWidth
                        label="EMAIL" 
                        variant="outlined" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>

                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        onClick={hadleSubmit}
                    > 
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
            <SimpleBackdrop open={load} />
        </Template>
    );
}

export default CadastroCliente;