import React, { useState } from 'react';
import TopBar from '../TopBar/TopBar';
import SideBar from '../SideBar/SideBar';
import { Container, View, CloseSideBarMobile } from './Styled';

const Template = ({ menuActive, children }) => {
    var windowWidth = window.innerWidth;
    const [toggleMenu, setToggleMenu] = useState(() => {
        if(windowWidth < 769){
            return true;
        }else{
            return false;
        }
    });

    return(
        <>
            <SideBar left={toggleMenu} menuActive={menuActive} />
            <Container margin={toggleMenu}>
                <TopBar toggle={setToggleMenu} menuActive={menuActive} />
                <CloseSideBarMobile 
                    left={toggleMenu} 
                    onClick={() => setToggleMenu(true)}
                />
                <View>
                    {children}
                </View>
            </Container>
        </>
    );
}

export default Template;