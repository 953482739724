const number = /^[0-9]+$/;

export function maskCPF(e, setFunction){
    let { value } = e.target;
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara;

    if(arrayValue.length <= 14){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 3 || index === 6){
                return `.${digito}`;
            }else if(index === 9){
                return `-${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
        setFunction(mascara);
    }
}

export function maskCNPJ(e, setFunction){
    let value = "";
    if(e.target){
        value = e.target.value
    }else{
        value = e;
    }
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara;

    if(arrayValue.length <= 18){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 2 || index === 5){
                return `.${digito}`;
            }else if(index === 8){
                return `/${digito}`;
            }else if(index === 12) {
                return `-${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
        setFunction(mascara);
    }
}

export function maskCpfCnpj(e, tipopessoa, setFunction) {
    if(Number(tipopessoa) === 1){
        maskCNPJ(e, setFunction);
    }else{
        maskCPF(e, setFunction);
    }
}

export function maskCEP(e, setFunction){
    let value = "";
    if(e.target){
        value = e.target.value
    }else{
        value = e;
    }
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara;

    if(arrayValue.length <= 9){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 5){
                return `-${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
        setFunction(mascara);
    }
}

export function maskFone(e, setFunction){
    let value = "";
    if(e.target){
        value = e.target.value
    }else{
        value = e;
    }
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara;

    if(arrayValue.length <= 14){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 0){
                return `(${digito}`;
            }else if(index === 2){
                return `)${digito}`;
            }else if(index === 7) {
                return `-${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
        setFunction(mascara);
    }
}

export function maskNumber(e, setFunction) {
    let { value } = e.target;
    if(value.match(number) || value === '') setFunction(value);
}

export function maskDinheiro(e, setFunction) {
    let { value } = e.target;
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara;

    if(arrayValue.length > 0){
        arrayMask = arrayValue.filter(digito => {
            if(digito.match(number)) return digito;
            if(digito === '.' || digito === ',') return digito;
            return '';
        });
        mascara = arrayMask.join('');
        setFunction(mascara);
    }else{
        setFunction('');
    }
}

export function maskValidadeCartao(e, setFunction){
    let { value } = e.target;
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara;

    if(arrayValue.length < 8){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 2){
                return `/${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
        setFunction(mascara);
    }
}

export function maskNumberCartao(e, setFunction){
    let { value } = e.target;
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara;

    if(arrayValue.length < 20){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 4){
                return ` ${digito}`;
            }else if(index === 8){
                return ` ${digito}`;
            }else if(index === 12){
                return ` ${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
        setFunction(mascara);
    }
}

export const removeMask = (mask) => {
    let regra = /[^a-z0-9]/gi;
    const value = mask.replace(regra, "");
    return value;
}

export function maskLogin(value){
    const format = removeMask(value);
    if(format.match(number)){
        let arrayValue = format.split('');
        let arrayMask = [];
        let mascara;

        if(String(format).length === 11){
            if(arrayValue.length <= 14){
                arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
                    if(index === 3 || index === 6){
                        return `.${digito}`;
                    }else if(index === 9){
                        return `-${digito}`;
                    }else{
                        return digito;
                    }
                });
            }
        }else if(String(format).length === 14){
            if(arrayValue.length <= 18){
                arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
                    if(index === 2 || index === 5){
                        return `.${digito}`;
                    }else if(index === 8){
                        return `/${digito}`;
                    }else if(index === 12) {
                        return `-${digito}`;
                    }else{
                        return digito;
                    }
                });
            }
        }
        mascara = arrayMask.join('');
        return mascara;
    }else{
        return value;
    }
}