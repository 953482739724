import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TopBar from '../../components/TopBar/TopBar';
import { ContainerPagamento, ContainerPagamentoInterno } from './styled';

import Pagamento from './Pagamento';

export default function Bloqueio() {
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('dadosPagamanentoEmpresa')) || null);

    useEffect(() => {
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({stopLoad: true})
            window.ReactNativeWebView.postMessage(dataPost);
        }
    }, []);
    
    return(
        <ContainerPagamento>
            <TopBar
                toggle={() => {}}
                barName={infoEmpresa.fantasia}
            />
            <ContainerPagamentoInterno>
                <Grid container spacing={1}>
                    <Grid item xs={false} sm={2} />
                    <Grid item xs={12} sm={8}>
                        <Pagamento />
                    </Grid>
                    <Grid item xs={false} sm={2} />
                </Grid>
            </ContainerPagamentoInterno>
        </ContainerPagamento>
    );
}