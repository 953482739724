import styled from 'styled-components';

export const Contained = styled.div`
    width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 7px;
    position: relative;
    min-height: 40px;
    span {
        position: absolute;
        font-size: 11px;
        top: 0;
        margin-top: -8px;
        margin-left: 10px;
        background-color: #fff;
        z-index: 11;
        padding: 0 3px 0 3px;
    }
`;

export const ContainerDiv = styled.div`
    width: 100%;
    border: 2px solid #aaa;
    border-radius: 4px;
    padding: 7px;
    margin-bottom: 7px;
    & svg {
        color: #aaa;
        margin-right: 7px;
    }
`;