import React, { useState, useEffect } from 'react';
import { FaBuilding, FaSignOutAlt, FaUserCog } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Person from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import DrawerInfoEmpresa from '../../components/Drawer/DrawerInfoEmpresa';
import DrawerAddEmpresa from '../../components/Drawer/DrawerAddEmpresa';
import DrawerUsersPainel from '../../components/Drawer/DrawerUsersPainel';
import DrawerAddUser from '../../components/Drawer/DrawerAddUser';
import { AlertInfo } from '../../components/Alert/Alert';
import api from '../../services/api';
import MenuFluter from '../../components/Menu/MenuFluter';
import { ContainerPage, Body, PaperContainer, IconContainer, Title, Text, Fluter } from './styled';
import DrawerConfigUser from './DrawerConfigUser';

export default function Painel() {
    const [empresas, setEmpresas] = useState([]);
    const [allEmpresas, setAllEmpresas] = useState([]);
    const [openLoad, setOpenLoad] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [menuUser, setMenuUser] = useState(null);
    const [infoEmpresa, setInfoEmpresa] = useState(null);
    const [addEmpresa, setAddEmpresa] = useState(false);
    const [listUsers, setListUsers] = useState(false);
    const [addUser, setAddUser] = useState(false);
    const [configUser, setConfigUser] = useState(false);
    const [painel] = useState(JSON.parse(sessionStorage.getItem('painel')));
    const [pesq, setPesq] = useState('');
    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        root: { flexGrow: 1 }, title: { flexGrow: 1 },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': { backgroundColor: fade(theme.palette.common.white, 0.25) },
            marginLeft: 0, width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
            [theme.breakpoints.up('xs')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: (pesq.length > 0) ? '20ch' : '0ch',
                '&:focus': {
                    width: '20ch',
                },
            },
            [theme.breakpoints.down('xs')]: {
                width: (pesq.length > 0) ? '10ch' : '0ch',
                '&:focus': {
                    width: '10ch',
                },
            },
        },
    }));

    async function loadData() {
        setOpenLoad(true);
        const response = await api.get(`/empresas/loadEmpresas/${painel.x_api_key}`);
        console.log(response);
        if (response.status === 200 && response.data) {
            if (response.data.error) {
                AlertInfo('error', response.data.message);
            } else {
                setEmpresas(response.data);
                setAllEmpresas(response.data);
            }
        }
        setOpenLoad(false);
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    useEffect(() => {
        if (pesq.length > 0) {
            const busca = pesq.toUpperCase();
            const filter = allEmpresas.filter(item => (item.razao.includes(busca) || item.fantasia.includes(busca)));
            setEmpresas(filter);
        } else if (pesq.length === 0) {
            setEmpresas(allEmpresas);
        }
        // eslint-disable-next-line
    }, [pesq]);

    async function activeEmpresa(empresa = {}) {
        if (empresa.id) {
            setOpenLoad(true);
            const response = await api.get(`/login/login/${empresa.id}`);
            if (response.status === 200 && response.data) {
                sessionStorage.setItem('dadosFiscais', JSON.stringify(response.data.dadosFiscais));
                sessionStorage.setItem('dashboard', JSON.stringify(response.data.dashboard));
                if (response.data.revenda) sessionStorage.setItem('infoRevenda', JSON.stringify(response.data.revenda));
                else sessionStorage.removeItem('infoRevenda');
                if (Number(empresa.acesso) === 1) {
                    sessionStorage.setItem('infoEmpresa', JSON.stringify(empresa));
                    history.push('/');
                } else {
                    sessionStorage.setItem('dadosPagamanentoEmpresa', JSON.stringify(empresa));
                    history.push('/bloqueio');
                }
            }
            setOpenLoad(false);
        }
    }

    function viewEmpresa(empresa = {}) {
        if (empresa.id) {
            setInfoEmpresa(empresa);
            setOpenDrawer(true);
        }
    }

    function logout() {
        if (window && window.ReactNativeWebView) {
            const dataPost = JSON.stringify({ logout: true })
            window.ReactNativeWebView.postMessage(dataPost);
        } else {
            sessionStorage.removeItem('painel');
            history.push('/');
        }
    }

    function openConfigUser() {
        setConfigUser(true);
    }

    const arrMenuUser = [
        { icon: FaUserCog, txt: 'PERFIL', func: openConfigUser },
        { icon: FaSignOutAlt, txt: 'SAIR', func: logout }
    ];

    useEffect(() => {
        if (window && window.ReactNativeWebView) {
            const dataPost = JSON.stringify({ stopLoad: true })
            window.ReactNativeWebView.postMessage(dataPost);
        }
    }, []);

    const classes = useStyles();
    return (
        <ContainerPage>
            <div className={classes.root}>
                <AppBar position="relative">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {painel.nome}
                        </Typography>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="BUSCAR..."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                value={pesq}
                                onChange={e => setPesq(e.target.value)}
                            />
                        </div>
                        <IconButton aria-label="show 17 new notifications" color="inherit">
                            <Badge badgeContent={0} color="default">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="menu"
                            onClick={e => setMenuUser(e.currentTarget)}
                        >
                            <AccountCircle />
                        </IconButton>
                        <MenuFluter
                            anchorEl={menuUser}
                            setAnchorEl={setMenuUser}
                            arrMenu={arrMenuUser}
                        />
                    </Toolbar>
                </AppBar>
            </div>
            <Body>
                <Container>
                    <Grid container spacing={5} justify="center">
                        {empresas.map((empresa, index) => (
                            <Grid item xs={12} sm={3} key={index}>
                                <Paper elevation={3}>
                                    <PaperContainer>
                                        <IconContainer onClick={() => viewEmpresa(empresa)} is_disabled={Number(empresa.acesso) === 1 ? false : true}>
                                            <FaBuilding />
                                        </IconContainer>
                                        <Title>{empresa.razao}</Title>
                                        <Text>{empresa.cnpj}</Text>
                                        <Button
                                            variant="contained"
                                            color={Number(empresa.acesso) === 1 ? "primary" : "secondary"}
                                            fullWidth
                                            onClick={() => activeEmpresa(empresa)}
                                        >
                                            ACESSAR
                                        </Button>
                                    </PaperContainer>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Body>
            <SimpleBackdrop open={openLoad} />
            <DrawerInfoEmpresa
                infoEmpresa={infoEmpresa}
                state={openDrawer}
                setState={setOpenDrawer}
                load={setOpenLoad}
                update={loadData}
            />
            <DrawerAddEmpresa
                state={addEmpresa}
                setState={setAddEmpresa}
                setEmpresa={setInfoEmpresa}
                openDrawer={setOpenDrawer}
            />
            <DrawerUsersPainel
                state={listUsers}
                setState={setListUsers}
                setNext={setAddUser}
            />
            <DrawerAddUser
                state={addUser}
                setState={setAddUser}
            />
            <DrawerConfigUser
                state={configUser}
                setState={setConfigUser}
            />

            {Number(painel.is_master) === 0 && (
                <Fluter onClick={() => setAddEmpresa(true)}>
                    <Tooltip title="ADICIONAR EMPRESA">
                        <Fab color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Fluter>
            )}
            {Number(painel.is_master) === 1 && (
                <Fluter onClick={() => setListUsers(true)}>
                    <Tooltip title="USUARIOS">
                        <Fab color="primary" aria-label="add">
                            <Person />
                        </Fab>
                    </Tooltip>
                </Fluter>
            )}
        </ContainerPage>
    );
}