import React, { useState, Fragment, useEffect } from 'react';
import { FaBoxes, FaProjectDiagram, FaPlus, FaMinus } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaTimes } from 'react-icons/fa';
import { AlertConfirm } from '../Alert/Alert';
import { displayMoney, converterMoeda } from '../../services/displayValue';
import { Primary } from './styled';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        minWidth: 24,
        color: '#222'
    }
});

export default function DrawerProduto({ useProd, setUseProd, setArrProd, dadosFiscais }) {
    const classes = useStyles();
    const [state, setState] = useState(false);
    const [icms, setIcms] = useState(false);
    const [alicms, setAlicms] = useState('');
    const [vicms, setVicms] = useState('');
    const [baseicms, setBaseicms] = useState('');
    const [baseicmsst, setBaseIcmsST] = useState('');
    const [icmsst, setIcmsST] = useState(false);
    const [vicmsst, setVicmsST] = useState('');
    const [ipi, setIpi] = useState(false);
    const [alipi, setAlipi] = useState('');
    const [vipi, setVipi] = useState('');
    const [ipiDevolv, setIpiDevolv] = useState(false);
    const [vipidevol, setVIpiDevolv] = useState('');
    const [pipidevol, setPIpiDevolv] = useState('');
    const [pis_cofins, setPisCofins] = useState(false);
    const [ppis, setPpis] = useState('');
    const [vpis, setVpis] = useState('');
    const [pcofins, setPcofins] = useState('');
    const [vcofins, setVcofins] = useState('');
    const [openAdd, setOpenAdd] = useState(false);
    const [adicional, setAdicional] = useState('');

    const [quant, setQuant] = useState(1);
    const [valor, setValor] = useState('');
    const [descontoUnit, setDescUnit] = useState('');
    const [subtotal, setSubTotal] = useState('');
    const [descontoTotal, setDescontoTotal] = useState('');
    const [total, setTotal] = useState('');

    useEffect(() => {
        console.log(useProd);
        if (useProd.id) {
            if (useProd.isConfirmed) {
                setIcms((useProd.vicms && useProd.vicms !== '') ? true : false);
                setAlicms(displayMoney(useProd.alicms) || '');
                setVicms(displayMoney(useProd.vicms) || '');
                setBaseicms(displayMoney(useProd.baseicms) || '');

                setPisCofins((useProd.vpis && useProd.vpis !== '') ? true : false);
                setPpis(displayMoney(useProd.ppis) || '');
                setVpis(displayMoney(useProd.vpis) || '');
                setPcofins(displayMoney(useProd.pcofins) || '');
                setVcofins(displayMoney(useProd.vcofins) || '');
            } else if (dadosFiscais && Number(dadosFiscais.crt) === 3) {
                if(Number(useProd.cst) !== 60 && Number(dadosFiscais.aliq_icms_padrao) > 0){
                    setIcms(true);
                    setAlicms(displayMoney(dadosFiscais.aliq_icms_padrao));
                    setBaseicms(displayMoney(useProd.preco));
                    setVicms(displayMoney((Number(useProd.preco) * Number(dadosFiscais.aliq_icms_padrao)) / 100));
                }

                if(Number(dadosFiscais.aliq_pis_padrao) > 0 && Number(useProd.pis_confins) < 3){
                    setPisCofins(true);
                    setPpis(displayMoney(dadosFiscais.aliq_pis_padrao) || '');
                    setVpis(displayMoney((Number(useProd.preco) * Number(dadosFiscais.aliq_pis_padrao)) / 100));
                    setPcofins(displayMoney(dadosFiscais.aliq_cofins_padrao) || '');
                    setVcofins(displayMoney((Number(useProd.preco) * Number(dadosFiscais.aliq_cofins_padrao)) / 100));
                }
            }
            setIcmsST((useProd.vicmsst && useProd.vicmsst !== '') ? true : false);
            setBaseIcmsST(displayMoney(useProd.baseicmsst) || '');
            setVicmsST(displayMoney(useProd.vicmsst) || '');
            setIpi((useProd.vipi && useProd.vipi !== '') ? true : false);
            setAlipi(displayMoney(useProd.alipi) || '');
            setVipi(displayMoney(useProd.vipi) || '');
            setIpiDevolv((useProd.vipidevol && useProd.vipidevol !== '') ? true : false);
            setPIpiDevolv(displayMoney(useProd.pipidevol) || '');
            setVIpiDevolv(displayMoney(useProd.vipidevol) || '');
            setOpenAdd((useProd.adicional && useProd.adicional !== '') ? true : false);
            setAdicional(useProd.adicional || '');

            setQuant(useProd.quant || 1);
            setValor(displayMoney(useProd.valor) || displayMoney(useProd.preco));
            setDescUnit(displayMoney(useProd.desconto) || '');
            setSubTotal(useProd.subtotal || useProd.preco);
            setDescontoTotal(useProd.desconto_total || '');
            setTotal(useProd.total || useProd.preco);
            setState(true);
        }
        // eslint-disable-next-line
    }, [useProd]);

    useEffect(() => {
        if(!state){
            setIcms(false);
            setAlicms('');
            setVicms('');
            setBaseicms('');
            setBaseIcmsST('');
            setIcmsST(false);
            setVicmsST('');
            setIpi(false);
            setAlipi('');
            setVipi('');
            setIpiDevolv(false);
            setVIpiDevolv('');
            setPIpiDevolv('');
            setPisCofins(false);
            setPpis('');
            setVpis('');
            setPcofins('');
            setVcofins('');
            setOpenAdd(false);
            setAdicional('');

            setQuant(1);
            setValor('');
            setDescUnit('');
            setSubTotal('');
            setDescontoTotal('');
            setTotal('');
        }
    }, [state]);

    useEffect(() => {
        const newSubTotal = converterMoeda(quant) * converterMoeda(valor);
        const newDescontoTotal = converterMoeda(descontoUnit) * converterMoeda(quant);
        const newTotal = (newSubTotal - newDescontoTotal);
        setSubTotal(newSubTotal);
        setDescontoTotal(newDescontoTotal);
        setTotal(newTotal);
        if (dadosFiscais && Number(dadosFiscais.crt) === 3 && converterMoeda(alicms) > 0) {
            setBaseicms(displayMoney(newTotal));
            setVicms(displayMoney((newTotal * converterMoeda(alicms)) / 100));
        }
        // eslint-disable-next-line
    }, [quant, valor, descontoUnit, alicms]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (open === false) setUseProd({});
        setState(open);
    };

    const addProd = () => {
        const prod = {
            ...useProd,
            quant: converterMoeda(quant),
            valor: converterMoeda(valor),
            desconto: converterMoeda(descontoUnit),
            subtotal,
            desconto_total: descontoTotal,
            total,
            alicms: converterMoeda(alicms),
            vicms: converterMoeda(vicms),
            baseicms: converterMoeda(baseicms),
            baseicmsst: converterMoeda(baseicmsst),
            vicmsst: converterMoeda(vicmsst),
            alipi: converterMoeda(alipi),
            vipi: converterMoeda(vipi),
            pipidevol: converterMoeda(pipidevol),
            vipidevol: converterMoeda(vipidevol),
            ppis: converterMoeda(ppis),
            vpis: converterMoeda(vpis),
            pcofins: converterMoeda(pcofins),
            vcofins: converterMoeda(vcofins),
            adicional,
            isConfirmed: true
        };
        setArrProd((arr) => {
            const filter = arr.filter((item) => item !== useProd);
            return [...filter, prod];
        });
        setUseProd({});
        setState(false);
    }

    const remove = () => {
        setState(false);
        AlertConfirm('', 'Remover item da NFe', useProd.descricao, 'SIM').then((resp) => {
            if (resp.isConfirmed) {
                setArrProd((arr) => {
                    const filter = arr.filter((item) => item !== useProd);
                    return filter;
                });
                setUseProd({});
            }
        });
    }

    const controlInput = (event, next) => {
        if (event.key === 'Enter') document.querySelector(`#${next}`).focus();
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Detalhes do Produto" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{ cursor: 'pointer' }} />
                    </ListItemIcon>
                </ListItem>
                {useProd.id && (
                    <>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaBoxes /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>
                                    {`
                                        ${useProd.descricao.substring(0, 25)}
                                        ${(useProd.descricao.length > 25) ? '...' : ''}
                                    `}
                                </Primary>
                            </ListItemText>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaProjectDiagram /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary> {`NCM: ${useProd.ncm}|CFOP: ${useProd.cfop}|CSOSN: ${useProd.csosn}`} </Primary>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <TextField
                                autoFocus
                                variant="outlined"
                                size="small"
                                label="QUANTIDADE"
                                value={quant}
                                onChange={(e) => setQuant(e.target.value)}
                                onKeyPress={(e) => controlInput(e, 'valor')}
                            />
                            <TextField
                                variant="outlined"
                                size="small"
                                id="valor"
                                label="VALOR"
                                value={valor}
                                onChange={(e) => setValor(e.target.value)}
                                onKeyPress={(e) => controlInput(e, 'desconto')}
                                style={{ marginLeft: '4px' }}
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                variant="outlined"
                                size="small"
                                id="desconto"
                                label="DESCONTO UNIT"
                                value={descontoUnit}
                                onChange={(e) => setDescUnit(e.target.value)}
                                onKeyPress={(e) => controlInput(e, 'btn')}
                            />
                            <TextField
                                variant="outlined"
                                size="small"
                                label="DESCONTO TOTAL"
                                value={displayMoney(descontoTotal)}
                                style={{ marginLeft: '4px' }}
                                readOnly
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText disableTypography>
                                <Primary> {`SUBTOTAL: R$ ${displayMoney(subtotal)}`} </Primary>
                            </ListItemText>
                            <ListItemText disableTypography>
                                <Primary> {`TOTAL: R$ ${displayMoney(total)}`} </Primary>
                            </ListItemText>
                        </ListItem>

                        {useProd.adicional !== undefined && (
                            <ListItem>
                                <Button
                                    fullWidth
                                    onClick={() => remove()}
                                    variant="contained"
                                    color="primary"
                                    style={{ margin: '0 25px 0 25px' }}
                                >
                                    REMOVER ITEM
                                </Button>
                            </ListItem>
                        )}
                        
                        <ListItem button alignItems="flex-start" onClick={() => setIcms(!icms)}>
                            <ListItemIcon className={classes.icon}>
                                {(icms) ? <FaMinus /> : <FaPlus />}
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>ICMS</Primary>
                            </ListItemText>
                        </ListItem>
                        {icms && (
                            <>
                                <ListItem>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="BASE"
                                        value={baseicms}
                                        onChange={(e) => setBaseicms(e.target.value)}
                                    />
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="ALIQUOTA"
                                        value={alicms}
                                        onChange={(e) => setAlicms(e.target.value)}
                                        style={{ marginLeft: '4px' }}
                                    />
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="VALOR"
                                        value={vicms}
                                        onChange={(e) => setVicms(e.target.value)}
                                        style={{ marginLeft: '4px' }}
                                    />
                                </ListItem>
                            </>
                        )}

                        <ListItem button alignItems="flex-start" onClick={() => setIcmsST(!icmsst)}>
                            <ListItemIcon className={classes.icon}>
                                {(icmsst) ? <FaMinus /> : <FaPlus />}
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>ICMS-ST</Primary>
                            </ListItemText>
                        </ListItem>
                        {icmsst && (
                            <>
                                <ListItem>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="BASE"
                                        value={baseicmsst}
                                        onChange={(e) => setBaseIcmsST(e.target.value)}
                                    />
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="VALOR"
                                        value={vicmsst}
                                        onChange={(e) => setVicmsST(e.target.value)}
                                        style={{ marginLeft: '4px' }}
                                    />
                                </ListItem>
                            </>
                        )}

                        <ListItem button alignItems="flex-start" onClick={() => setIpi(!ipi)}>
                            <ListItemIcon className={classes.icon}>
                                {(ipi) ? <FaMinus /> : <FaPlus />}
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>IPI</Primary>
                            </ListItemText>
                        </ListItem>
                        {ipi && (
                            <>
                                <ListItem>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="ALIQUOTA"
                                        value={alipi}
                                        onChange={(e) => setAlipi(e.target.value)}
                                    />
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="VALOR"
                                        value={vipi}
                                        onChange={(e) => setVipi(e.target.value)}
                                        style={{ marginLeft: '4px' }}
                                    />
                                </ListItem>
                            </>
                        )}

                        <ListItem button alignItems="flex-start" onClick={() => setIpiDevolv(!ipiDevolv)}>
                            <ListItemIcon className={classes.icon}>
                                {(ipiDevolv) ? <FaMinus /> : <FaPlus />}
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>IPI DEVOLUÇÃO</Primary>
                            </ListItemText>
                        </ListItem>
                        {ipiDevolv && (
                            <>
                                <ListItem>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="ALIQUOTA"
                                        value={pipidevol}
                                        onChange={(e) => setPIpiDevolv(e.target.value)}
                                    />
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="VALOR"
                                        value={vipidevol}
                                        onChange={(e) => setVIpiDevolv(e.target.value)}
                                        style={{ marginLeft: '4px' }}
                                    />
                                </ListItem>
                            </>
                        )}

                        <ListItem button alignItems="flex-start" onClick={() => setPisCofins(!pis_cofins)}>
                            <ListItemIcon className={classes.icon}>
                                {(pis_cofins) ? <FaMinus /> : <FaPlus />}
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>PIS & COFINS</Primary>
                            </ListItemText>
                        </ListItem>
                        {pis_cofins && (
                            <>
                                <ListItem>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="ALIQUOTA PIS"
                                        value={ppis}
                                        onChange={(e) => setPpis(e.target.value)}
                                    />
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="VALOR PIS"
                                        value={vpis}
                                        onChange={(e) => setVpis(e.target.value)}
                                        style={{ marginLeft: '4px' }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="ALIQUOTA COFINS"
                                        value={pcofins}
                                        onChange={(e) => setPcofins(e.target.value)}
                                    />
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="VALOR COFINS"
                                        value={vcofins}
                                        onChange={(e) => setVcofins(e.target.value)}
                                        style={{ marginLeft: '4px' }}
                                    />
                                </ListItem>
                            </>
                        )}
                        <ListItem button alignItems="flex-start" onClick={() => setOpenAdd(!openAdd)}>
                            <ListItemIcon className={classes.icon}>
                                {(openAdd) ? <FaMinus /> : <FaPlus />}
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>ADICIONAL</Primary>
                            </ListItemText>
                        </ListItem>
                        {openAdd && (
                            <ListItem>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    label="INFO ADICIONAL"
                                    value={adicional}
                                    onChange={(e) => setAdicional(e.target.value)}
                                />
                            </ListItem>
                        )}

                        <ListItem>
                            <Button
                                onClick={() => addProd()}
                                fullWidth
                                variant="contained"
                                color="primary"
                                id="btn"
                            >
                                ADICIONAR
                            </Button>
                        </ListItem>
                    </>
                )}
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
