import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AutoCompleteCfop from '../../../components/Select/AutoCompleteCfop';
import SelectValueForLabel from '../../../components/Select/SelectValueForLabel';
import { maskNumber } from '../../../services/mask';

export default function HeaderFiscal(props) {
    const { cfopNota, setCfopNota, numero, setNumero, finalidade, setFinalidade } = props;
    const { ref_nfe, setRefNfe } = props;

    return(
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <AutoCompleteCfop value={cfopNota} setValue={setCfopNota} variant="outlined"  />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField 
                    fullWidth
                    type="number"
                    label="NUMERO"
                    variant="outlined" 
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <SelectValueForLabel 
                    label="FINALDIDADE"
                    value={finalidade}
                    setValue={setFinalidade}
                    variant="outlined" 
                    options={[
                        { value: "1", label: 'NFe Normal' },
                        { value: "2", label: 'NFe Complementar' },
                        { value: "3", label: 'NFe de Ajuste' },
                        { value: "4", label: 'NFe de Devolução' }
                    ]}
                />
            </Grid>
            {(finalidade !== "1") && (
                <Grid item xs={12} sm={12}>
                    <TextField 
                        fullWidth
                        label="CHAVE DE ACESSO DA NFe DE ORIGEM"
                        variant="outlined" 
                        value={ref_nfe}
                        onChange={(e) => maskNumber(e, setRefNfe)}
                    />
                </Grid>
            )}
        </Grid>
    );
}