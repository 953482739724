import axios from 'axios';

// export const backendURL = 'http://localhost/webbackend';
// export const spededURL = 'http://localhost/apispeednfe';
export const spedemdfURL = 'http://localhost/apispeedmdfe';

export const backendURL = 'https://www.sistemarenove.com/backend';
export const spededURL = 'https://www.sistemarenove.com/apispeed';

const api = axios.create({
    baseURL: backendURL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});

export const speed = axios.create({
    baseURL: spededURL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
});

export const mdfeApi = axios.create({
    baseURL: spedemdfURL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
});

export const apisafe2pay = axios.create({
    baseURL: 'https://apinode.renovetecnologia.com',
});

export const apiCnpj = axios.create({
    baseURL: 'https://publica.cnpj.ws/cnpj',
});

export default api;