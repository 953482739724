import React from 'react';
import { Contained } from './styled';

export default function InputView({ label, value }) {
    return(
        <Contained>
            <span>{ label }</span>
            { value }
        </Contained>
    );
}