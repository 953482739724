import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import InputPassword from '../../components/Inputs/InputPassword';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import DropZone from '../../components/DropZone/DropZone';
import ImgActive from '../../components/DropZone/ImgActive';
import { AlertConfirm, AlertInfo } from '../../components/Alert/Alert';
import api, { speed, spededURL } from '../../services/api';
import { removeMask } from '../../services/displayValue';

export default function Configurar() {
    const [openLoad, setOpenLoad] = useState(false);
    const [idconfig_email, setIdConfigEmail] = useState(null);
    const [password_email, setPasswordEmail] = useState("");
    const [server_smtp, setServerSmtp] = useState("");
    const [server_port, setServerPort] = useState("");
    const [protocol, setProtocol] = useState("ssl");
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [isLogo, setIsLogo] = useState(false);
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));

    async function loadData() {
        setOpenLoad(true);
        try {
            const getConfigEmail = await api.get(`/configEmail/getConfigEmail/${infoEmpresa.id}`);
            if(getConfigEmail && getConfigEmail.status === 200){
                if(getConfigEmail.data && getConfigEmail.data.idconfig_email){
                    setIdConfigEmail(getConfigEmail.data.idconfig_email);
                    setServerSmtp(getConfigEmail.data.server_smtp);
                    setServerPort(getConfigEmail.data.server_port);
                    setProtocol(getConfigEmail.data.protocol);
                    setTitle(getConfigEmail.data.title);
                    setBody(getConfigEmail.data.body);
                }else{
                    const emailCompany = String(infoEmpresa.email);
                    if(emailCompany.length > 0){
                        if(emailCompany.includes('@gmail.com')){
                            setServerSmtp('smtp.gmail.com');
                            setServerPort(465);
                            setProtocol('ssl');
                        }else if(emailCompany.includes('@outlook.com') || emailCompany.includes('@hotmail.com') || emailCompany.includes('@live.com')){
                            setServerSmtp('smtp.office365.com');
                            setServerPort(587);
                            setProtocol('tls');
                        }
                    }
                }
            }

            const resp = await speed.get(`/config/isExistsLogo/${removeMask(infoEmpresa.cnpj)}`);
            console.log(resp);
            if(resp && resp.status === 200 && resp.data) setIsLogo(resp.data.file);
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'Não foi Possivel buscar dados', 'Verifique a conexão com a internet');
        }
        setOpenLoad(false);
    }

    async function saveData() {
        if(String(password_email).length > 3){
            setOpenLoad(true);
            const dataEmail = qs.stringify({
                password_email, server_smtp, server_port, protocol,
                title, body, idconfig_email, id_empresa: infoEmpresa.id
            });
            const updateEmail = await api.post(`/configEmail/setupEmail`, dataEmail);
            if(updateEmail && updateEmail.status === 200){
                AlertInfo('success', 'DADOS GRAVADOS COM SUCESSO!', '');
            }else{
                AlertInfo('error', 'FALHA AO SALVAR DADOS', '');
            }
            setOpenLoad(false);
        }else{
            AlertInfo('error', 'DIGITE A SENHA PARA SALVAR', '');
        }
    }

    async function handleUploadLogo(file) {
        if(file && file[0]){
            setOpenLoad(true);
            const cnpj = removeMask(infoEmpresa.cnpj);
            const data = new FormData();
            data.append('file', file[0]);
            data.append('cnpj', cnpj);
            try {
                const response = await speed.post('config/addLogo', data);
                console.log(response);
                if(response && response.status === 200 && response.data){
                    if(response.data.error){
                        AlertInfo('error', response.data.error);
                    }else{
                        setIsLogo(true);
                        AlertInfo('success', 'LOGO SALVA');
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
            setOpenLoad(false);
        }
    }

    function handleRemoveLogo() {
        AlertConfirm('', 'REMOVER LOGO', '', 'SIM').then(async res => {
            if(res.isConfirmed){
                const response = await speed.get(`/config/removeLogo/${removeMask(infoEmpresa.cnpj)}`);
                if(response && response.status === 200 && response.data){
                    if(response.data.delete){
                        AlertInfo('success', 'LOGO REMOVIDA');
                        setIsLogo(false);
                    }
                }
            }
        });
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    return (
        <Template menuActive="configurar">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h2>DADOS DE ENVIO DE EMAIL</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputPassword
                        fullWidth
                        label="SENHA DE AUTENTICAÇÃO"
                        variant="outlined"
                        value={password_email}
                        setValue={setPasswordEmail}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        label="SMTP"
                        variant="outlined"
                        value={server_smtp}
                        onChange={(e) => setServerSmtp(e.target.value)}
                        helperText="smtp.gmail.com || smtp.office365.com"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        type="number"
                        label="PORTA"
                        variant="outlined"
                        value={server_port}
                        onChange={(e) => setServerPort(e.target.value)}
                        helperText="465 || 587"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectValueForLabel
                        label="PROTOCOLO"
                        value={protocol}
                        variant="outlined"
                        setValue={setProtocol}
                        options={[
                            { value: 'ssl', label: 'SSL' },
                            { value: 'tls', label: 'TLS' },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="ASSUNTO DO EMAIL"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(String(e.target.value).toUpperCase())}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        fullWidth
                        label="TEXTO DO EMAIL"
                        variant="outlined"
                        value={body}
                        onChange={(e) => setBody(String(e.target.value).toUpperCase())}
                    />
                </Grid>
                <Grid item xs={12}>
                    <hr/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <h2>LOGO</h2>
                    {!isLogo && (
                        <DropZone 
                            textDefault='Clique para selecionar a logo ou arraste ela aqui.'
                            heightZone={40}
                            handleUpload={handleUploadLogo}
                        />
                    )}
                    {isLogo && (
                        <ImgActive
                            url={`${spededURL}/arquivos/img/${removeMask(infoEmpresa.cnpj)}.png`}
                            handleClick={handleRemoveLogo}
                        />
                    )}
                </Grid>
                <Grid item xs={12} />
                
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        onClick={saveData}
                    > 
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}