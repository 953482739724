import React, { useState, Fragment, useEffect } from 'react';
import { FaCoins } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaTimes } from 'react-icons/fa';
import { displayMoney, converterMoeda } from '../../services/displayValue';
import { Primary } from './styled';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        minWidth: 24,
        color: '#222'
    }
});

export default function DrawerSomatorio({ somatorio, setSomatorio, state, setState }) {
    const classes = useStyles();

    const [frete, setFrete] = useState(somatorio.frete || '');
    const [seguro, setSeguro] = useState(somatorio.seguro || '');
    const [despesas, setDespesas] = useState(somatorio.despesas || '');
    const [desconto, setDesconto] = useState(somatorio.desconto_final || '');

    useEffect(() => {
        if(somatorio[0]){
            setFrete(displayMoney(somatorio.frete) || '');
            setSeguro(displayMoney(somatorio.seguro) || '');
            setDespesas(displayMoney(somatorio.despesas) || '');
            setDesconto(displayMoney(somatorio.desconto_final) || '');

        }
    }, [somatorio]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function handleValue() {
        const newFrete = converterMoeda(frete);
        const newSeguro = converterMoeda(seguro);
        const newDespesas = converterMoeda(despesas);
        const newDesconto = converterMoeda(desconto);
        const newTotal = (newFrete + newSeguro + newDespesas - newDesconto + somatorio[0].subtotal - somatorio[0].desconto_total);
        const newSomatoria = {
            baseicms: somatorio[0].baseicms,
            vicms: somatorio[0].vicms,
            frete: newFrete,
            seguro: newSeguro,
            despesas: newDespesas,
            vipi: somatorio[0].vipi,
            subtotal: somatorio[0].subtotal,
            desconto_final: newDesconto,
            desconto_total: somatorio[0].desconto_total,
            desconto_total_final: (somatorio[0].desconto_total + newDesconto),
            total: newTotal
        }
        setSomatorio([newSomatoria]);
        setState(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Totais da Nota" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                {somatorio[0] && (
                    <>
                        <ListItem>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="FRETE"
                                value={frete}
                                onChange={(e) => setFrete(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                size="small"
                                label="SEGURO"
                                value={seguro}
                                onChange={(e) => setSeguro(e.target.value)}
                                style={{marginLeft: '4px'}}
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="DESPESAS"
                                value={despesas}
                                onChange={(e) => setDespesas(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                size="small"
                                label="DESCONTO"
                                value={desconto}
                                onChange={(e) => setDesconto(e.target.value)}
                                style={{marginLeft: '4px'}}
                            />
                        </ListItem>
                        
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaCoins /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary> 
                                    {`TOTAL DE PRODUTOS: R$ ${displayMoney(somatorio[0].subtotal)}`} 
                                </Primary>
                            </ListItemText>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaCoins /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary> 
                                    {`DESCONTO DE PRODUTOS: R$ ${displayMoney(somatorio[0].desconto_total)}`} 
                                </Primary>
                            </ListItemText>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaCoins /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary> {`TOTAL DA NFe: R$ ${displayMoney(somatorio[0].total)}`} </Primary>
                            </ListItemText>
                        </ListItem>

                        <ListItem>
                            <Button 
                                onClick={handleValue}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                                id="btn"
                            >
                                SALVAR
                            </Button>
                        </ListItem>
                    </>
                )}
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
