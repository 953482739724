import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function TableData({ columns, rows, clickFunction, indexColumn = false, vh = '70vh', double = [] }) {
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      height: vh,
    },
    row: {
      cursor: 'pointer',
    },
    cell: {
      color: '#000',
      fontWeight: 'bold',
      padding: 5
    }
  });

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {indexColumn && (
                <TableCell style={{ minWidth: indexColumn.minWidth }} className={classes.cell}>
                  { indexColumn.label }
                </TableCell>
              )}

              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, indexC) => {
              return (
                <TableRow 
                  hover 
                  role="checkbox" 
                  tabIndex={-1} 
                  key={indexC} 
                  className={classes.row}
                  onClick={() => clickFunction(row)}
                >
                  {indexColumn && (
                    <TableCell className={classes.cell}>
                      { indexC + 1 }
                    </TableCell>
                  )}

                  {columns.map((column, indexR) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={indexR} align={column.align} className={classes.cell}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

            {double.map((row, indexC) => {
              return (
                <TableRow 
                  hover 
                  role="checkbox" 
                  tabIndex={-1} 
                  key={indexC} 
                  className={classes.row}
                  onClick={() => clickFunction(row)}
                >
                  {indexColumn && (
                    <TableCell className={classes.cell}>
                      { indexC + 1 }
                    </TableCell>
                  )}

                  {columns.map((column, indexR) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={indexR} align={column.align} className={classes.cell}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      
    </Paper>
  );
}
