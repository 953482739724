import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaTimes, FaUserGraduate } from 'react-icons/fa';
import Search from '../Search/Search.js';
import api from '../../services/api';
import { Primary } from './styled';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320
    },
    icon: {
        minWidth: 24, 
        color: '#222',
    }
});

export default function DrawerCliente({ setCliente }) {
    const classes = useStyles();
    const [state, setState] = useState(false);
    const [rowsPerPage, setRowsPerPage] =  useState(15);
    const [filtro, setFiltro] = useState('');
    const [clientes, setClientes] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        const infoEmpresa = JSON.parse(sessionStorage.getItem('infoEmpresa'));
        const response = await api.get(`/clientes/listClientesByIdEmpresa/${infoEmpresa.id}`);
        if(response.status === 200 && response.data[0]) setClientes(response.data);
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    const selectCliente = (json) => {
        setCliente(json);
        setState(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Catálogo de Clientes" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <Search 
                        label="Buscar"
                        autoFocus
                        onChange={(e) => {
                            const { value } = e.target;
                            if(value.length > 2 || value === '') setFiltro(value.toUpperCase());
                        }}
                    />
                </ListItem>

                {clientes.filter((cliente) => cliente.nome.includes(filtro))
                .slice(0, rowsPerPage).map((cliente, index) => (
                    <ListItem button key={index} alignItems="flex-start" onClick={() => selectCliente(cliente)}>
                        <ListItemIcon className={classes.icon}> <FaUserGraduate /> </ListItemIcon>
                        <ListItemText disableTypography >
                            <Primary>
                                {`${cliente.nome.substring(0,25)}${(cliente.nome.length > 25) ? '...': ''}`}
                            </Primary>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
            { filtro === '' && rowsPerPage < clientes.length && (
                <Button 
                    onClick={() => setRowsPerPage(rowsPerPage + 15)}
                    fullWidth 
                    variant="contained" 
                    color="primary"
                >
                    MOSTRAR MAIS
                </Button>
            )}
        </div>
    );

    return (
        <Fragment>
            <Button 
                fullWidth 
                variant="contained" 
                color="primary"
                onClick={toggleDrawer(true)}
            > 
                Adicionar Cliente
            </Button>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
