import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Template from '../../components/Template/Template';
import TableData from '../../components/Table/TableData';
import SearchList from '../../components/Search/SearchList';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { displayId } from '../../services/displayValue';
import api from '../../services/api';

const Clientes = () => {
    const [activeSearch, setActivSearch] = useState(false);
    const [load, setLoad] = useState(false);
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const history = useHistory();

    const [rows, setRows] = useState([]);
    const columns = [
        { id: 'id', label: 'CODIGO', minWidth: 70, format: displayId },
        { id: 'nome', label: 'NOME', minWidth: 170 },
        { id: 'endereco', label: 'ENDEREÇO', minWidth: 170 },
    ];

    const loadClientes = async () => {
        setLoad(true);
        const id_empresa = infoEmpresa.id;
        const response = await api.get(`/clientes/listClientesByIdEmpresa/${id_empresa}`);
        const { data } = response;
        if (data.length > 0) {
            setRows(data);
        }
        setLoad(false);
    }

    const routeCadastro = async (json = {}) => {
        const location = {
            pathname: '/cadastrocliente',
            state: { infoCliente: json },
        }
        history.push(location);
    }

    return (
        <Template menuActive="clientes">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => routeCadastro()}
                    >
                        NOVO
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={loadClientes}
                    >
                        LISTAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4} />
                <Grid item xs={12} sm={4} container justify="flex-end" alignItems="center">
                    <SearchList
                        active={activeSearch}
                        url={`/clientes/buscaCliente/${infoEmpresa.id}`}
                        setList={setRows}
                        setLoad={setLoad}
                    />
                    <Fab
                        color="primary"
                        aria-label="add"
                        size="medium"
                        onClick={() => setActivSearch(!activeSearch)}
                    >
                        <FaSearch />
                    </Fab>
                </Grid>

                {rows.length > 0 && (
                    <>
                        <Grid item xs={12} sm={12}>
                            <TableData
                                columns={columns}
                                rows={rows}
                                clickFunction={routeCadastro}
                            />
                        </Grid>
                        <Grid item xs={false} sm={10}></Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                label="Total de Clientes"
                                variant="outlined"
                                inputProps={{ style: { textAlign: 'right' } }}
                                size="small"
                                value={rows.length}
                                onChange={() => { }}
                                readOnly
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <SimpleBackdrop open={load} />
        </Template>
    );
}

export default Clientes;