import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import AutoCompleteCity from '../../components/Select/AutoCompleteCity';
import { maskCEP, maskCPF, maskFone } from '../../services/mask';
import { arrayUfCode } from '../../data/options';

export default function DadosEmpresa(props) {
  const { stateCnpj, stateRazao, stateFantasia, stateIe, stateResponsavel, stateCpf, stateEndereco,
    stateBairro, stateNumero, stateComplemento, stateCep, stateCodigouf, stateCidade, stateTelefone,
    stateEmail, stateCpfCnpjContador, stateSenha, stateTipoPessoa, stateRevenda } = props;
  
  const [razao, setRazao] = stateRazao;
  const [fantasia, setFantasia] = stateFantasia;
  const [ie, setIe] = stateIe;
  const [responsavel, setResponsavel] = stateResponsavel;
  const [cpf, setCpf] = stateCpf;
  const [endereco, setEndereco] = stateEndereco;
  const [bairro, setBairro] = stateBairro;
  const [numero, setNumero] = stateNumero;
  const [complemento, setComplemento] = stateComplemento;
  const [cep, setCep] = stateCep;
  const [codigouf, setCodigoUf] = stateCodigouf;
  const [cidade, setCidade] = stateCidade;
  const [telefone, setTelefone] = stateTelefone;
  const [email, setEmail] = stateEmail;
  const [cpf_cnpj_contador, setCpfCnpjContador] = stateCpfCnpjContador;
  const [revenda, setRevenda] = stateRevenda;
  const [senha, setSenha] = stateSenha;

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom>
        Preencha os Dados Restantes
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField 
            label={stateTipoPessoa[0] === "1" ? "Razão Social" : "Nome completo do Emitente"}
            fullWidth 
            value={razao}
            onChange={e => setRazao(e.target.value)}
          />
        </Grid>
        {stateTipoPessoa[0] === "1" && (
          <Grid item xs={12} md={12}>
            <TextField
              label="Fantasia"
              fullWidth
              value={fantasia}
              onChange={e => setFantasia(e.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextField 
            label={stateTipoPessoa[0] === "1" ? "CNPJ" : "CPF"}
            fullWidth 
            value={stateCnpj[0]}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Inscrição Estadual"
            fullWidth
            value={ie}
            onChange={e => setIe(e.target.value)}
          />
        </Grid>

        {stateTipoPessoa[0] === "1" && (
          <>
            <Grid item xs={12} md={6}>
              <TextField 
                label="Responsavel" 
                fullWidth 
                value={responsavel}
                onChange={e => setResponsavel(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="CPF"
                fullWidth
                value={cpf}
                onChange={e => maskCPF(e, setCpf)}
              />
            </Grid>
          </>
        )}
        

        <Grid item xs={12} md={12} sm={12}>
          <TextField
            label="Endereço"
            fullWidth
            value={endereco}
            onChange={e => setEndereco(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Bairro"
            fullWidth
            value={bairro}
            onChange={e => setBairro(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Numero"
            type="number"
            fullWidth
            value={numero}
            onChange={e => setNumero(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="CEP"
            fullWidth
            value={cep}
            onChange={e => maskCEP(e, setCep)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Complemento"
            fullWidth
            value={complemento}
            onChange={e => setComplemento(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectValueForLabel
            label="UF"
            value={codigouf}
            setValue={setCodigoUf}
            options={arrayUfCode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoCompleteCity
            codigouf={codigouf}
            value={cidade}
            setValue={setCidade}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Telefone"
            fullWidth
            value={telefone}
            onChange={e => maskFone(e, setTelefone)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Email"
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="CPF/CNPJ CONTADOR"
            fullWidth
            value={cpf_cnpj_contador}
            onChange={e => setCpfCnpjContador(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="REVENDA"
            type="number"
            fullWidth
            value={revenda}
            onChange={e => setRevenda(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            label="Senha de Acesso"
            fullWidth
            type="password"
            value={senha}
            onChange={e => setSenha(e.target.value)}
          />
        </Grid>

      </Grid>
    </Fragment>
  );
}