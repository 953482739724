import React, { useState, Fragment, useEffect } from 'react';
import qs from 'querystring';
import moment  from 'moment';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InputMonth from '../Inputs/InputMonth';
import SelectSimple from '../../components/Select/SelectSimple';
import api from '../../services/api';
import { endMes, startMes } from '../../services/calc';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        marginRight: 5,
        marginLeft: -5,
    }
});

export default function DrawerBuscaNF({ state, setState, setRows, setTxt, openLoad, setNfce }) {
    const classes = useStyles();
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [filtro, setFiltro] = useState("MÊS");
    const [dataMes, setDataMes] = useState(moment().format("YYYY-MM"))
    const [data, setData] = useState(moment().format("YYYY-MM-DD"));
    const [cliente, setCliente] = useState('');
    const [inicio, setInicio] = useState('');
    const [final, setFinal] = useState('');

    useEffect(() => {
        setCliente('');
        setInicio('');
        setFinal('');
    }, [filtro]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    async function buscar() {
        openLoad(true);
        const busca = {
            filtro, data, cliente, 
            inicio: filtro === "MÊS" ? startMes(dataMes) : inicio, 
            final: filtro === "MÊS" ? endMes(dataMes) : final,
            id_empresa: infoEmpresa.id,
        };
        const response = await api.post(`/fiscal/buscaNF`, qs.stringify(busca));
        if(response.status === 200 && response.data){
            let txt = '';
            switch(filtro){
                case "DATA": txt = moment(data).format("DD-MM-YYYY"); break;
                case "CLIENTE": txt = cliente; break;
                case "PERIODO": 
                    txt = `${moment(inicio).format("DD-MM-YYYY")} a ${moment(final).format("DD-MM-YYYY")}`; 
                    break;
                case "MÊS": 
                    txt = `${moment(busca.inicio).format("DD-MM-YYYY")} a ${moment(busca.final).format("DD-MM-YYYY")}`; 
                    break;
                default: txt = "CLIENTE + PERIODO"; break;
            }
            setTxt(txt);
            setRows(response.data.nfe);
            setNfce(response.data.nfce)
            setState(false);
        }
        openLoad(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="BUSCAR" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                <ListItem>
                    <SelectSimple
                        label="FILTRO"
                        value={filtro}
                        setValue={setFiltro}
                        options={["MÊS", "DATA", "CLIENTE", "PERIODO", "CLIENTE + PERIODO"]}
                    />
                </ListItem>

                {filtro === "MÊS" && (
                    <ListItem>
                        <InputMonth 
                            label="MÊS DA BUSCA" 
                            value={dataMes}
                            onChange={(e) => setDataMes(e.target.value)}
                        />
                    </ListItem>
                )}

                {filtro === "DATA" && (
                    <ListItem>
                        <TextField 
                            fullWidth
                            type="date"
                            label="DATA DA BUSCA" 
                            variant="outlined" 
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={data}
                            onChange={(e) => setData(e.target.value)}
                        />
                    </ListItem>
                )}

                {filtro.includes("CLIENTE") && (
                    <ListItem>
                        <TextField 
                            fullWidth
                            label="CLIENTE" 
                            variant="outlined"
                            value={cliente}
                            onChange={(e) => setCliente(e.target.value)}
                        />
                    </ListItem>
                )}

                {filtro.includes("PERIODO") && (
                    <>
                        <ListItem>
                            <TextField 
                                fullWidth
                                type="date"
                                label="DATA INICIAL" 
                                variant="outlined" 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={inicio}
                                onChange={(e) => setInicio(e.target.value)}
                            />
                        </ListItem>
                        <ListItem>
                            <TextField 
                                fullWidth
                                type="date"
                                label="DATA FINAL" 
                                variant="outlined" 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={final}
                                onChange={(e) => setFinal(e.target.value)}
                            />
                        </ListItem>
                    </>
                )}

                <ListItem>
                    <Button 
                        onClick={() => buscar()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        id="btn"
                    >
                        <FaSearch className={classes.icon} />
                        BUSCAR
                    </Button>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
