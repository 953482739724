import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import TableData from '../../components/Table/TableData';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import api from '../../services/api';

const Transporte = () => {
    const [openLoad, setOpenLoad] = useState(false);
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [rows, setRows] = useState([]);
    const [veiculos, setVeiculos] = useState([]);
    const history = useHistory();
    const columns = [
        { id: 'nome', label: 'NOME', minWidth: 170 },
        { id: 'cnpj', label: 'CNPJ', minWidth: 70 },
        { id: 'cidade', label: 'CIDADE', minWidth: 110 },
    ];

    async function routeCadastro(json = {}) {
        let infoVeiculo = [];
        if(json.id_transporte){
            infoVeiculo = veiculos.filter((veic) => veic.transporte_id === json.id_transporte);
        }
        const location = {
            pathname: '/cadastrotransporte',
            state: { infoTransp: json, infoVeiculo },
        }
        history.push(location);
    }

    async function loadData(){
        setOpenLoad(true);
        const response = await api.get(`/transporte/listTransportes/${infoEmpresa.id}`);
        if(response.status === 200 && response.data){
            setRows(response.data.transportes);
            setVeiculos(response.data.veiculos);
        }
        setOpenLoad(false);
    }

    return(
        <Template menuActive="transportadoras">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        onClick={() => routeCadastro()}
                    > 
                        NOVO 
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        onClick={loadData}
                    > 
                        LISTAR 
                    </Button>
                </Grid>

                {rows.length > 0 && (
                    <>
                        <Grid item xs={12} sm={12}>
                            <TableData 
                                columns={columns} 
                                rows={rows} 
                                clickFunction={routeCadastro}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}

export default Transporte;