import React, { useState, Fragment, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaTimes } from 'react-icons/fa';
import SelectSimple from '../Select/SelectSimple';
import { AlertConfirm } from '../Alert/Alert';
import { arrayUfLabel } from '../../data/options';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        minWidth: 24,
        color: '#222'
    }
});

export default function DrawerAddVeic({ state, setState, setArr, edit, setEdit }) {
    const classes = useStyles();
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [placa, setPlaca] = useState("");
    const [uf_placa, setUfPlaca] = useState(infoEmpresa.uf);
    const [codigo_antt, setCodigoANTT] = useState("");

    useEffect(() => {
        if(edit){
            setPlaca(edit.placa || "");
            setUfPlaca(edit.uf_placa || infoEmpresa.uf);
            setCodigoANTT(edit.codigo_antt || "");
        }else{
            setPlaca("");
            setUfPlaca(infoEmpresa.uf);
            setCodigoANTT("");
        } // eslint-disable-next-line
    }, [edit]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setEdit(null);
        setState(open);
    };

    const addVeic = () => {
        if(placa.length > 4){
            const veiculo = { placa, uf_placa, codigo_antt };
            setArr((arr) => {
                const filtro = arr.filter((item) => item !== edit);
                return [...filtro, veiculo];
            });
            setEdit(null);
            setPlaca("");
            setUfPlaca(infoEmpresa.uf);
            setCodigoANTT("");
            setState(false);
        }
    }

    const remove = () => {
        setState(false);
        AlertConfirm('', 'APAGAR VEICULO?', '', 'SIM').then((resp) => {
            if(resp.isConfirmed){
                setArr((arr) => {
                    const filtro = arr.filter((item) => item !== edit);
                    return filtro;
                });
                setEdit(null);
            }
        });
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Detalhes do Veiculo" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="PLACA"
                        value={placa}
                        onChange={(e) => setPlaca(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <SelectSimple 
                        label="UF DA PLACA"
                        value={uf_placa}
                        setValue={setUfPlaca}
                        options={arrayUfLabel}
                        size="small"
                    />
                </ListItem>
                <ListItem>
                    <TextField 
                        fullWidth
                        label="CODIGO ANTT" 
                        variant="outlined" 
                        value={codigo_antt}
                        onChange={(e) => setCodigoANTT(e.target.value)}
                        size="small"
                    />
                </ListItem>

                {edit && (
                    <ListItem>
                        <Button 
                            fullWidth
                            onClick={() => remove()} 
                            variant="contained" 
                            color="primary"
                            style={{margin: '0 25px 0 25px'}}
                        >
                            <FaTrash /> &nbsp; EXCLUIR
                        </Button>
                    </ListItem>
                )}

                <ListItem>
                    <Button 
                        onClick={() => addVeic()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        id="btn"
                    >
                        { (edit) ? 'SALVAR' : 'ADICIONAR' }
                    </Button>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
