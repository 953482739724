import React from 'react';
import Grid from '@material-ui/core/Grid';
import SelectValueForLabel from '../../../components/Select/SelectValueForLabel';
import DrawerTransportadoraNf from '../../../components/Drawer/DrawerTransportadoraNf';
import DrawerVolumes from '../../../components/Drawer/DrawerVolumes';
import TableInput from '../../../components/Table/TableInput';
import InputView from '../../../components/Views/InputView';
import { formasFrete } from '../../../data/options';

export default function FreteFiscal(props) {
    const { transporte, setTransporte, setInfoVeiculo, setInfoTransp } = props;
    const { setVolume, setEspecie, setMarca, setNumeracao, setPesoLiquido, setPesoBruto } = props;
    const { infoTransp, infoVeiculo } = props;
    const { volume, especie, marca, numeracao, peso_liquido, peso_bruto } = props;

    return(
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <SelectValueForLabel 
                    label="FRETE"
                    value={transporte}
                    setValue={setTransporte}
                    variant="outlined" 
                    options={formasFrete}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DrawerTransportadoraNf
                    setVeiculo={setInfoVeiculo}
                    setTransporte={setInfoTransp}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DrawerVolumes
                    volume={setVolume}
                    especie={setEspecie}
                    marca={setMarca}
                    numeracao={setNumeracao}
                    pesoLiquido={setPesoLiquido}
                    pesoBruto={setPesoBruto}
                />
            </Grid>
            {infoTransp && infoTransp.id_transporte && (
                <Grid item xs={12} sm={12}>
                    <TableInput 
                        columns={[
                            { id: 'nome', label: 'TRANSPORTADORA' },
                            { id: 'placa', label: 'PLACA VEICULO'}
                        ]} 
                        rows={[{...infoTransp, ...infoVeiculo}]} 
                        clickFunction={() => {}}
                    />
                </Grid>
            )}
            {volume.length > 0 && (
                <Grid item xs={6} sm={6}>
                    <InputView 
                        label="VOLUME"
                        value={volume}
                    />
                </Grid>
            )}
            {especie.length > 0 && (
                <Grid item xs={6} sm={6}>
                    <InputView 
                        label="ESPECIE"
                        value={especie}
                    />
                </Grid>
            )}
            {marca.length > 0 && (
                <Grid item xs={6} sm={6}>
                    <InputView 
                        label="MARCA"
                        value={marca}
                    />
                </Grid>
            )}
            {numeracao.length > 0 && (
                <Grid item xs={6} sm={6}>
                    <InputView 
                        label="NUMERAÇÃO"
                        value={numeracao}
                    />
                </Grid>
            )}
            {peso_liquido > 0 && (
                <Grid item xs={6} sm={6}>
                    <InputView 
                        label="PESO LIQUIDO"
                        value={peso_liquido}
                    />
                </Grid>
            )}
            {peso_bruto.length > 0 && (
                <Grid item xs={6} sm={6}>
                    <InputView 
                        label="PESO BRUTO"
                        value={peso_bruto}
                    />
                </Grid>
            )}
        </Grid>
    );
}