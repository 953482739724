import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        font-size: 32px;
    }
`;

export const AvatarIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #a71a1a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
`;

export const FormContainer = styled.div`
    width: 100%;
    max-width: 500px;
    padding: 15px;
`;

export const FormGrid = styled.div`
    margin: 10px 0;
`;