import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import Template from '../../components/Template/Template';
import TableData from '../../components/Table/TableData';
import DrawerNf from '../../components/Drawer/DrawerNf';
import DrawerBuscaNF from '../../components/Drawer/DrawerBuscaNF';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { IconForCode } from '../../components/Icons/IconsCode';
import { displayId, displayMoney, displayDataBrl } from '../../services/displayValue';
import api from '../../services/api';

const NotaFiscal = () => {
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [nf, setNf] = useState({});
    const [drawerNf, setDrawerNf] = useState(false);
    const [drawerBusca, setDrawerBusca] = useState(false);
    const [openLoad, setOpenLoad] = useState(false);
    const [txtFiltro, setTxtFiltro] = useState(null);
    const [nfe, setNfe] = useState([]);
    const [nfce, setNfce] = useState([]);

    const formatTipo = (prop) => (Number(prop) === 55) ? 'NFe' : 'NFCe';
    const formatNome = (prop) => (prop) ? prop : 'CLIENTES DIVERSOS';

    const columns = [
        { id: 'situacao', label: 'STATUS', minWidth: 30, format: IconForCode },
        { id: 'numero', label: 'NUMERO', minWidth: 50, format: displayId },
        { id: 'modelo', label: 'TIPO', minWidth: 50, format: formatTipo },
        { id: 'nome', label: 'DESTINATARIO', minWidth: 170, format: formatNome },
        { id: 'update_at', label: 'DATA', minWidth: 70, format: displayDataBrl },
        { id: 'total', label: 'TOTAL NF', minWidth: 70, format: displayMoney, align: 'right' },
    ];

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    async function loadData(data = "") {
        setOpenLoad(true);
        setTxtFiltro(null);
        const response = await api.get(`/fiscal/loadNfByData/${infoEmpresa.id}/${data}`);
        if(response.status === 200 && response.data){
            setNfe(response.data.nfe);
            setNfce(response.data.nfce);
        }
        setOpenLoad(false);
    }

    function openDrawerNf(json = {}) {
        setNf(json);
        setDrawerNf(true);
    }

    return(
        <Template menuActive="notafiscal">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <Link to="/nfe">
                        <Button fullWidth variant="contained" color="primary"> 
                            NOVA NFe
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Link to="/nfce">
                        <Button fullWidth variant="contained" color="primary"> 
                            NOVA NFCe
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4} container justify="flex-end" alignItems="center">
                    {txtFiltro && (
                        <Chip
                            icon={<FaSearch />}
                            label={txtFiltro}
                            onDelete={() => loadData()}
                            variant="outlined"
                            style={{ marginRight: 10 }}
                        />
                    )}
                    <Fab 
                        color="primary" 
                        aria-label="add" 
                        size="medium"
                        onClick={() => setDrawerBusca(true)}
                    >
                        <FaSearch />
                    </Fab>
                </Grid>

                {(nfe.length > 0 || nfce.length > 0) && (
                    <Grid item xs={12} sm={12}>
                        <TableData 
                            columns={columns} 
                            rows={nfe} 
                            double={nfce}
                            clickFunction={openDrawerNf}
                        />
                    </Grid>
                )}
            </Grid>
            <DrawerNf 
                nf={nf} 
                state={drawerNf} 
                setState={setDrawerNf} 
                setOpenLoad={setOpenLoad} 
                setRows={setNfe}
                setNfce={setNfce}
            />
            <DrawerBuscaNF 
                state={drawerBusca} 
                setState={setDrawerBusca} 
                setRows={setNfe}
                setTxt={setTxtFiltro}
                openLoad={setOpenLoad}
                setNfce={setNfce}
            />
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}

export default NotaFiscal;