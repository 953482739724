import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Template from '../../components/Template/Template';
import { AlertInfo, AlertConfirm } from '../../components/Alert/Alert';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import AutoCompleteCfop from '../../components/Select/AutoCompleteCfop';
import SelectSimple from '../../components/Select/SelectSimple';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { maskNumber, maskDinheiro } from '../../services/mask';
import { displayId, displayMoney } from '../../services/displayValue';
import { ValidProd } from '../../services/validForms';
import api from '../../services/api';
import { arrayCSOSN, arrayOrigem, arrayCST, arrayPisCofins } from '../../data/options';

const CadastroProduto = (props) => {
    const { infoProd } = props.location.state;
    const history = useHistory();
    const [load, setLoad] = useState(false);
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [id] = useState(infoProd.id || null);
    const [descricao, setDescricao] = useState(infoProd.descricao || '');
    const [codigo_barras, setCodigoBarras] = useState(infoProd.codigo_barras || '');
    const [preco, setPreco] = useState(displayMoney(infoProd.preco) || '');
    const [cfop, setCfop] = useState(() => {
        if (infoProd.id) {
            return { cfop: infoProd.cfop, natureza: infoProd.natureza }
        } else {
            return { cfop: '5102', natureza: 'VENDA MERC ADQUIRIDA/RECEBIDA TERCEIRO' }
        }
    });
    const [ncm, setNcm] = useState(infoProd.ncm || '');
    const [cest, setCest] = useState(infoProd.cest || '');
    const [unidade, setUnidade] = useState(infoProd.unidade || 'UN');
    const [csosn, setCsosn] = useState(infoProd.csosn || '102');
    const [cst, setCst] = useState(infoProd.cst || '00');
    const [reducao_bc, setReducaoBc] = useState(infoProd.reducao_bc || '0');
    const [origem, setOrigem] = useState(infoProd.origem || '0');
    const [pis_confins, setPisConfins] = useState(infoProd.pis_confins || '49');
    const [especifico, setEspecifico] = useState(infoProd.especifico || 'NÃO');
    const [codigo_anp, setCodigoANP] = useState(infoProd.codigo_anp || "");

    const hadleSubmit = async () => {
        const valid = ValidProd(descricao, codigo_barras, preco, cfop, ncm);
        if (valid) {
            AlertInfo('error', 'DADOS INCOMPLETOS', `${valid.error}`);
        } else {
            setLoad(true);
            const data = {
                id,
                id_empresa: infoEmpresa.id,
                descricao,
                codigo_barras,
                preco,
                cfop: cfop.cfop,
                natureza: cfop.natureza,
                ncm,
                cest,
                unidade,
                csosn,
                cst,
                reducao_bc,
                pis_confins,
                origem,
                especifico,
                codigo_anp,
            }
            api.post(`/produtos/setupProd`, qs.stringify(data)).then(() => {
                setLoad(false);
                AlertInfo('success', 'DADOS GRAVADOS', '').then(() => history.push('/produtos'));
            }).catch((err) => {
                setLoad(false);
                AlertInfo('error', 'FALHA AO SALVAR', err);
            });
        }
    }

    const handleDeleteProduto = () => {
        let text = "A ação não podera ser mais desfeita!";
        AlertConfirm('warning', 'Deletar este produto?', text, 'Sim Deletar',).then((result) => {
            if (result.isConfirmed) {
                setLoad(true);
                const data = { id, id_empresa: infoEmpresa.id };
                api.post(`/produtos/deleteProduto`, qs.stringify(data)).then(() => {
                    setLoad(false);
                    AlertInfo('success', 'EXCLUIDO', '').then(() => history.push('/produtos'));
                }).catch((err) => {
                    setLoad(false);
                    AlertInfo('error', 'FALHA AO DELETAR', err);
                });
            }
        })
    }

    return (
        <Template menuActive="produtos">
            <Grid container spacing={1}>
                {id && (
                    <>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                label="CODIGO"
                                variant="outlined"
                                value={displayId(id)}
                                onChange={() => {}}
                                size='small'
                                disabled
                            />
                        </Grid>
                        <Grid item xs={false} sm={9}></Grid>
                        <Grid item xs={12} sm={1} style={{ textAlign: 'end' }}>
                            <Tooltip title="Deletar Produto">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={handleDeleteProduto}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={8}>
                    <TextField
                        autoFocus
                        fullWidth
                        label="DESCRICAO"
                        variant="outlined"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="CODIGO DE BARRAS"
                        variant="outlined"
                        value={codigo_barras}
                        onChange={(e) => maskNumber(e, setCodigoBarras)}
                    />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <TextField
                        fullWidth
                        label="PREÇO"
                        variant="outlined"
                        value={preco}
                        onChange={(e) => maskDinheiro(e, setPreco)}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextField
                        fullWidth
                        label="UNIDADE"
                        variant="outlined"
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        label="NCM"
                        variant="outlined"
                        value={ncm}
                        onChange={(e) => maskNumber(e, setNcm)}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextField
                        fullWidth
                        label="CEST"
                        variant="outlined"
                        value={cest}
                        onChange={(e) => maskNumber(e, setCest)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectValueForLabel
                        label="PIS E COFINS"
                        value={pis_confins}
                        setValue={setPisConfins}
                        options={arrayPisCofins}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    {Number(infoEmpresa.crt) !== 3 && (
                        <SelectValueForLabel
                            label="CSOSN"
                            value={csosn}
                            setValue={setCsosn}
                            options={arrayCSOSN}
                            variant="outlined"
                        />
                    )}
                    {Number(infoEmpresa.crt) === 3 && (
                        <SelectValueForLabel
                            label="CST"
                            value={cst}
                            setValue={setCst}
                            options={arrayCST}
                            variant="outlined"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <AutoCompleteCfop
                        value={cfop}
                        setValue={setCfop}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectValueForLabel
                        label="ORIGEM"
                        value={origem}
                        setValue={setOrigem}
                        options={arrayOrigem}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectSimple
                        label="PRODUTO ESPECIFICO"
                        value={especifico}
                        setValue={setEspecifico}
                        options={["NÃO", "VEICULOS", "MEDICAMENTOS", "ARMAMENTOS", "COMBUSTIVEIS"]}
                    />
                </Grid>

                {cst === '20' && (
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            type='number'
                            label="REDUÇÃO B.C"
                            variant="outlined"
                            value={reducao_bc}
                            onChange={(e) => setReducaoBc(e.target.value)}
                        />
                    </Grid>
                )}

                {especifico === "COMBUSTIVEIS" && (
                    <>
                        <Grid item xs={false} sm={cst === '20' ? 6 : 9}></Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="CODIGO ANP"
                                variant="outlined"
                                value={codigo_anp}
                                onChange={(e) => setCodigoANP(e.target.value)}
                            />
                        </Grid>
                    </>
                )}

                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={hadleSubmit}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
            <SimpleBackdrop open={load} />
        </Template>
    );
}

export default CadastroProduto;