import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { displayNumber3 } from '../../services/displayValue';
import { Container, HeaderContainer, TabCell } from './styled';

export default function TableData({ columns, rows, clickFunction, indexColumn = false }) {
    return (
        <Container>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <HeaderContainer>
                            {indexColumn && (
                                <TabCell 
                                    value={ indexColumn.label } 
                                    width={indexColumn.minWidth} 
                                    disabled
                                    readOnly
                                />
                            )}

                            {columns.map((column, index) => (
                                <TabCell
                                    key={index}
                                    align={column.align}
                                    width={column.minWidth}
                                    value={column.label}
                                    disabled
                                    readOnly
                                />
                            ))}
                        </HeaderContainer>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, indexC) => {
                            return (
                                <div style={{display:'flex'}} onClick={() => clickFunction(row)} key={indexC}>
                                    {indexColumn && (
                                        <TabCell 
                                            value={ displayNumber3(indexC + 1) } 
                                            width={indexColumn.minWidth}
                                            readOnly
                                        />
                                    )}

                                    {columns.map((column, indexR) => {
                                        const value = row[column.id];
                                        return (
                                            <TabCell
                                                key={indexR} 
                                                align={column.align} 
                                                value={column.format ? column.format(value) : value} 
                                                width={column.minWidth} 
                                                readOnly
                                            />
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}
