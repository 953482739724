import React, { useState } from 'react';
import qs from 'querystring';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import AutoCompleteCity from '../../components/Select/AutoCompleteCity';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo, AlertConfirm } from '../../components/Alert/Alert';
import { arrayUfCode } from '../../data/options';
import { maskCPF, maskNumber, maskCEP, maskFone, maskCpfCnpj } from '../../services/mask';
import { ValidEmpresa } from '../../services/validForms';
import api from '../../services/api';

export default function FormEmpresa() {
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')) || {});
    const [openLoad, setOpenLoad] = useState(false);
    const [id] = useState(infoEmpresa.id || null);
    const [tipopessoa] = useState(infoEmpresa.tipopessoa || "1");
    const [razao, setRazao] = useState(infoEmpresa.razao || "");
    const [fantasia, setFantasia] = useState(infoEmpresa.fantasia || "");
    const [cnpj, setCnpj] = useState(infoEmpresa.cnpj || "");
    const [ie, setIe] = useState(infoEmpresa.ie || "");
    const [responsavel, setResponsavel] = useState(infoEmpresa.responsavel || "");
    const [cpf, setCpf] = useState(infoEmpresa.cpf || "");
    const [endereco, setEndereco] = useState(infoEmpresa.endereco || "");
    const [bairro, setBairro] = useState(infoEmpresa.bairro || "");
    const [numero, setNumero] = useState(infoEmpresa.numero || 0);
    const [complemento, setComplemento] = useState(infoEmpresa.complemento || "");
    const [cep, setCep] = useState(infoEmpresa.cep || "");
    const [arrayUf] = useState(arrayUfCode);
    const [codigouf, setCodigoUf] = useState(infoEmpresa.codigouf || "");
    const [cidade, setCidade] = useState(infoEmpresa.codigomunicipio || "");
    const [telefone, setTelefone] = useState(infoEmpresa.telefone || "");
    const [email, setEmail] = useState(infoEmpresa.email || "");
    const [senha, setSenha] = useState("");
    const [cpf_cnpj_contador, setCpfCnpjContador] = useState(infoEmpresa.cpf_cnpj_contador || "");

    function handleSubmit() {
        AlertConfirm('', "SALVAR ALTERAÇÕES?", '', 'SIM').then(async(resp) => {
            if(resp.isConfirmed){
                setOpenLoad(true);
                const data = {
                    id,
                    razao,
                    fantasia,
                    cnpj,
                    ie,
                    responsavel,
                    cpf,
                    endereco,
                    numero,
                    bairro,
                    complemento,
                    codigouf,
                    uf: cidade.uf,
                    cidade: cidade.cidades,
                    codigomunicipio: cidade.municipio,
                    cep,
                    telefone,
                    email,
                    cpf_cnpj_contador,
                    senha,
                }

                const valid = ValidEmpresa(data);
                if(!valid){
                    const response = await api.post(`/empresas/updateEmpresa`, qs.stringify(data));
                    if(response.status === 200){
                        sessionStorage.setItem('infoEmpresa', JSON.stringify(response.data));
                        AlertInfo('success', 'DADOS GRAVADOS COM SUCESSO!', '');
                    }else{
                        AlertInfo('error', 'FALHA NA OPERAÇÃO!', '');
                    }
                }else{
                    AlertInfo('error', valid.error, '');
                }
                setOpenLoad(false);
            }
        });
    }

    return(
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <h1 style={{textAlign:'center'}}>DADOS DA {tipopessoa === "0" ? "EMITENTE" : "EMPRESA"}</h1>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label="RAZÃO SOCIAL" 
                    variant="outlined" 
                    value={razao}
                    onChange={(e) => setRazao(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label="FANTASIA" 
                    variant="outlined" 
                    value={fantasia}
                    onChange={(e) => setFantasia(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label={tipopessoa === "0" ? "CPF" : "CNPJ"}
                    variant="outlined" 
                    value={cnpj}
                    onChange={(e) => maskCpfCnpj(e, tipopessoa, setCnpj)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label="INSCRIÇÃO ESTADUAL" 
                    variant="outlined" 
                    value={ie}
                    onChange={(e) => maskNumber(e, setIe)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label="RESPONSAVEL" 
                    variant="outlined" 
                    value={responsavel}
                    onChange={(e) => setResponsavel(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label="CPF DO RESPONSAVEL" 
                    variant="outlined" 
                    value={cpf}
                    onChange={(e) => maskCPF(e, setCpf)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label="ENDEREÇO" 
                    variant="outlined" 
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField 
                    fullWidth
                    label="BAIRRO" 
                    variant="outlined" 
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField 
                    fullWidth
                    label="NUMERO" 
                    variant="outlined" 
                    value={numero}
                    onChange={(e) => maskNumber(e, setNumero)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label="COMPLEMENTO" 
                    variant="outlined" 
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <SelectValueForLabel 
                    label="UF"
                    value={codigouf}
                    setValue={setCodigoUf}
                    options={arrayUf}
                    variant="outlined" 
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <AutoCompleteCity 
                    codigouf={codigouf} 
                    value={cidade}
                    setValue={setCidade}
                    variant="outlined" 
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField 
                    fullWidth
                    label="CEP" 
                    variant="outlined" 
                    value={cep}
                    onChange={(e) => maskCEP(e, setCep)}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField 
                    fullWidth
                    label="TELEFONE" 
                    variant="outlined" 
                    value={telefone}
                    onChange={(e) => maskFone(e, setTelefone)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    fullWidth
                    label="EMAIL" 
                    variant="outlined" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Grid>
            <Grid item xs={false} sm={4}>
                <TextField 
                    fullWidth
                    type="text"
                    label="CPF/CNPJ CONTADOR" 
                    variant="outlined" 
                    value={cpf_cnpj_contador}
                    onChange={(e) => setCpfCnpjContador(e.target.value)}
                />
            </Grid>
            <Grid item xs={false} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
                <TextField 
                    fullWidth
                    type="password"
                    label="SENHA DE ACESSO" 
                    variant="outlined" 
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                />
            </Grid>
            <Grid item xs={false} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
                <Button 
                    fullWidth 
                    variant="contained" 
                    color="primary"
                    onClick={handleSubmit}
                > 
                    SALVAR
                </Button>
            </Grid>
            <Grid item xs={false} sm={4}></Grid>
            <SimpleBackdrop open={openLoad} />
        </Grid>
    );
}