import styled from 'styled-components';

export const ContainerBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

export const ContainerIcon = styled.div`
    background-color: #a71a1a;
    color: #fff;
    font-size: 100px;
    padding: 15px;
    border-radius: 50px;
`;

export const Title = styled.span`
    font-size: 22pt;
    font-weight: bold;
`;

export const SubTitle = styled.span`
    font-size: 18px;
`;


// TESTE DE PAGAMENTO

export const ContainerPagamento = styled.div`
    width: 100%;
    height: 100%;
`;

export const ContainerPagamentoInterno = styled.div`
    width: 100%;
    padding: 4px;
    padding-top: 20px;
`;

export const ContainerValorPag = styled.div`
    border: 2px solid #a71a1a;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 10px;
`;

export const ContainerLinkPix = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    height: 100%;
`;

export const LinkPixCopiaCola = styled.div`
    cursor: pointer;
    word-break: break-all;
    color: #a71a1a;
    &:hover{
        color: #b33535;
    }
`;