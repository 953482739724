import React, { useState, Fragment } from 'react';
import qs from 'querystring';
import { FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress  from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { AlertInfo } from '../../components/Alert/Alert';
import api from '../../services/api';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        minWidth: 24,
        color: '#222'
    }
});

export default function DrawerConfigUser({ state, setState }) {
    const classes = useStyles();
    const [infoPainel] = useState(JSON.parse(sessionStorage.getItem('painel')));
    const [load, setLoad] = useState(false);
    const [senha, setSenha] = useState('');
    const [cpfcnpj, setCpfCnpj] = useState(infoPainel.cpfcnpj || '');
    const [telefone, setTelefone] = useState(infoPainel.telefone || '');
    const [celular, setCelular] = useState(infoPainel.celular || '');
    const [email, setEmail] = useState(infoPainel.email || '');
    const [uf, setUf] = useState(infoPainel.uf || 'PA');
    const [cidade, setCidade] = useState(infoPainel.cidade || '');
    const [is_safe2pay, setIsSafe2Pay] = useState(Boolean(Number(infoPainel.is_safe2pay || 0)));
    const [api_safe2pay, setApiSafe2Pay] = useState(infoPainel.api_safe2pay || '');

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function closeDrawer({ icon = 'error', title = '', txt = '' }) {
        setLoad(false);
        setState(false);
        AlertInfo(icon, title, txt);
    }

    const saveData = async() => {
        if(is_safe2pay && String(api_safe2pay).length === 0){
            closeDrawer({title: 'PREENCHA UMA CHAVE VALIDA SAFE2PAY'});
        }else{
            setLoad(true);
            const data = qs.stringify({
                cpfcnpj, telefone, celular, email,
                uf, cidade, api_safe2pay, senha,
                is_safe2pay: Number(is_safe2pay)
            });
            try {
                const response = await api.post(`/painel/updateUserPainel/${infoPainel.x_api_key}`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.success){
                        closeDrawer({icon: 'success', title: 'DADOS GRAVADOS'});
                        return;
                    }
                }
                console.log(response);
                closeDrawer({title: 'FALHA NA OPERAÇÃO'});
            } catch (error) {
                console.log(error);
                closeDrawer({title: 'FALHA NA CONEXÃO'});
            }
        }
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="PERFIL USUARIO" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="CNPJ/CPF"
                        value={cpfcnpj}
                        onChange={(e) => setCpfCnpj(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="TELEFONE"
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="CELULAR"
                        value={celular}
                        onChange={(e) => setCelular(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="EMAIL"
                        value={email}
                        onChange={(e) => setEmail(String(e.target.value).toUpperCase())}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="UF"
                        value={uf}
                        onChange={(e) => setUf(String(e.target.value).toUpperCase())}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="CIDADE"
                        value={cidade}
                        onChange={(e) => setCidade(String(e.target.value).toUpperCase())}
                    />
                </ListItem>
                <ListItem>
                    <FormControlLabel
                        control={
                            <Checkbox 
                                color="primary"
                                checked={is_safe2pay}
                                onChange={() => setIsSafe2Pay(!is_safe2pay)}
                            />
                        }
                        label="USAR API DA SAFE2PAY"
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="API KEY SAFE2PAY"
                        value={api_safe2pay}
                        onChange={(e) => setApiSafe2Pay(e.target.value)}
                        disabled={!is_safe2pay}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        id="senha"
                        variant="outlined"
                        type="password"
                        size="small"
                        label="SENHA"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                    />
                </ListItem>
                {!load && (
                    <ListItem>
                        <Button 
                            onClick={() => saveData()}
                            fullWidth 
                            variant="contained" 
                            color="primary"
                            id="buscar"
                        >
                            SALVAR
                        </Button>
                    </ListItem>
                )}
                {load && (
                    <ListItem>
                        <CircularProgress />
                        &nbsp; AGUARDE...
                    </ListItem>
                )}
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
