import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const SelectValueForLabel = ({ label, value, setValue, options, ...rest }) => {
    return(
        <FormControl {...rest} fullWidth >
            <InputLabel htmlFor="outlined-age-native-simple">{ label }</InputLabel>
            <Select
                native
                value={value}
                onChange={(e) => setValue(e.target.value)}
                label={label}
                inputProps={{ name: label }}
            >
                {options.map((item, index) => (
                    <option value={item.value} key={index}>{item.label}</option>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectValueForLabel;