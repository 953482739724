import styled from 'styled-components';

export const ContainerPage = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Body = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 15px;
`;

export const PaperContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    height: 300px;
`;

export const Title = styled.span`
    text-align: center;
    font-weight: bold;
`;

export const Text = styled.span`
    text-align: center;
`;

export const IconContainer = styled.div`
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
    color: ${props => props.is_disabled ? "#000" : "#a71a1a"};
    &:hover{
        color: #650e0e;
    }
`;

export const Fluter = styled.div`
    bottom: 20px;
    right: 20px;
    position:fixed;
    z-index:1000;
`;