import React, { Fragment, useState, useEffect } from 'react';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import { FaPaperPlane, FaEdit, FaBan, FaGlobeAmericas, FaPrint } from 'react-icons/fa';
import { FaSearch, FaTimes, FaTrash, FaDownload, FaFileImport, FaEnvelopeOpenText } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ModalSendEmailNF from '../Modal/ModalSendEmailNF';
import { AlertConfirm, AlertInfo, AlertQuestion } from '../Alert/Alert';
import { removeMask } from '../../services/displayValue';
import { verificCertificado } from '../../services/verific';
import api, { speed, spededURL } from '../../services/api';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        marginRight: 5,
        marginLeft: -5,
    }
});

export default function DrawerNf({ nf, state, setState, setOpenLoad, setRows, setNfce }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [openModalEmail, setOpenModalEmail] = useState(false);
    const [configEmail, setConfigEmail] = useState(null);
    const classes = useStyles();
    const history = useHistory();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function checkStatus() {
        if(nf.situacao){
            const sit = Number(nf.situacao);
            if(sit === 100 || sit === 101 || sit === 102) return false;
        }

        return true;
    }

    async function loadDadosNF() {
        const url = (Number(nf.modelo) === 55) ? `/nfe/infoNfeById` : `/nfce/infoNfceById`;
        const response = await api.get(`${url}/${nf.id}/${nf.id_empresa}`);
        if(response.status === 200 && response.data){
            if(Number(nf.modelo) === 55){
                const data = {
                    dadosEmpresa: sessionStorage.getItem('infoEmpresa'),
                    dadosFiscais: sessionStorage.getItem('dadosFiscais'),
                    dadosNFe: JSON.stringify(response.data.infoNfe),
                    dadosCliente: JSON.stringify(response.data.infoCliente),
                    dadosProdutos: JSON.stringify(response.data.produtos),
                    duplicatas: JSON.stringify(response.data.duplicatas),
                    transporte: JSON.stringify(response.data.transporte),
                    veiculo: JSON.stringify(response.data.veiculo),
                };
                return data;
            }else{
                const data = {
                    dadosEmpresa: sessionStorage.getItem('infoEmpresa'),
                    dadosFiscais: sessionStorage.getItem('dadosFiscais'),
                    dadosNFe: JSON.stringify(response.data.infoNfce),
                    dadosCliente: JSON.stringify(response.data.infoCliente),
                    dadosProdutos: JSON.stringify(response.data.produtos),
                    duplicatas: JSON.stringify(response.data.duplicatas),
                };
                return data;
            }
        }
        return null;
    }

    async function isLimited() {
        if(Number(infoLogin.limite) > 0){
            const response = await api.get(`/login/login/${infoLogin.id}`);
            if(response.status === 200 && response.data){
                const totalNfe = Number(response.data.dashboard.card.nfe.nfe_value);
                const totalNfce = Number(response.data.dashboard.card.nfce.nfce_value);
                const totalNotas = totalNfe + totalNfce + Number(nf.total);
                if(Number(totalNotas) > Number(infoLogin.limite)){
                    return true;
                }else{
                    return false;
                }
            }
        }else{
            return false;
        }
    }

    async function enviar() {
        setState(false);
        const valid = verificCertificado();
        if(!valid){
            const limited = await isLimited();
            if(!limited){
                AlertConfirm('', `ENVIAR NFe?`, '', 'SIM').then(async(resp) => {
                    if(resp.isConfirmed){
                        setOpenLoad(true);
                        const data = await loadDadosNF();
                        if(data){
                            const urlSpeed = (Number(nf.modelo) === 55)?`/NFe/EmitirNFeV2`:`/NFCe/EmitirNFCeV2`;
                            const response = await speed.post(urlSpeed, qs.stringify(data));
                            console.log(response);
                            if(response.status === 200){
                                if(response.data.success){
                                    const { success, chave, recibo, protocolo } = response.data;
                                    const code = qs.stringify({ 
                                        success, chave, recibo, protocolo,
                                        id_empresa: nf.id_empresa,
                                        nfe_id: nf.id
                                    });
                                    const urlApi = (Number(nf.modelo) === 55)?'nfe':'nfce';
                                    const upload = await api.post(`/${urlApi}/emissao`, code);
                                    if(upload.status === 200) {
                                        let title = 'NFe EMITIDA';
                                        if(Number(nf.modelo) === 55){
                                            setRows(upload.data);
                                        }else{
                                            setNfce(upload.data);
                                            title = 'NFCe EMITIDA';
                                        }
                                        AlertInfo('success', title, '').then(() => danfe(chave));
                                    }
                                }else if(response.data.motivo){
                                    const { motivo } = response.data;
                                    const erro = motivo.split("[");
                                    AlertInfo('error', erro[0], erro[1]);
                                }else {
                                    AlertInfo('error', 'FALHA AO ENVIAR DADOS!', response.data);
                                    console.log(response);
                                }
                            }else{
                                AlertInfo('error', 'FALHA AO ENVIAR DADOS!', '');
                                console.log(response);
                            }
                        }
                        setOpenLoad(false);
                    }
                });
            }else{
                AlertInfo('error', 'LIMITE DE NOTAS ATINGIDO', '');
            }
        }else{
            AlertInfo('error', valid.error, '');
        }
    }

    function edit() {
        const url = (Number(nf.modelo) === 55) ? '/nfe' : '/nfce';
        const location = {
            pathname: url,
            state: { nfe_id: nf.id, nfce_id: nf.id },
        }
        history.push(location);
    }

    async function predanfe() {
        setState(false);
        const valid = verificCertificado();
        if(!valid){
            setOpenLoad(true);
            const data = await loadDadosNF();
            if(data){
                const type = (Number(nf.modelo) === 55)?'NFe':'NFCe';
                const response = await speed.post(`/${type}/preDanfeV2`, qs.stringify(data));
                if(response.status === 200){
                    if(response.data.chave){
                        const url = `${spededURL}/${type}/preDanfeV2/${response.data.chave}`;
                        if(window && window.ReactNativeWebView){
                            const dataPost = JSON.stringify({pdf: url})
                            window.ReactNativeWebView.postMessage(dataPost);
                        }else{
                            window.open(url);
                        }
                    }else{
                        AlertInfo('error', 'FALHA NA OPERAÇÃO!', response.data);
                        console.log(response);
                    }
                }else{
                    AlertInfo('error', 'FALHA NA OPERAÇÃO!', 'verifique sua conexão');
                    console.log(response);
                }
            }
            setOpenLoad(false);
        }else{
            AlertInfo('error', valid.error, '');
        }
    }

    function inutilizar() {
        setState(false);
        const valid = verificCertificado();
        if(!valid){
            let title = `INUTILIZAR NUMERAÇÃO?`;
            let txt = `A ação não poderá ser desfeita!`;
            AlertConfirm('', title, txt, 'SIM').then(async(resp) => {
                if(resp.isConfirmed){
                    setOpenLoad(true);
                    const infoEmpresa = sessionStorage.getItem('infoEmpresa');
                    const dadosFiscais = sessionStorage.getItem('dadosFiscais');
                    const { numero } = nf;
                    const data = { infoEmpresa, dadosFiscais, numero };
                    const response = await speed.post(`/NFe/inutilizarNfe`, qs.stringify(data));
                    let title = 'FALHA AO PROCESSAR DADOS!';
                    if(response.status === 200 && response.data){
                        if(response.data.infInut.cStat === "102"){
                            const data = {
                                id_empresa: nf.id_empresa,
                                nfe_id: nf.id,
                                status: response.data.infInut.cStat,
                            };
                            const backend = await api.post(`/nfe/atualizarStatus`, qs.stringify(data));
                            if(backend.status === 200 && backend.data[0]){
                                setRows(backend.data);
                                AlertInfo('success', 'DADOS GRAVADOS!', '');
                                setOpenLoad(false);
                                return;
                            }
                        }else{
                            if(response.data.infInut.xMotivo) title = response.data.infInut.xMotivo;
                        }
                    }
                    AlertInfo('error', title, '');
                    setOpenLoad(false);
                }
            });
        }else{
            AlertInfo('error', valid.error, '');
        }
    }

    async function sendEmail() {
        if(configEmail && configEmail.idconfig_email){
            setOpenModalEmail(true);
        }else{
            setState(false);
            AlertInfo('error', 'DADOS DE ENVIO DE E-MAIL NÃO DEFINIDOS');
        }
        
    }

    function danfe(chave = null) {
        const infoEmpresa = JSON.parse(sessionStorage.getItem('infoEmpresa'));
        const dadosFiscais = JSON.parse(sessionStorage.getItem('dadosFiscais'));
        const cnpj = removeMask(infoEmpresa.cnpj);
        const modelo = (Number(nf.modelo) === 55)?'NFe':'NFCe';
        const chaveNf = (chave === null) ? nf.chave : chave;
        const url = `${spededURL}/${modelo}/danfe/${nf.update_at}/${cnpj}/${dadosFiscais.ambiente}/${chaveNf}`;
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({pdf: url})
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            window.open(url);
        }
    }

    function downloadXml() {
        const infoEmpresa = JSON.parse(sessionStorage.getItem('infoEmpresa'));
        const dadosFiscais = JSON.parse(sessionStorage.getItem('dadosFiscais'));
        const { ambiente } = dadosFiscais;
        const modelo = (Number(nf.modelo) === 55)?'NFe':'NFCe';
        const cnpj = removeMask(infoEmpresa.cnpj);
        const url = `${spededURL}/NFe/downloadXml/${nf.update_at}/${cnpj}/${nf.chave}/${ambiente}/${modelo}`;
        window.open(url);
    }

    async function consultar() {
        setState(false);
        const valid = verificCertificado();
        if(!valid){
            setOpenLoad(true);
            const data = qs.stringify({
                dadosEmpresa: sessionStorage.getItem('infoEmpresa'),
                dadosFiscais: sessionStorage.getItem('dadosFiscais'),
                chave: nf.chave,
                modelo: nf.modelo,
            });
            const response = await speed.post(`/NFe/consulta`, data);
            if(response.status === 200){
                if(response.data.cStat){
                    if(Number(response.data.cStat) !== Number(nf.situacao)){
                        const data = {
                            id_empresa: nf.id_empresa,
                            nfe_id: nf.id,
                            status: response.data.cStat
                        };
                        const urlBackend = (Number(nf.modelo) === 55)?'nfe':'nfce';
                        const backend = await api.post(`/${urlBackend}/atualizarStatus`, qs.stringify(data));
                        if(backend.status === 200){
                            if(Number(nf.modelo) === 55){
                                setRows(backend.data);
                            }else{
                                setNfce(backend.data);
                            }
                        }
                    }
                    AlertInfo('success', response.data.xMotivo, '');
                }else{
                    AlertInfo('error', 'Não foi possivel consultar', '');
                    console.log(response);
                }
            }else{
                AlertInfo('error', 'Não foi possivel consultar', '');
                console.log(response);
            }
            setOpenLoad(false);
        }else{
            AlertInfo('error', valid.error, '');
        }
    }

    function cancelar() {
        setState(false);
        const valid = verificCertificado();
        if(!valid){
            AlertQuestion('DIGITE UMA JUSTIFICATIVA', '', '').then((resp) => {
                if(resp.isConfirmed){
                    const { value } = resp;
                    const txt = "A ação não podera ser desfeita!";
                    AlertConfirm('', "CANCELAR NF?", txt, "SIM").then(async(resp) => {
                        if(resp.isConfirmed){
                            setOpenLoad(true);
                            const data = qs.stringify({
                                dadosEmpresa: sessionStorage.getItem('infoEmpresa'),
                                dadosFiscais: sessionStorage.getItem('dadosFiscais'),
                                chave: nf.chave,
                                xJust: value,
                                nProt: nf.protocolo,
                                modelo: nf.modelo,
                            });
                            const response = await speed.post(`/NFe/cancelaNFe`, data);
                            console.log(response);
                            if(response.status === 200){
                                if(response.data.cStat === "128"){
                                    const { cStat } = response.data.retEvento.infEvento;
                                    const { xMotivo } = response.data.retEvento.infEvento;
                                    let icon = 'error';
                                    if(cStat === "101" || cStat === "155" || cStat === "135"){
                                        icon = 'success';
                                        const data = qs.stringify({
                                            id_empresa: nf.id_empresa,
                                            nfe_id: nf.id,
                                            status: "101",
                                        });
                                        const urlUpdate = (Number(nf.modelo) === 55)?'nfe':'nfce';
                                        const backend = await api.post(`/${urlUpdate}/atualizarStatus`, data);
                                        if(backend.status === 200){
                                            if(Number(nf.modelo) === 55){
                                                setRows(backend.data);
                                            }else{
                                                setNfce(backend.data);
                                            }
                                        }
                                    }
                                    AlertInfo(icon, xMotivo, '');
                                }
                            }else{
                                AlertInfo('error', 'Falha na operação', '');
                            }
                            setOpenLoad(false);
                        }
                    });
                }
            });
        }else{
            AlertInfo('error', valid.error, '');
        }
    }

    async function cartaCorrecao() {
        if(nf.eventos === 'cc'){
            danfeCC();
        }else{
            setState(false);
            const valid = verificCertificado();
            if(!valid){
                AlertQuestion('DIGITE A CORREÇÃO', '', '').then((resp) => {
                    if(resp.isConfirmed){
                        const { value } = resp;
                        const txt = "A ação não podera ser desfeita!";
                        AlertConfirm('', "CONFIRMAR OPERAÇÃO?", txt, "SIM").then(async(resp) => {
                            if(resp.isConfirmed){
                                setOpenLoad(true);
                                const data = qs.stringify({
                                    dadosEmpresa: sessionStorage.getItem('infoEmpresa'),
                                    dadosFiscais: sessionStorage.getItem('dadosFiscais'),
                                    chave: nf.chave,
                                    xCorrecao: value.toUpperCase(),
                                });
                                const response = await speed.post(`/NFe/cartaCorrecaoNFe`, data);
                                if(response.status === 200 && response.data){
                                    if(response.data.sucess){
                                        const updateData = qs.stringify({
                                            id_empresa: nf.id_empresa,
                                            nfe_id: nf.id
                                        });
                                        const updateDb = await api.post(`/Nfe/atualizarEvento`, updateData);
                                        if(updateDb.status === 200){
                                            setRows(updateDb.data);
                                            danfeCC();
                                        }
                                    }else if(response.data.err){
                                        console.log(response);
                                        const xMotivo = response.data.resp.retEvento.infEvento.xMotivo;
                                        AlertInfo('error', xMotivo, '');
                                    }else if(response.data.getMessage){
                                        console.log(response);
                                        AlertInfo('error', 'HOUVE UM ERRO NA OPERAÇÃO', '');
                                    }
                                }else{
                                    console.log(response);
                                    AlertInfo('error', 'FALHA NA OPERAÇÃO', '');
                                }
                                setOpenLoad(false);
                            }
                        });
                    }
                });
            }
        }
    }

    function danfeCC() {
        const infoEmpresa = JSON.parse(sessionStorage.getItem('infoEmpresa'));
        const dadosFiscais = JSON.parse(sessionStorage.getItem('dadosFiscais'));
        const cnpj = removeMask(infoEmpresa.cnpj);
        const modelo = (Number(nf.modelo) === 55)?'NFe':'NFCe';
        const chaveNf = nf.chave;
        const url = `${spededURL}/${modelo}/danfeCC/${nf.update_at}/${cnpj}/${dadosFiscais.ambiente}/${chaveNf}`;
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({pdf: url})
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            window.open(url);
        }
    }

    function deleteNf() {
        setState(false);
        AlertConfirm('', 'APAGAR DOS REGISTROS?', '', 'SIM').then(async(resp) => {
            if(resp.isConfirmed){
                setOpenLoad(true);
                const url = (Number(nf.modelo) === 55) ? `/nfe/deleteNfe` : `/nfce/deleteNfce`;
                const { id, id_empresa } = nf;
                const data = qs.stringify({ id, id_empresa });
                const response = await api.post(url, data);
                if(response.status === 200){
                    if(Number(nf.modelo) === 55){
                        setRows((rows) => rows.filter((row) => row !== nf));
                    }else{
                        setNfce((rows) => rows.filter((row) => row !== nf));
                    }
                    AlertInfo('success', 'NFe APAGADA!', '');
                }else{
                    AlertInfo('error', 'FALHA AO PROCESSAR DADOS!', '');
                }
                setOpenLoad(false);
            }
        });
    }

    async function loadConfigEmail() {
        const response = await api.get(`/configEmail/getConfigEmail/${infoLogin.id}`);
        if(response && response.status === 200){
            if(response.data && response.data.idconfig_email) setConfigEmail(response.data);
        }
    }

    // eslint-disable-next-line
    useEffect(() => loadConfigEmail(), []);

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Ações Nota Fiscal" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                { checkStatus() && (
                    <>
                        <ListItem>
                            <Button 
                                onClick={() => enviar()}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                                id="btn"
                            >
                                <FaPaperPlane className={classes.icon} />
                                ENVIAR
                            </Button>
                        </ListItem>

                        <ListItem>
                            <Button 
                                onClick={() => edit()}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                                id="btn"
                            >
                                <FaEdit className={classes.icon} />
                                EDITAR
                            </Button>
                        </ListItem>

                        <ListItem>
                            <Button 
                                onClick={() => predanfe()}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                                id="btn"
                            >
                                <FaSearch className={classes.icon} />
                                PRÉ-DANFE
                            </Button>
                        </ListItem>

                        {Number(nf.modelo) === 55 && (
                            <ListItem>
                                <Button 
                                    onClick={() => inutilizar()}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                    id="btn"
                                >
                                    <FaBan className={classes.icon} />
                                    INUTILIZAR
                                </Button>
                            </ListItem>
                        )}
                    </>
                )}

                {nf.situacao && (
                    <>
                        <ListItem>
                            <Button 
                                onClick={() => danfe()}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                                id="btn"
                            >
                                <FaPrint className={classes.icon} />
                                DANFE
                            </Button>
                        </ListItem>

                        <ListItem>
                            <Button 
                                onClick={() => downloadXml()}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                                id="btn"
                            >
                                <FaDownload className={classes.icon} />
                                Baixar XML
                            </Button>
                        </ListItem>

                        <ListItem>
                            <Button 
                                onClick={() => sendEmail()}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                            >
                                <FaEnvelopeOpenText className={classes.icon} />
                                Enviar por e-mail
                            </Button>
                        </ListItem>

                        <ListItem>
                            <Button 
                                onClick={() => cancelar()}
                                fullWidth 
                                variant="contained" 
                                color="primary"
                                id="btn"
                            >
                                <FaTimes className={classes.icon} />
                                CANCELAR
                            </Button>
                        </ListItem>
                        {Number(nf.situacao) === 100 && Number(nf.modelo) === 55 && (
                            <ListItem>
                                <Button 
                                    onClick={() => cartaCorrecao()}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                    id="btn"
                                >
                                    <FaFileImport className={classes.icon} />
                                    CARTA DE CORREÇÃO
                                </Button>
                            </ListItem>
                        )}
                    </>
                )}

                <ListItem>
                    <Button 
                        onClick={() => consultar()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        id="btn"
                    >
                        <FaGlobeAmericas className={classes.icon} />
                        CONSULTAR
                    </Button>
                </ListItem>

                { checkStatus() && (
                    <ListItem>
                        <Button 
                            onClick={() => deleteNf()}
                            fullWidth 
                            variant="contained" 
                            color="primary"
                            id="btn"
                        >
                            <FaTrash className={classes.icon} />
                            APAGAR
                        </Button>
                    </ListItem>
                ) }
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
            <ModalSendEmailNF
                open={openModalEmail}
                setOpen={setOpenModalEmail}
                infoNf={nf}
                closeDrawer={setState}
                configEmail={configEmail}
            />
        </Fragment>
    );
}
