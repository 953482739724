import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Clientes from './pages/Clientes/Clientes';
import CadastroCliente from './pages/Clientes/CadastroCliente';
import Produtos from './pages/Produtos/Produtos';
import CadastroProduto from './pages/Produtos/CadastroProduto';
import Transporte from './pages/Transporte/Transporte';
import CadastroTransp from './pages/Transporte/CadastroTransp';
import NotaFiscal from './pages/NotaFiscal/NotaFiscal';
import NFe from './pages/NotaFiscal/NFe';
import NFCe from './pages/NotaFiscal/NFCe';
import Mdfe from './pages/Mdfe/Mdfe';
import EmitirMdfe from './pages/Mdfe/EmitirMdfe';
import Certificado from './pages/Certificado/Certificado';
import DadosIniciais from './pages/DadosIniciais/DadosIniciais';
import Configurar from './pages/Configurar/Configurar';
import Empresa from './pages/Empresa/Empresa';
import Plano from './pages/Plano/Plano';
import Renovar from './pages/Plano/Renovar';
import CadastroEmpresa from './pages/Cadastro/CadastroEmpresa';
import Painel from './pages/Painel/Painel';
import Bloqueio from './pages/Bloqueio/Bloqueio';

const isAuthentication = () => {
    const infoEmpresa = sessionStorage.getItem('infoEmpresa') || null;
    if(infoEmpresa){
        return true;
    }else{
        return false;
    }
};

const isController = () => {
    const painel = sessionStorage.getItem('painel') || null;
    if(painel){
        return true;
    }else{
        return false;
    }
}

const PrivateRoute = (props) => {
    const { path, location, component: Component } = props;
    return(
        <Route path={path} >
            {(isAuthentication()) ? 
                <Component location={location} /> 
            : (isController()) ? <Painel /> : <Login />
            }
        </Route>
    );
};

const ExternalRoute = (props) => {
    const { path, location, component: Component } = props;
    return(
        <Route path={path} >
            <Component location={location} />
        </Route>
    );
};

export default function Routes() {
    return(
        <BrowserRouter>
            <Switch>
                <PrivateRoute path="/clientes" component={ Clientes } />
                <PrivateRoute path="/cadastrocliente" component={ CadastroCliente } />
                <PrivateRoute path="/produtos" component={ Produtos } />
                <PrivateRoute path="/transportadoras" component={ Transporte } />
                <PrivateRoute path="/cadastrotransporte" component={ CadastroTransp } />
                <PrivateRoute path="/cadastroproduto" component={ CadastroProduto } />
                <PrivateRoute path="/notafiscal" component={ NotaFiscal } />
                <PrivateRoute path="/nfe" component={ NFe } />
                <PrivateRoute path="/nfce" component={ NFCe } />
                <PrivateRoute path="/mdfe" component={ Mdfe } />
                <PrivateRoute path="/emitirmdfe" component={ EmitirMdfe } />
                <PrivateRoute path="/certificado" component={ Certificado } />
                <PrivateRoute path="/dados" component={ DadosIniciais } />
                <PrivateRoute path="/configurar" component={ Configurar } />
                <PrivateRoute path="/empresa" component={ Empresa } />
                <PrivateRoute path="/plano" component={ Plano } />
                <PrivateRoute path="/renovar" component={ Renovar } />

                <PrivateRoute path="/painel" component={ Painel } />

                <ExternalRoute path="/cadastroempresa" component={ CadastroEmpresa } />
                <ExternalRoute path="/bloqueio" component={ Bloqueio } />
                <PrivateRoute path="/" component={ Home } />
            </Switch>
        </BrowserRouter>
    );
}