import React from 'react';
import { DropContainer, ImgContainer, FloatingClose } from './styledDropzone';
import { FaTimes } from 'react-icons/fa';

export default function ImgActive({url, handleClick = () => {}}) {
    return (
        <DropContainer>
            <ImgContainer>
                <FloatingClose onClick={handleClick}>
                    <FaTimes />
                </FloatingClose>
                <img
                    alt='Logo'
                    height={70}
                    src={url}
                />
            </ImgContainer>
        </DropContainer>
    );
}