import moment from 'moment';

export const verificCertificado = () => {
    const dadosFiscais = JSON.parse(sessionStorage.getItem('dadosFiscais'));
    if(Number(dadosFiscais.upload_a1) !== 1){
        return { error: "Certificado digital não configurado!" }
    }else if(moment().isAfter(dadosFiscais.validade)){
        return { error: "Certificado digital vencido!" }
    }

    return null;
}