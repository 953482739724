import React from "react";
import Typography from '@material-ui/core/Typography';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { displayMoney } from '../../services/displayValue';

export default function GraphicColumn({ data = [] }) {
  	return (
		<div style={{ width: '100%', height: 300, textAlign: 'center' }}>
			<Typography>Comparação com os ultimos 3 meses:</Typography>
			<ResponsiveContainer>
				<BarChart
					data={data}
				>
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip formatter={(value) => [displayMoney(value), `TOTAL: R$`]} />
					<Legend />
					<Bar dataKey="values" fill="#a71a1a" name="TOTAL DE EMISSÃO" />
				</BarChart>
			</ResponsiveContainer>
		</div>
  );
}