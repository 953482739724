import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DrawerProduto from '../../../components/Drawer/DrawerProduto';
import DrawerProdNFe from '../../../components/Drawer/DrawerProdNFe';
import DrawerSomatorio from '../../../components/Drawer/DrawerSomatorio';
import TableInput from '../../../components/Table/TableInput';
import { displayId, displayMoney } from '../../../services/displayValue';

export default function ProdutoFiscal({ arrayProdutos, setArrayProdutos, somatoria, setSomatoria, modelo, dadosFiscais }) {
    const [useProd, setUseProd] = useState({});
    const [openDrawerSum, setOpenDrawerSum] = useState(false);
    const columns = [
        { id: 'id', label: 'CODIGO', minWidth: 70, format: displayId },
        { id: 'descricao', label: 'DESCRIÇÃO', minWidth: 270 },
        { id: 'quant', label: 'QUANT', minWidth: 60, format: displayMoney, align: 'right' },
        { id: 'valor', label: 'VALOR', minWidth: 90, align: 'right', format: displayMoney },
        { id: 'desconto', label: 'DESCONTO', minWidth: 90, align: 'right', format: displayMoney },
        { id: 'total', label: 'TOTAL', minWidth: 100, align: 'right', format: displayMoney },
        { id: 'ncm', label: 'NCM', minWidth: 90 },
        { id: 'csosn', label: 'CSOSN', minWidth: 60 },
        { id: 'cfop', label: 'CFOP', minWidth: 50 },
        { id: 'vipi', label: 'IPI', minWidth: 90, align: 'right', format: displayMoney },
        { id: 'vicms', label: 'ICMS', minWidth: 90, align: 'right', format: displayMoney },
        { id: 'vicmsst', label: 'ICMS-ST', minWidth: 90, align: 'right', format: displayMoney },
        { id: 'adicional', label: 'ADICIONAL', minWidth: 250 },
    ];
    const colunsSum = [
        { id: 'baseicms', label: 'BASE C. ICMS', minWidth: 100, align: 'right', format: displayMoney },
        { id: 'vicms', label: 'ICMS', minWidth: 90, align: 'right', format: displayMoney },
        { id: 'vicmsst', label: 'ICMS-ST', minWidth: 90, align: 'right', format: displayMoney },
        { id: 'frete', label: 'FRETE', minWidth: 80, align: 'right', format: displayMoney },
        { id: 'seguro', label: 'SEGURO', minWidth: 80, align: 'right', format: displayMoney },
        { id: 'despesas', label: 'DESPESAS', minWidth: 80, align: 'right', format: displayMoney },
        { id: 'vipi', label: 'IPI', minWidth: 70, align: 'right', format: displayMoney },
        { id: 'subtotal', label: 'TOTAL PRODUTOS', minWidth: 130, align: 'right', format: displayMoney },
        { id: 'desconto_total_final', label: 'DESCONTO', minWidth: 100, align: 'right', format: displayMoney },
        { id: 'total', label: 'TOTAL NFe', minWidth: 110, align: 'right', format: displayMoney },
    ];

    useEffect(() => {
        if(arrayProdutos.length === 0){
            setSomatoria([]);
        }else if(arrayProdutos.length === 1){
            const baseicms = Number(arrayProdutos[0].baseicms);
            const vicms = Number(arrayProdutos[0].vicms);
            const baseicmsst = Number(arrayProdutos[0].baseicmsst);
            const vicmsst = Number(arrayProdutos[0].vicmsst);
            const vipi = Number(arrayProdutos[0].vipi);
            const subtotal = Number(arrayProdutos[0].subtotal);
            const desconto_total = Number(arrayProdutos[0].desconto_total);

            const frete = (somatoria[0]) ? Number(somatoria[0].frete) : 0;
            const seguro = (somatoria[0]) ? Number(somatoria[0].seguro) : 0;
            const despesas = (somatoria[0]) ? Number(somatoria[0].despesas) : 0;
            const desconto_final = (somatoria[0]) ? Number(somatoria[0].desconto_final) : 0;

            const desconto_total_final = desconto_final + desconto_total;
            const total = (subtotal - desconto_total - desconto_final + frete + seguro + despesas + vicmsst);
            
            const sum = { 
                baseicms, baseicmsst, vicms, vicmsst, frete, seguro, despesas, vipi, subtotal, 
                desconto_total, desconto_final, desconto_total_final, total 
            };
            setSomatoria([sum]);
        }else if(arrayProdutos.length > 1){
            const sum = arrayProdutos.reduce((acumulator, item) => {
                const preBaseIcms = Number(acumulator.baseicms);
                const preVicms = Number(acumulator.vicms);
                const preBaseIcmsST = Number(acumulator.baseicmsst);
                const preVicmsST = Number(acumulator.vicmsst);
                const preVipi = Number(acumulator.vipi);
                const preSubtotal = Number(acumulator.subtotal);
                const preDesconto = Number(acumulator.desconto_total);

                const baseicms = preBaseIcms + Number(item.baseicms);
                const vicms = preVicms + Number(item.vicms);
                const baseicmsst = preBaseIcmsST + Number(item.baseicmsst);
                const vicmsst = preVicmsST + Number(item.vicmsst);
                const vipi = preVipi + Number(item.vipi);
                const subtotal = preSubtotal + Number(item.subtotal);
                const desconto_total = preDesconto + Number(item.desconto_total);
                
                return { baseicms, vicms, baseicmsst, vicmsst, vipi, subtotal, desconto_total };
            });

            const frete = (somatoria[0]) ? Number(somatoria[0].frete) : 0;
            const seguro = (somatoria[0]) ? Number(somatoria[0].seguro) : 0;
            const despesas = (somatoria[0]) ? Number(somatoria[0].despesas) : 0;
            const desconto_final = (somatoria[0]) ? Number(somatoria[0].desconto_final) : 0;

            const desconto_total_final = desconto_final + Number(sum.desconto_total);
            const total = (Number(sum.subtotal) - Number(sum.desconto_total) - desconto_final + frete + seguro + despesas + sum.vicmsst);
            const newSum = { ...sum, frete, seguro, despesas, desconto_final, desconto_total_final, total };
            setSomatoria([newSum]);
        }
        // eslint-disable-next-line
    }, [arrayProdutos]);

    const viewDrawer = () => setOpenDrawerSum(true);

    useEffect(() => console.log(useProd), [useProd]);

    return(
        <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
                <DrawerProduto setProduto={setUseProd} />
                <DrawerProdNFe 
                    useProd={useProd} 
                    setUseProd={setUseProd}
                    setArrProd={setArrayProdutos}
                    modelo={modelo} 
                    dadosFiscais={dadosFiscais}
                />
                <DrawerSomatorio 
                    somatorio={somatoria} 
                    setSomatorio={setSomatoria}
                    state={openDrawerSum}
                    setState={setOpenDrawerSum}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                {arrayProdutos.length > 0 && (
                    <Paper elevation={3} style={{ padding: 10 }}>
                        <TableInput 
                            columns={columns} 
                            rows={arrayProdutos} 
                            clickFunction={setUseProd}
                            indexColumn={{minWidth: 50, label: 'ITEM'}}
                        />
                        <br/>
                        <TableInput 
                            columns={colunsSum} 
                            rows={somatoria} 
                            clickFunction={viewDrawer}
                        />
                    </Paper>
                )}
                {arrayProdutos.length === 0 && (
                    <Paper elevation={0} style={{ height: 130 }} />
                )}
            </Grid>
        </Grid>
    );
}