import React, { useState, Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { converterMoeda } from '../../services/displayValue';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320
    },
    icon: {
        minWidth: 24, 
        color: '#222',
    }
});

export default function DrawerVolumes({ volume, especie, marca, numeracao, pesoLiquido, pesoBruto }) {
    const classes = useStyles();
    const [state, setState] = useState(false);
    const [stateVolume, setVolume] = useState("");
    const [stateEspecie, setEspecie] = useState("");
    const [stateMarca, setMarca] = useState("");
    const [stateNumeracao, setNumeracao] = useState("");
    const [statePeso_liquido, setPesoLiquido] = useState("");
    const [statePeso_bruto, setPesoBruto] = useState("");

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function adicionar() {
        volume(stateVolume.toUpperCase());
        especie(stateEspecie.toUpperCase());
        marca(stateMarca.toUpperCase());
        numeracao(stateNumeracao.toUpperCase());
        console.log(converterMoeda(statePeso_liquido));
        pesoLiquido(converterMoeda(statePeso_liquido));
        pesoBruto(converterMoeda(statePeso_bruto));
        setState(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Detalhes dos Volumes" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                <ListItem>
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        size="small"
                        label="VOLUME"
                        value={stateVolume}
                        onChange={(e) => setVolume(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="ESPECIE"
                        value={stateEspecie}
                        onChange={(e) => setEspecie(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="MARCA"
                        value={stateMarca}
                        onChange={(e) => setMarca(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="NUMERAÇÃO"
                        value={stateNumeracao}
                        onChange={(e) => setNumeracao(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="PESO LIQUIDO"
                        value={statePeso_liquido}
                        onChange={(e) => setPesoLiquido(e.target.value)}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="PESO BRUTO"
                        value={statePeso_bruto}
                        onChange={(e) => setPesoBruto(e.target.value)}
                    />
                </ListItem>

                <ListItem>
                    <Button 
                        onClick={() => adicionar()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        id="btn"
                    >
                        ADICIONAR
                    </Button>
                </ListItem>
            </List>            
        </div>
    );

    return (
        <Fragment>
            <Button 
                fullWidth 
                variant="contained" 
                color="primary"
                onClick={toggleDrawer(true)}
            > 
                VOLUMES
            </Button>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
