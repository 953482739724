import Swal from 'sweetalert2';
import axios from 'axios';
import { removeMask } from './displayValue';

export const apiCNPJ = async(txt) => {
    const cnpj = removeMask(txt);
    const response = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`);
    return response;
}

export const apiCEP = async(txt) => {
    const cep = removeMask(txt);
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return response;
}

export const consultaCNPJ = () => {
    return Swal.fire({
        title: 'DIGITE O CNPJ',
        input: 'text',
        inputAttributes: {
            autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Buscar',
        confirmButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: async(cnpj) => {
            const response = await apiCNPJ(cnpj);
            if(response.status === 200){
                if(response.data.error){
                    Swal.showValidationMessage(response.data.error);
                }else{
                    return response.data;
                }
            }else{
                Swal.showValidationMessage('Falha ao consultar dados!');
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    })
}