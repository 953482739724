import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { CardContainer, LineOne, LineTwo, LineThree, Title, NumberCenter } from './styled';

export default function CardHome({ title, count, label, legend, displayButton = null }) {
    return(
        <CardContainer>
            <LineOne>
                <Title>{ title }</Title>
                {displayButton && (
                    <Link to={displayButton}>
                        <Button variant="outlined" size="small" color="inherit">NOVA</Button>
                    </Link>
                )}
            </LineOne>
            <LineTwo>
                <NumberCenter>{ count }</NumberCenter>
                <span>{ label }</span>
            </LineTwo>
            <LineThree>
                { legend }
            </LineThree>
        </CardContainer>
    );
}