import React from 'react';
import { FaCheckCircle, FaTimesCircle, FaBan } from 'react-icons/fa';

const iconsNf = {
    100: { icon: FaCheckCircle, color: "#0dc123" },
    101: { icon: FaTimesCircle, color: "#a71a1a" },
    102: { icon: FaBan, color: "#228be6" },
};

export const IconForCode = (code) => {
    const FaIcon = (iconsNf[code]) ? iconsNf[code]['icon'] : null;
    const color = (iconsNf[code]) ? iconsNf[code]['color'] : null;

    if(FaIcon){
        return(
            <FaIcon color={color}/>
        );
    }else{
        return code;
    }
}