import styled from 'styled-components';

export const DropContainer = styled.div`
    border: 2px dashed #bbb;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
`;

export const UploadMsg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${props => props.height ? `${props.height}px 0` : `15px 0`};
`;

export const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;
`;

export const FloatingClose = styled.div`
    width: 25px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: rgba(0, 0, 0, 0.9);
    }
`;