import React from 'react';
import TextField from '@material-ui/core/TextField';

import './input.css';

export default function InputMonth({...rest}) {
    return(
        <TextField 
            fullWidth
            type="month"
            {...rest}
            variant="outlined" 
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}