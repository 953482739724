import React from 'react';
import Grid from '@material-ui/core/Grid';
import Template from '../../components/Template/Template';
import Pagamento from '../Bloqueio/Pagamento';

export default function Renovar() {
    return(
        <Template menuActive="plano">
            <Grid container spacing={3}>
                <Grid item xs={false} sm={1} />
                <Grid item xs={12} sm={10}>
                    <Pagamento rota="/plano" />
                </Grid>
                <Grid item xs={false} sm={1} />
            </Grid>
        </Template>
    );
}