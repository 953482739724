import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import { maskCpfCnpj } from '../../services/mask';

export default function IncialForm({ stateCnpj, stateTipoPessoa }) {
    const [cnpj, setCnpj] = stateCnpj;
    const [tipo_pessoa, setTipoPessoa] = stateTipoPessoa;

    return (
        <Fragment>
            <Typography variant="h6" gutterBottom>
                Vamos Começar!
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <SelectValueForLabel
                        label="TIPO PESSOA"
                        value={tipo_pessoa}
                        setValue={setTipoPessoa}
                        options={[
                            { value: '0', label: 'FISICA' },
                            { value: '1', label: 'JURIDICA' },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="cnpj"
                        name="cnpj"
                        label={`Digite o ${tipo_pessoa === "0" ? "CPF do emitente" : "CNPJ da empresa"}`}
                        fullWidth
                        autoComplete="given-name"
                        value={cnpj}
                        onChange={e => maskCpfCnpj(e, tipo_pessoa, setCnpj)}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}