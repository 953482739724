import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const HeaderContainer = styled.div`
    display: flex;
`;

export const TabCell = styled.input`
    width: ${props => (props.width) ? `${props.width}px` : '100%' };
    text-align: ${props => (props.align) ? props.align : 'left' };
    font-size: 14px;
    padding: 3px 2px 3px 2px;
    cursor: pointer;
    &:disabled {
        background-color: #fff;
        color: #000;
    }
    &:read-only {
        background-color: #ddd;
        font-weight: bold;
    }
    &:hover{
        background-color: #dddeee;
    }
`;