import React from 'react';
import { FaTrash } from 'react-icons/fa';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DrawerCliente from '../../../components/Drawer/DrawerCliente';
import InputView from '../../../components/Views/InputView';

export default function ClienteFiscal({ infoCliente, setInfoCliente, buttonSize, resize = false }) {
    return(
        <Grid container spacing={1}>
            <Grid item xs={12} sm={buttonSize}>
                <DrawerCliente setCliente={setInfoCliente} />
            </Grid>
            <Grid item xs={12} sm={12}>
                {infoCliente.id && (
                    <Paper elevation={3} style={{ padding: 10 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={(resize)?12:8}>
                                <InputView 
                                    label="CLIENTE"
                                    value={infoCliente.nome}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(resize)?12:4}>
                                <InputView 
                                    label={(infoCliente.tipopessoa === '0') ? 'CPF' : 'CNPJ'}
                                    value={infoCliente.cpfcnpj}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(resize)?12:6}>
                                <InputView 
                                    label="ENDEREÇO"
                                    value={infoCliente.endereco}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(resize)?12:3}>
                                <InputView 
                                    label="BAIRRO"
                                    value={infoCliente.bairro}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(resize)?12:3}>
                                <InputView 
                                    label="CEP"
                                    value={infoCliente.cep}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(resize)?12:4}>
                                <InputView 
                                    label="CIDADE"
                                    value={infoCliente.cidade}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(resize)?12:2}>
                                <InputView 
                                    label="UF"
                                    value={infoCliente.uf}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(resize)?12:3}>
                                <InputView 
                                    label="TELEFONE"
                                    value={infoCliente.telefone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(resize)?12:3}>
                                <InputView 
                                    label="INSCRIÇÃO ESTADUAL"
                                    value={infoCliente.ie}
                                />
                            </Grid>
                            <Grid 
                                item xs={12} sm={12} 
                                container 
                                spacing={1}
                                justify="flex-end"
                            >
                                <FaTrash 
                                    style={{ cursor: 'pointer' }} 
                                    color="#a71a1a"
                                    onClick={() => setInfoCliente({})}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                {!infoCliente.id && (
                    <Paper elevation={0} style={{ height: (resize)?0:130 }} />
                )}
            </Grid>
        </Grid>
    );
}