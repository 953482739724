import React from 'react';
import FormEmpresa from './FormEmpresa';
import Template from '../../components/Template/Template';

const Empresa = () => {
    return(
        <Template menuActive="empresa">
            <FormEmpresa />
        </Template>
    );
}

export default Empresa;