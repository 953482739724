import styled from 'styled-components';

export const SideArea = styled.div`
    position: fixed;
    display: block;
    left: ${props => (props.left) ? '-250px' : 0};
    width: 250px;
    height: 100%;
    background-color: #091114;
    transition: all .5s ease;
    z-index: 999;
`;

export const ContainerSideArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const HeaderMenu = styled.div`
    min-height: 56px;
    background-color: #1e2b30;
    @media(min-width: 600px){
        min-height: 64px;
    }
`;

export const MenuItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    min-height: 50px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 1px solid #000;
    background-color: ${props => (props.active) ? '#a71a1a' : (props.subMenu) ? '#1e2b30' : 'transparent'};
    transition: 0.6s;
    cursor: pointer;
    &:hover {
        padding-left: 20px;
    }
`;

export const LinkMenu = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
        font-size: 25px;
    }
    h6 {
        font-size: 16px;
    }
`;

export const ContainerSuporte = styled.a`
    color: #fff;
    bottom: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    div {
        display: flex;
        align-items: center;       
        gap: 8px;
    }
`;