export const unidades = ["UN", "KG", "LT", "PC", "CX", "MT", "CM"];

export const arrayCSOSN = [
    { value: '101', label: '101 - Tributada pelo Simples Nacional com permissão de crédito' },
    { value: '102', label: '102 - Tributada pelo Simples Nacional sem permissão de crédito' },
    { value: '103', label: '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta' },
    { value: '201', label: '201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária' },
    { value: '202', label: '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária' },
    { value: '203', label: '203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária' },
    { value: '300', label: '300 - Imune' },
    { value: '400', label: '400 - Não tributada pelo Simples Nacional' },
    { value: '500', label: '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação' },
    { value: '61', label: '61 - ICMS Monofasico' },
    { value: '900', label: '900 - Outros' },
];

export const arrayOrigem = [
    { value: '0', label: '0 - Nacional, exceto as indicadas nos códigos 3 a 5;' },
    { value: '1', label: '1 - Estrangeira – Importação direta, exceto a indicada no código 6;' },
    { value: '2', label: '2 - Estrangeira – Adquirida no mercado interno, exceto a indicada no código 7;' },
    { value: '3', label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%;' },
    { value: '4', label: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/1967' },
    { value: '5', label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%;' },
    { value: '6', label: '6 - Estrangeira – Importação direta, sem similar nacional, constante em lista de Resolução Camex e gás natural;' },
    { value: '7', label: '7 - Estrangeira – Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução Camex e gás natural.' },
];

export const arrayCST = [
    { value: '00', label: '00 - Tributada integralmente' },
    { value: '10', label: '10 - Tributada e com cobrança do ICMS por substituição tributária' },
    { value: '20', label: '20 - Com redução da BC' },
    { value: '30', label: '30 - Isenta / não tributada e com cobrança do ICMS por substituição tributária' },
    { value: '40', label: '40 - Isenta' },
    { value: '41', label: '41 - Não tributada' },
    { value: '50', label: '50 - Com suspensão' },
    { value: '51', label: '51 - Com diferimento' },
    { value: '60', label: '60 - ICMS cobrado anteriormente por substituição tributária' },
    { value: '61', label: '61 - ICMS Monofasico' },
    { value: '70', label: '70 - Com redução da BC e cobrança do ICMS por substituição tributária' },
    { value: '90', label: '90 - Outras' },
];

export const arrayPisCofins = [
    { value: '01', label: '01 - Operação Tributável com Alíquota Básica' },
    { value: '02', label: '02 - Operação Tributável com Alíquota Diferenciada' },
    { value: '03', label: '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto' },
    { value: '04', label: '04 - Operação Tributável Monofásica – Revenda a Alíquota Zero' },
    { value: '05', label: '05 - Operação Tributável por Substituição Tributária' },
    { value: '06', label: '06 - Operação Tributável a Alíquota Zero' },
    { value: '07', label: '07 - Operação Isenta da Contribuição' },
    { value: '08', label: '08 - Operação sem Incidência da Contribuição' },
    { value: '09', label: '09 - Operação com Suspensão da Contribuição' },
    { value: '49', label: '49 - Outras Operações de Saída' },
    { value: '99', label: '99 - Outras Operações' },
];

export const arrayUfCode = [
    { value: '12', label: 'AC' },
    { value: '27', label: 'AL' },
    { value: '13', label: 'AM' },
    { value: '16', label: 'AP' },
    { value: '29', label: 'BA' },
    { value: '23', label: 'CE' },
    { value: '53', label: 'DF' },
    { value: '32', label: 'ES' },
    { value: '52', label: 'GO' },
    { value: '21', label: 'MA' },
    { value: '31', label: 'MG' },
    { value: '50', label: 'MS' },
    { value: '51', label: 'MT' },
    { value: '15', label: 'PA' },
    { value: '25', label: 'PB' },
    { value: '26', label: 'PE' },
    { value: '22', label: 'PI' },
    { value: '41', label: 'PR' },
    { value: '33', label: 'RJ' },
    { value: '24', label: 'RN' },
    { value: '11', label: 'RO' },
    { value: '14', label: 'RR' },
    { value: '43', label: 'RS' },
    { value: '42', label: 'SC' },
    { value: '28', label: 'SE' },
    { value: '35', label: 'SP' },
    { value: '17', label: 'TO' },
];

export const arrayUfLabel = [
    'AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR',
    'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO',
];

export const formasPagamento = [
    { value: "01", label: 'DINHEIRO' },
    { value: "02", label: 'CHEQUE' },
    { value: "03", label: 'CARTÃO DE CRÉDITO' },
    { value: "04", label: 'CARTÃO DE DÉBITO' },
    { value: "05", label: 'CRÉDITO LOJA' },
    { value: "10", label: 'VALE ALIMENTAÇÃO' },
    { value: "11", label: 'VALE REFEIÇÃO' },
    { value: "12", label: 'VALE PRESENTE' },
    { value: "13", label: 'VALE COMBUSTÍVEL' },
    { value: "15", label: 'BOLETO BANCÁRIO' },
    { value: "16", label: 'DEPÓSITO BANCÁRIO' },
    { value: "17", label: 'PIX' },
    { value: "18", label: 'CARTEIRA DIGITAL' },
    { value: "19", label: 'CASHBACK' },
    { value: "90", label: 'SEM PAGAMENTO' },
    { value: "99", label: 'OUTROS' },
];

export const formasFrete = [
    { value: "9", label: 'SEM FRETE' },
    { value: "0", label: 'EMITENTE' },
    { value: "1", label: 'DESTINATÁRIO' },
    { value: "2", label: 'TERCEIROS' },
    { value: "3", label: 'PRÓPRIO (REMETENTE)' },
    { value: "4", label: 'PRÓPRIO (DESTINATÁRIO)' },
];