import React, { useState, Fragment } from 'react';
import qs from 'querystring';
import { FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress  from '@material-ui/core/CircularProgress';
import { AlertInfo } from '../Alert/Alert';
import { maskLogin } from '../../services/mask';
import api from '../../services/api';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        minWidth: 24,
        color: '#222'
    }
});

export default function DrawerAddEmpresa({ state, setState, setEmpresa, openDrawer }) {
    const classes = useStyles();
    const [load, setLoad] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [cnpj, setCnpj] = useState('');
    const [senha, setSenha] = useState('');

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    const controlInput = (event, next) => {
        if(event.key === 'Enter') document.querySelector(`#${next}`).focus();
    }

    const buscarEmpresa = async() => {
        setDisabled(true);
        setLoad(true);
        const user = maskLogin(cnpj);
        console.log(user);
        const login = qs.stringify({ user, senha });
        const response = await api.post(`/login/login`, login);
        const { data, status } = response;
        if(status === 200 && data.empresa && data.empresa.id){
            const { empresa } = data;
            setEmpresa({add: true, ...empresa});
            openDrawer(true);
        }else{
            AlertInfo('error', "Empresa não cadastrada", "");
        }
        setState(false);
        setDisabled(false);
        setLoad(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Dados da empresa" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="CNPJ/CPF"
                        value={cnpj}
                        onChange={(e) => setCnpj(e.target.value)}
                        onKeyPress={(e) => controlInput(e, 'senha')}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        fullWidth
                        id="senha"
                        variant="outlined"
                        type="password"
                        size="small"
                        label="SENHA"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        onKeyPress={(e) => controlInput(e, 'buscar')}
                    />
                </ListItem>
                <ListItem>
                    <Button 
                        onClick={() => buscarEmpresa()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        id="buscar"
                        disabled={disabled}
                    >
                        BUSCAR
                    </Button>
                </ListItem>
                {load && (
                    <ListItem>
                        <CircularProgress />
                        &nbsp; AGUARDE...
                    </ListItem>
                )}
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
