import styled from 'styled-components';

export const Container = styled.div`
    margin-left: ${props => (props.margin) ? 0 : 250}px;
    transition: all .5s ease;
    @media(max-width: 768px) {
        margin-left: 0;
    }
`;

export const View = styled.div`
    padding: 15px;
`;

export const CloseSideBarMobile = styled.div`
    height: 100%;
    width: 100%;
    z-index: ${props => (props.left) ? '-250' : '800'};
    opacity: ${props => (props.left) ? '0' : '1'};
    background-color: rgba(0,0,0, 0.4);
    position: absolute;
    display: none;
    transition: all .5s ease;
    @media(max-width: 768px) {
        display: block;
    }
`;