import React, { useState, useEffect } from 'react';
import { FaQrcode, FaBarcode, FaCreditCard, FaDollarSign, FaCalendarCheck, FaCalendarTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import TableData from '../../components/Table/TableData';
import api from '../../services/api';
import { displayMoney } from '../../services/displayValue';
import { BrasilDate } from '../../services/calc';
import { PaperContainerCard, PaperLabel, PaperValue } from './styled';

export default function Plano() {
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [daysRest] = useState(Number(moment(infoEmpresa.data_expiracao).diff(moment(), 'days')));
    const [isLimited] = useState(Number(infoEmpresa.limite) > 0 ? true : false);
    const [rows, setRows] = useState([]);
    const history = useHistory();
    const iconLabelStyle = {
        backgroundColor: '#059d6b',
        color:'#fff',
    }

    const iconPag = (cod) => {
        if(Number(cod) === 1) return <FaBarcode size={30} />
        else if(Number(cod) === 2) return <FaCreditCard size={30}  />
        else if(Number(cod) === 6) return <FaQrcode size={30}  />
    }

    const textPeriodo = (cod) => {
        if(Number(cod) === 1) return 'MENSAL';
        else if(Number(cod) === 3) return 'TRIMESTRAL';
        else if(Number(cod) === 6) return 'SEMESTRAL';
        else if(Number(cod) === 12) return 'ANUAL';
    }

    const textPagamento = (cod) => {
        if(Number(cod) === 3) return <Chip label="PAGO" style={iconLabelStyle} />
        else return <Chip label="PENDENTE" color="primary" />
    }

    const columns = [
        { id: 'type_transaction', label: 'TIPO', minWidth: 70, format: iconPag },
        { id: 'id_transaction', label: 'CODIGO', minWidth: 70 },
        { id: 'vencimento', label: 'VENCIMENTO', minWidth: 100, format: BrasilDate },
        { id: 'data_pagamento', label: 'DATA PAGAMENTO', minWidth: 100, format: BrasilDate },
        { id: 'valor_pago', label: 'VALOR PAGO', minWidth: 70, format: displayMoney },
        { id: 'periodo_liberado', label: 'PERIODO', minWidth: 70, format: textPeriodo },
        { id: 'status_pagamento', label: 'STATUS', minWidth: 70, format: textPagamento },
    ];

    function isBoletoOpen(row) {
        if(row.link_parcela !== "" && Number(row.status_pagamento) === 1){
            window.open(row.link_parcela, '_blank');
        }
    }

    async function loadData() {
        const response = await api.get(`/pagamentos/listPagamentos/${infoEmpresa.id}`);
        console.log(response);
        if(response && response.status === 200 && response.data){
            const arrayPag = response.data;
            setRows(arrayPag.reverse());
        }
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    return(
        <Template menuActive="plano">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={3}>
                        <PaperContainerCard>
                            <div>
                                <PaperLabel>PLANO DE USO</PaperLabel>
                                <br />
                                <PaperValue>
                                    R$ {isLimited ? '0,00' : displayMoney(infoEmpresa.valor_sistema)}
                                </PaperValue>
                            </div>
                            <div>
                                <Fab 
                                    color="primary" 
                                    aria-label="add" 
                                    size="large"
                                >
                                    <FaDollarSign size={30} />
                                </Fab>
                            </div>
                        </PaperContainerCard>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={3}>
                        <PaperContainerCard>
                            <div>
                                <PaperLabel>ATIVAÇÃO:</PaperLabel>
                                <br />
                                <PaperValue>{BrasilDate(infoEmpresa.data_ativacao)}</PaperValue>
                            </div>
                            <div>
                                <Fab 
                                    color="primary" 
                                    aria-label="add" 
                                    size="large"
                                >
                                    <FaCalendarCheck size={30} />
                                </Fab>
                            </div>
                        </PaperContainerCard>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Paper elevation={3}>
                        <PaperContainerCard>
                            <div>
                                <PaperLabel>EXPIRA EM:</PaperLabel>
                                <br />
                                <PaperValue>
                                    {isLimited ? `--/--/----` : BrasilDate(infoEmpresa.data_expiracao)}
                                </PaperValue>
                            </div>
                            <div>
                                <Fab 
                                    color="primary" 
                                    aria-label="add" 
                                    size="large"
                                >
                                    <FaCalendarTimes size={30} />
                                </Fab>
                            </div>
                        </PaperContainerCard>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {(daysRest < 6 || Number(infoEmpresa.limite) > 0) && (
                        <Button 
                            fullWidth 
                            variant="contained" 
                            color="primary"
                            onClick={() => history.push('/renovar')}
                        > 
                            RENOVAR PLANO
                        </Button>
                    )}
                </Grid>
                <Grid item xsx={12} sm={3}>
                    {rows.length > 0 && Number(rows[0].type_transaction) === 0 && (
                        <a 
                            href={rows[0].link_carnet}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            <Button 
                                fullWidth 
                                variant="contained" 
                                color="primary"
                            > 
                                VISUALIZAR CARNÊ
                            </Button>
                        </a>
                    )}
                </Grid>
                <Grid item xsx={12} sm={6}></Grid>
                <Grid item xs={12}>
                    <TableData 
                        columns={columns} 
                        rows={rows} 
                        clickFunction={isBoletoOpen}
                        vh="52vh"
                    />
                </Grid>
            </Grid>
        </Template>
    );
}