import React, { useState, useEffect } from 'react';
import { BuscaContainer, InputBusca } from './styled';
import api from '../../services/api';

export default function SearchList({ active, url, setList, setLoad }) {
    const [value, setValue] = useState('');

    useEffect(() => {
        setTimeout(() => {
            let obj = document.querySelector('#searchList');
            if(obj) obj.focus();
        }, 1000);
    }, [active]);

    async function busca(event) {
        if(event.key === 'Enter'){
            setLoad(true);
            const response = await api.get(`${url}/${value}`);
            console.log(response);
            if(response.status === 200 && response.data){
                setList(response.data);
            }
            setLoad(false);
        }
    }

    return(
        <BuscaContainer>
            <InputBusca 
                active={active} 
                id="searchList"
                value={value}
                placeholder="BUSCAR..."
                onChange={e => setValue(e.target.value)}
                onKeyPress={busca}
            />
        </BuscaContainer>
    );
}