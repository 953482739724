import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaTimes } from 'react-icons/fa';
import { displayMoney, converterMoeda, displayNumber3 } from '../../services/displayValue';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320,
    },
    icon: {
        minWidth: 24,
        color: '#222'
    }
});

export default function DrawerParcela({ state, setState, info, setParcelas, valorTotal }) {
    const classes = useStyles();
    const [vencimento, setVencimento] = useState('');
    const [valor, setValor] = useState('');

    useEffect(() => {
        setVencimento(info.vencimento);
        setValor(displayMoney(info.valor_duplicata));
        // eslint-disable-next-line
    }, [info]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };

    function atualizar() {
        if(Number(info.n_duplicata) === 1){
            const sobra = (valorTotal - converterMoeda(valor));
            setParcelas((arr) => {
                const parcelasRestantes = (arr.length - 1);
                const newDivision = (sobra / parcelasRestantes);
                let newArr = arr.map(item => {
                    if(item.n_duplicata === info.n_duplicata){
                        return {
                            n_duplicata: item.n_duplicata,
                            vencimento: vencimento,
                            valor_duplicata: converterMoeda(valor)
                        }
                    }else{
                        return {
                            n_duplicata: item.n_duplicata,
                            vencimento: item.vencimento,
                            valor_duplicata: newDivision
                        }
                    }
                });

                return newArr;
            });
        }else{
            setParcelas((arr) => {
                let newArr = arr;
                let index = (Number(info.n_duplicata) - 1);
                newArr[index].vencimento = vencimento;
                return newArr;
            });
        }
        setState(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary={`Duplicata ${displayNumber3(info.n_duplicata)}`} />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        label="VENCIMENTO"
                        value={vencimento}
                        onChange={(e) => setVencimento(e.target.value)}
                    />
                </ListItem>
                {Number(info.n_duplicata) === 1 && (
                    <ListItem>
                        <TextField
                            variant="outlined"
                            size="small"
                            label="VALOR"
                            value={valor}
                            onChange={(e) => setValor(e.target.value)}
                        />
                    </ListItem>
                )}
                <ListItem>
                    <Button 
                        onClick={() => atualizar()}
                        fullWidth 
                        variant="contained" 
                        color="primary"
                    >
                        ALTERAR
                    </Button>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
