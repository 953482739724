import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaGlobeAmericas, FaTrash, FaTruck } from 'react-icons/fa';
import qs from 'querystring';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import { AlertInfo, AlertConfirm } from '../../components/Alert/Alert';
import SelectSimple from '../../components/Select/SelectSimple';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import AutoCompleteCity from '../../components/Select/AutoCompleteCity';
import TableInput from '../../components/Table/TableInput';
import DrawerAddVeic from '../../components/Drawer/DrawerAddVeic';
import { arrayUfLabel } from '../../data/options';
import { maskCPF, maskCNPJ } from '../../services/mask';
import { ValidTranporte } from '../../services/validForms';
import { consultaCNPJ, apiCEP } from '../../services/consult.js';
import api from '../../services/api';

const CadastroTransp = (props) => {
    const { infoTransp } = props.location.state;
    const history = useHistory();
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [id] = useState(infoTransp.id_transporte || null);
    const [cnpj, setCnpj] = useState(infoTransp.cnpj || "");
    const [cpf, setCpf] = useState(infoTransp.cpf || "");
    const [nome, setNome] = useState(infoTransp.nome || "");
    const [endereco, setEndereco] = useState(infoTransp.endereco);
    const [cidade, setCidade] = useState(infoTransp.codigomunicipio || "");
    const [uf, setUf] = useState(infoTransp.uf || infoEmpresa.uf);
    const [infoVeiculo, setInfoVeiculo] = useState(props.location.state.infoVeiculo || []);

    const [openLoad, setOpenLoad] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [edit, setEdit] = useState(null);
    

    const hadleSubmit = async() => {
        const valid = ValidTranporte(nome, cpf, cnpj, endereco, cidade);
        if(valid){
            AlertInfo('error', 'DADOS INCOMPLETOS', `${valid.error}`);
        }else{
            setOpenLoad(true);
            const data = {
                id,
                id_empresa: infoEmpresa.id,
                nome,
                cpf,
                cnpj,
                endereco,
                uf,
                cidade: cidade.cidades,
                codigomunicipio: cidade.municipio,
                veiculos: JSON.stringify(infoVeiculo),
            }
            const response = await api.post(`/transporte/setupTransporte`, qs.stringify(data));
            setOpenLoad(false);
            if(response.status === 200) {
                AlertInfo('success', 'DADOS GRAVADOS', '').then(() => history.push('/transportadoras'));
            }else{
                AlertInfo('error', 'FALHA AO SALVAR');
            }
        }
    }

    const handleDeleteTransp = () => {
        let text = "A ação não podera ser mais desfeita!";
        AlertConfirm('', 'Deletar este cadastro?', text, 'Sim Deletar', ).then(async(result) => {
            if (result.isConfirmed) {
                setOpenLoad(true);
                const data = qs.stringify({ id, id_empresa: infoEmpresa.id });
                const response = await api.post(`/transporte/deleteTransporte`, data);
                setOpenLoad(false);
                if(response.status === 200){
                    AlertInfo('success', 'DADOS GRAVADOS!', '').then(() => history.push('/transportadoras'));
                }else{
                    AlertInfo('error', "FALHA NA OPERAÇÃO", "");
                }
            }
        })
    }

    const buscarCNPJ = () => {
        consultaCNPJ().then(async(result) => {
            if (result.isConfirmed) {
                setNome(result.value["RAZAO SOCIAL"]);
                maskCNPJ(result.value["CNPJ"], setCnpj);
                setEndereco(`${result.value["TIPO LOGRADOURO"]} ${result.value["LOGRADOURO"]}`);
                const respCity = await apiCEP(result.value["CEP"]);
                setCidade(respCity.data.ibge);
                setUf(result.value["UF"]);

            }
        });
    }

    function editVeic(json = {}) {
        setEdit(json);
        setOpenDrawer(true);
    }

    return(
        <Template menuActive="transportadoras">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        onClick={buscarCNPJ}
                    > 
                        <FaGlobeAmericas /> &nbsp;CONSULTA CNPJ
                    </Button>
                </Grid>
                <Grid item xs={false} sm={8}></Grid>
                <Grid item xs={12} sm={2}>
                    {id && (
                        <Button 
                            fullWidth 
                            variant="contained" 
                            color="primary"
                            onClick={handleDeleteTransp}
                        > 
                            <FaTrash /> &nbsp; DELETAR
                        </Button>
                    )}
                </Grid>
            </Grid>

            <br/>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        autoFocus
                        fullWidth
                        label="NOME OU RAZÃO SOCIAL"
                        variant="outlined" 
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        fullWidth
                        label="CNPJ"
                        variant="outlined" 
                        value={cnpj}
                        onChange={(e) => maskCNPJ(e, setCnpj)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        fullWidth
                        label="CPF"
                        variant="outlined" 
                        value={cpf}
                        onChange={(e) => maskCPF(e, setCpf)}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField 
                        fullWidth
                        label="ENDEREÇO" 
                        variant="outlined" 
                        value={endereco}
                        onChange={(e) => setEndereco(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectSimple 
                        label="UF"
                        value={uf}
                        setValue={setUf}
                        options={arrayUfLabel}
                        variant="outlined" 
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutoCompleteCity 
                        codigouf={uf} 
                        value={cidade}
                        setValue={setCidade}
                        variant="outlined" 
                    />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        onClick={() => setOpenDrawer(true)}
                    > 
                        <FaTruck /> &nbsp; ADICIONAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={8}></Grid>

                {infoVeiculo.length > 0 && (
                    <Grid item xs={12} sm={12}>
                        <TableInput 
                            columns={[
                                { id: 'placa', label: 'PLACA', minWidth: 150 },
                                { id: 'uf_placa', label: 'UF DA PLACA', minWidth: 150 },
                                { id: 'codigo_antt', label: 'CODIGO ANTT', minWidth: 150 },
                            ]} 
                            rows={infoVeiculo} 
                            clickFunction={editVeic}
                        />
                    </Grid>
                )}

                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        onClick={hadleSubmit}
                    > 
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>

            <DrawerAddVeic
                state={openDrawer}
                setState={setOpenDrawer}
                setArr={setInfoVeiculo}
                edit={edit}
                setEdit={setEdit}
            />
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}

export default CadastroTransp;