import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaTag, FaYoutube } from 'react-icons/fa';
import qs from 'querystring';
import Swal from 'sweetalert2';
import md5 from 'md5';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Snack from '../../components/Alert/Snack';
import { AlertInfo } from '../../components/Alert/Alert';
import { maskValidadeCartao, maskNumberCartao } from '../../services/mask';
import { americDate, displayDataText, addMountfromDate } from '../../services/calc';
import { displayMoney } from '../../services/displayValue';
import api, { backendURL, apisafe2pay } from '../../services/api';
import { ContainerValorPag, LinkPixCopiaCola, ContainerLinkPix } from './styled';

export default function Pagamento({ rota = '/' }) {
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa') || sessionStorage.getItem('dadosPagamanentoEmpresa')));
    const [is_revenda, setIsRevenda] = useState(null);
    const [isSafe2Pay, setIsSafe2Pay] = useState(true);
    const [api_safe2pay, setApiSafe2Pay] = useState('');
    const [valor_origem, setValorOrigem] = useState(Number(infoEmpresa.valor_sistema));
    const [valor_pagamento, setValorPagamento] = useState(0);
    const [forma_pagamento, setFormaPagamento] = useState('6');
    const [periodo_pagamento, setPeriodoPagamento] = useState('1');
    const [nome_cartao, setNomeCartao] = useState('');
    const [numero_cartao, setNumeroCartao] = useState('');
    const [expiracao, setExpiracao] = useState('');
    const [cvc, setCvc] = useState('');
    const [parcelas, setParcelas] = useState('');
    const [vencimento, setVencimento] = useState(americDate());
    const [openLoad, setOpenLoad] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [messageSnack, setMessageSnack] =  useState('');
    const [isPaid, setIsPaid] = useState(false);
    const [urlQrCode, setUrlQrCode] = useState(null);
    const [pixCopiaCola, setPixCopiaCola] = useState('');
    const [linhaDigitavel, setLinhaDigitavel] = useState('');
    const [linkBoleto, setLinkBoleto] = useState(null);
    const [isCupom,setIsCupom] = useState(null);
    const [linkWhatsapp, setLinkWhatsapp] = useState('');
    const [linkYoutube, setLinkYoutube] = useState('');
    const history = useHistory();

    const calcParcelas = () => {
        let resp = [];
        for (let index = 0; index < Number(periodo_pagamento); index++) {
            const element = {
                "Amount": Number(valor_origem),
                "DueDate": addMountfromDate(vencimento, index),
                "Message": ["Fique atento ao vencimento para evitar multas e juros"]
            };
            resp.push(element);
        }
        return resp;
    }

    const paymentObject = () => {
        let payment = {};
        if(forma_pagamento === "0"){
            payment = {
                "Message": "Emissor Web Renove",
                "PenaltyAmount": 5.00,
                "InterestAmount": 0,
                "BankSlips": calcParcelas()
            }
        }else if(forma_pagamento === "1"){
            payment = {
                "DueDate": displayDataText(vencimento),
                "Instruction": "", 
                "Message": [],
                "CancelAfterDue": false,
                "IsEnablePartialPayment": false
            }
        }else if(forma_pagamento === "2"){
            payment = {
                "Holder": nome_cartao.toUpperCase(),
                "CardNumber": numero_cartao,
                "ExpirationDate": expiracao,
                "SecurityCode": cvc,
                "InstallmentQuantity": parcelas,
                "SoftDescriptor": "RENOVE"
            }
        }

        return payment;
    }

    async function gerarPagamento() {
        setOpenLoad(true);
        setTimeout(async() => {
            const data = {
                "IsSandbox": false,
                "Application": "Sistema Renove Site",
                "Vendor": "Sistema Renove Site",
                "CallbackUrl": `${backendURL}/pagamentos/notifyApiSafe2Pay`,
                "PaymentMethod": Number(forma_pagamento) === 0 ? "1" : forma_pagamento,
                "PaymentObject": paymentObject(),
                "Reference": "PAGAMENTO SISTEMA",
                "Customer": {
                    "Name": infoEmpresa.razao,
                    "Identity": infoEmpresa.cnpj,
                    "Phone": "",
                    "Email": "",
                    "Address": {
                        "ZipCode": infoEmpresa.cep,
                        "Street": infoEmpresa.endereco,
                        "Number": infoEmpresa.numero,
                        "Complement": infoEmpresa.complemento,
                        "District": infoEmpresa.bairro,
                        "CityName": infoEmpresa.cidade,
                        "StateInitials": infoEmpresa.uf,
                        "CountryName": "Brasil"
                    }
                },
                "Products": [
                    {
                        "Code": "001",
                        "Description": "Mensalidade Site Sistema Renove",
                        "UnitPrice": Number(valor_pagamento),
                        "Quantity": 1
                    },
                ]
            };
            try {
                const urlPayment = Number(forma_pagamento) === 0 ? `/carnet` : `/Payment`;
                const response = await apisafe2pay.post(urlPayment, data, {
                    headers: {
                        safe2pay: api_safe2pay
                    }
                });
                console.log(response);
                if(response && response.status === 200 && response.data){
                    if(response.data.HasError){
                        AlertInfo('error', 'FALHA NA OPERAÇÃO', response.data.Error);
                    }else{
                        let dataSave = {};
                        dataSave['id_empresa'] = infoEmpresa.id;
                        dataSave['id_transaction'] = response.data.ResponseDetail.IdTransaction;
                        dataSave['type_transaction'] = forma_pagamento;
                        dataSave['periodo_liberado'] = periodo_pagamento;
                        if(forma_pagamento === "6"){
                            setIsPaid(true);
                            setPixCopiaCola(response.data.ResponseDetail.Key);
                            setUrlQrCode(response.data.ResponseDetail.QrCode);
                            setTimeout(() => consultPagamento(response.data.ResponseDetail.IdTransaction), 5000);
                        }else if(forma_pagamento === "0"){
                            const { CarnetUrl, BankSlips, Identifier } = response.data.ResponseDetail;
                            dataSave['CarnetUrl'] = CarnetUrl;
                            dataSave['BankSlips'] = JSON.stringify(BankSlips);
                            dataSave['Identifier'] = Identifier;
                            dataSave['valor_boleto'] = valor_pagamento;
                            if(window && window.ReactNativeWebView){
                                const dataPost = JSON.stringify({pdf: CarnetUrl})
                                window.ReactNativeWebView.postMessage(dataPost);
                            }else{
                                window.open(CarnetUrl, "_blank");
                            }
                        }else if(forma_pagamento === "1"){
                            dataSave['vencimento'] = vencimento;
                            dataSave['linha_digitavel'] = response.data.ResponseDetail.DigitableLine;
                            dataSave['link_parcela'] = response.data.ResponseDetail.BankSlipUrl;
                            setIsPaid(true);
                            setLinkBoleto(response.data.ResponseDetail.BankSlipUrl);
                            setLinhaDigitavel(response.data.ResponseDetail.DigitableLine);
                        }else{
                            dataSave['status_pagamento'] = response.data.ResponseDetail.Status;
                            dataSave['message'] = response.data.ResponseDetail.Message;
                        }
                        const urlApiSave = Number(forma_pagamento) === 0 ? `/pagamentos/addCarnet` : `/pagamentos/addPagamento`;
                        const resp = await api.post(urlApiSave, qs.stringify(dataSave));
                        console.log(resp);
                        if(resp && resp.status === 200 && resp.data){
                            if(forma_pagamento === "2"){
                                sessionStorage.setItem('infoEmpresa', JSON.stringify(resp.data.infoEmpresa));
                                AlertInfo('success', 'SISTEMA LIBERADO', '').then(() => {
                                    history.push(rota);
                                });
                            }
                            setOpenLoad(false);
                        }
                    }
                }
            } catch (err) {
                console.log(err);
                setOpenLoad(false)
                const txt = `NÃO FOI POSSIVEL GERAR PAGAMENTO VERIFIQUE CONEXÃO COM A INTERNET`;
                AlertInfo('error', txt, '');
            }
        }, 500);
    }

    async function consultPagamento(IdTransaction) {
        let consult = setInterval(async() => {
            console.log('Consultando...');
            const response = await apisafe2pay.get(`/transaction/Get?Id=${IdTransaction}`, {
                headers: {
                    safe2pay: api_safe2pay
                }
            });
            console.log(response);
            if(response && response.status === 200 && response.data){
                if(response.data.ResponseDetail.Status === "3"){
                    clearInterval(consult);
                    console.log('Pagamento Recebido!');
                    const data = qs.stringify({
                        id_empresa: infoEmpresa.id,
                        id_transaction: IdTransaction,
                        status_pagamento: response.data.ResponseDetail.Status,
                        message: response.data.ResponseDetail.Message,
                        periodo_liberado: periodo_pagamento,
                    });
                    const liberar = await api.post(`/pagamentos/liberarFast`, data);
                    if(liberar && liberar.status === 200){
                        sessionStorage.setItem('infoEmpresa', JSON.stringify(liberar.data.success));
                        AlertInfo('success', 'SISTEMA LIBERADO', '').then(() => {
                            history.push(rota);
                        });
                    }
                }else{
                    console.log(response.data.ResponseDetail.Message);
                }
            }
        }, 5000);
    }

    async function alterValueOrigem(detailsCupom) {
        Swal.fire({
            title: 'DIGITE O NOVO VALOR DE COBRANÇA',
            input: 'number',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'ALTERAR',
            confirmButtonColor: '#d33',
            cancelButtonText: 'CANCELAR',
            showLoaderOnConfirm: true,
            inputValidator: (valueInput) => {
                if(!valueInput || Number(valueInput) < Number(detailsCupom.custo_revenda)) return 'VALOR INVALIDO';
            },
            preConfirm: async(cupon) => {
                const data = qs.stringify({
                    id: infoEmpresa.id,
                    cnpj: infoEmpresa.cnpj,
                    revenda: detailsCupom.revenda,
                    valor_sistema: cupon,
                    custo_revenda: detailsCupom.custo_revenda,
                });
                const response = await api.post(`/pagamentos/revendaSite`, data);
                console.log(response);
                if(response.status === 200){
                    if(response.data.error){
                        Swal.showValidationMessage(response.data.error);
                    }else if(response.data.success){
                        setValorOrigem(Number(cupon));
                        return response.data;
                    }
                }else{
                    Swal.showValidationMessage('Falha ao consultar dados!');
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async result => {
            if(result.isConfirmed && result.value){
                AlertInfo('success', result.value.success, '');
            }
        });
    }

    async function consultCuponEspecial() {
        if(!isCupom){
            Swal.fire({
                title: 'DIGITE O CUPON ESPECIAL',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'VERIFICAR',
                confirmButtonColor: '#d33',
                cancelButtonText: 'CANCELAR',
                showLoaderOnConfirm: true,
                inputValidator: (valueInput) => {
                    if(!valueInput) return 'CUPOM INVALIDO';
                },
                preConfirm: async(cupon) => {
                    const data = qs.stringify({
                        id: infoEmpresa.id,
                        cnpj: infoEmpresa.cnpj,
                        cupon_desconto: String(cupon).toUpperCase(),
                    });
                    const response = await api.post(`/pagamentos/validCuponDesconto`, data);
                    console.log(response);
                    if(response.status === 200){
                        if(response.data.error){
                            Swal.showValidationMessage(response.data.error);
                        }else if(response.data.success){
                            return response.data;
                        }
                    }else{
                        Swal.showValidationMessage('Falha ao consultar dados!');
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then(async result => {
                if(result.isConfirmed && result.value){
                    setIsCupom(result.value.details);
                    if(Number(result.value.details.type_cupom) === 3){
                        alterValueOrigem(result.value.details);
                    }else{
                        AlertInfo('success', result.value.success, '').then(() => {
                            if(Number(result.value.details.type_cupom) === 1){
                                sessionStorage.setItem('infoEmpresa', JSON.stringify(result.value.infoEmpresa));
                                history.push(rota);
                            }else if(Number(result.value.details.type_cupom) === 2){
                                setValorOrigem(Number(valor_origem) - Number(result.value.details.desconto_value));
                            }
                        });
                    }
                }
            });
        }else{
            AlertInfo('error', 'VOCÊ JÁ USOU UM CUPOM ESPECIAL');
        }
    }

    function copyLink(text, msg) {
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({clipboard: text});
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            navigator.clipboard.writeText(text);
        }
        setMessageSnack(msg);
        setOpenSnack(true);
    }

    async function loadData() {
        const response = await api.get(`/pagamentos/listPagamentos/${infoEmpresa.id}/1`);
        if(response && response.status === 200 && response.data){
            const { data } = response;
            if(data.length > 0){
                if(Number(data[0].type_transaction) === 1){
                    setLinkBoleto(data[0].link_parcela);
                    setLinhaDigitavel(data[0].linha_digitavel);
                    setIsPaid(true);
                }
            }
        }
        const respLinks = await api.get(`/dados/listLinksUteis`);
        if(respLinks && respLinks.status === 200 && respLinks.data){
            respLinks['data'].forEach(element => {
                if(element.name_link === 'whatsapp') setLinkWhatsapp(element.link);
                else if(element.name_link === 'youtube_pay') setLinkYoutube(element.link);
            });
        }
    }

    async function isRevenda() {
        if(infoEmpresa && infoEmpresa.revenda){
            if(Number(infoEmpresa.revenda) > 0){
                const response = await api.get(`/painel/infoPainel/${md5(infoEmpresa.revenda)}`);
                if(response && response.status === 200 && response.data){
                    if(response.data.success){
                        setIsRevenda(response.data.data);
                        setIsSafe2Pay(Boolean(Number(response.data.data.is_safe2pay)));
                        setApiSafe2Pay(response.data.data.api_safe2pay);
                    }
                }
            }
        }
    }

    function openLinkBoleto() {
        if(linkBoleto){
            if(window && window.ReactNativeWebView){
                const dataPost = JSON.stringify({pdf: linkBoleto})
                window.ReactNativeWebView.postMessage(dataPost);
            }else{
                window.open(linkBoleto, '_blank');
            }
        }
    }

    useEffect(() => {
        setValorPagamento(valor_origem * Number(periodo_pagamento));
        // eslint-disable-next-line
    }, [periodo_pagamento, valor_origem]);

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    // eslint-disable-next-line
    useEffect(() => isRevenda(), []);

    return(
        <Paper elevation={3} style={{padding: 15}}>
            <SimpleBackdrop open={openLoad} />
            <Snack
                open={openSnack}
                setOpen={setOpenSnack}
                time={2000}
                message={messageSnack}
            />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h2 style={{textAlign:'center'}}>
                        EFETUE O PAGAMENTO PARA ULTILIZAR O SISTEMA
                    </h2>
                </Grid>
                <Grid item xs={12}>
                    <span style={{fontWeight:'bold'}}>
                        {infoEmpresa.razao}
                        <br />
                        {infoEmpresa.cnpj}
                    </span>
                </Grid>
                <Grid item xs={false} sm={4} />
                <Grid item xs={12} sm={4}>
                    <ContainerValorPag>
                        <span style={{fontSize:'10pt'}}>VALOR A PAGAR:</span>
                        <h1>
                            {`R$ ${displayMoney(forma_pagamento === "0" ? valor_origem : valor_pagamento)}`}
                        </h1>
                    </ContainerValorPag>
                </Grid>
                <Grid item xs={false} sm={4} />
                {isSafe2Pay && (
                    <>
                        {!isPaid && (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <SelectValueForLabel
                                        variant="outlined" 
                                        label="PERIODO PAGAMENTO"
                                        value={periodo_pagamento}
                                        setValue={setPeriodoPagamento}
                                        options={[
                                            {value: '1', label: 'MENSAL'},
                                            {value: '3', label: 'TRIMESTRAL'},
                                            {value: '6', label: 'SEMESTRAL'},
                                            {value: '12', label: 'ANUAL'},
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectValueForLabel
                                        variant="outlined" 
                                        label="FORMA DE PAGAMENTO"
                                        value={forma_pagamento}
                                        setValue={setFormaPagamento}
                                        options={[
                                            {value: '6', label: 'PIX'},
                                            {value: '2', label: 'CARTÃO CREDITO'},
                                            {value: '1', label: 'BOLETO'},
                                            {value: '0', label: 'CARNÊ BOLETO'},
                                        ]}
                                    />
                                </Grid>

                                {Number(forma_pagamento) < 2 && (
                                    <>
                                        <Grid item xs={false} sm={6} />
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                margin="dense"
                                                label="VENCIMENTO"
                                                type="date"
                                                fullWidth
                                                variant="outlined"
                                                value={vencimento}
                                                onChange={e => setVencimento(e.target.value)}
                                            />
                                        </Grid>
                                    </>
                                )}

                                {forma_pagamento === '2' && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                margin="dense"
                                                label="NOME NO CARTÃO"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={nome_cartao}
                                                onChange={e => setNomeCartao(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                margin="dense"
                                                label="NUMERO CARTÃO"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={numero_cartao}
                                                onChange={e => maskNumberCartao(e, setNumeroCartao)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={(forma_pagamento === "4")?6:4}>
                                            <TextField
                                                margin="dense"
                                                label="DATA EXPIÇÃO"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={expiracao}
                                                onChange={e => maskValidadeCartao(e, setExpiracao)}
                                                placeholder="MM/AAAA"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                type="number"
                                                margin="dense"
                                                label="CODIGO SEGURANÇA"
                                                fullWidth
                                                variant="outlined"
                                                value={cvc}
                                                onChange={e => setCvc(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                margin="dense"
                                                label="PARCELAS"
                                                fullWidth
                                                variant="outlined"
                                                value={parcelas}
                                                onChange={e => setParcelas(e.target.value)}
                                                type="number"
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={false} sm={4}/>
                                <Grid item xs={12} sm={4}>
                                    <Button 
                                        fullWidth 
                                        variant="contained" 
                                        color="primary"
                                        onClick={() => gerarPagamento()}
                                    > 
                                        GERAR PAGAMENTO
                                    </Button>
                                </Grid>
                                <Grid item xs={false} sm={4}/>
                            </>
                        )}
                        {isPaid && (
                            <>
                                {urlQrCode && (
                                    <>
                                        <Grid item xs={12} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                            <img 
                                                src={urlQrCode} 
                                                alt="QrCodePagamento" 
                                                style={{width:'50%'}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ContainerLinkPix>
                                                <span style={{textAlign:'center'}}>PIX COPIA E COLA</span>
                                                <br />
                                                <LinkPixCopiaCola
                                                    onClick={() => copyLink(pixCopiaCola, 'Chave Pix Copiada!')}
                                                >
                                                    {pixCopiaCola}
                                                </LinkPixCopiaCola>
                                            </ContainerLinkPix>
                                        </Grid>
                                    </>
                                )}
                                {linkBoleto && (
                                    <>
                                        <Grid item xs={false} sm={4}/>
                                        <Grid item xs={12} sm={4}>
                                            <Button 
                                                fullWidth 
                                                variant="contained" 
                                                color="primary"
                                                onClick={() => openLinkBoleto()}
                                            > 
                                                VISUALIZAR BOLETO
                                            </Button>
                                        </Grid>
                                        <Grid item xs={false} sm={4}/>
                                        <Grid item xs={12}>
                                            <ContainerLinkPix>
                                                <br />
                                                <span>LINHA DIGITAVEL</span>
                                                <LinkPixCopiaCola
                                                    onClick={() => copyLink(linhaDigitavel, 'Linha Digitavel Copiada!')}
                                                >
                                                    {linhaDigitavel}
                                                </LinkPixCopiaCola>
                                            </ContainerLinkPix>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}

                <Grid item xs={12}>
                    {is_revenda && (
                        <span style={{fontSize:'13pt'}}>
                            SUPORTE: {is_revenda.nome}
                            <br />
                        </span>
                    )}
                    <span style={{fontSize:'8pt'}}>
                        * Pagamentos em PIX, Cartão de Debito e Cartão de Crédito serão 
                        processados em até 10 minutos.
                        <br />
                        * Pagamentos em Boleto levaram de 1 a 3 dias para serem
                        processados.
                        <br />
                        * O sistema só será liberado para uso após o pagamento ser processado.
                    </span>
                </Grid>
                <Grid item xs={12} container justify='center'>
                    <Fab 
                        color="primary" 
                        aria-label="add" 
                        size="medium"
                        onClick={() => consultCuponEspecial()}
                    >
                        <FaTag size={25} />
                    </Fab>
                    <div style={{width:30}} />
                    <a 
                        href={linkWhatsapp}
                        target='_blank'
                        rel="noopener noreferrer"
                    >
                        <Fab 
                            color="primary" 
                            aria-label="add" 
                            size="medium"
                        >
                            <FaWhatsapp size={25} />
                        </Fab>
                    </a>
                    <div style={{width:30}} />
                    <a 
                        href={linkYoutube}
                        target='_blank'
                        rel="noopener noreferrer"
                    >
                        <Fab 
                            color="primary" 
                            aria-label="add" 
                            size="medium"
                        >
                            <FaYoutube size={25} />
                        </Fab>
                    </a>
                </Grid>
            </Grid>
        </Paper>
    );
}