import styled from 'styled-components';

export const BuscaContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
`;

export const InputBusca = styled.input`
    height: 48px;
    outline: none;
    border: 1px solid #a71a1a;
    border-radius: ${props => (props.active) ? ' 5px 22px 22px 5px' : '50px'};
    font-size: 20px;
    width: ${props => (props.active) ? '100%' : '0'};
    position: absolute;
    transition: all .5s ease;
    padding: ${props => (props.active) ? '0 48px 0 8px' : '0 48px 0 0'};
`;