import React, { useState, useRef, useEffect } from 'react';
import qs from 'querystring';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { FaLock, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import InputPassword from '../../components/Inputs/InputPassword';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { maskLogin } from '../../services/mask';
import api from '../../services/api';
import { Container, AvatarIcon, FormContainer, FormGrid } from './Styled';

const Login = () => {
    const [openLoading, setOpenLoading] = useState(false);
    const [user, setUser] = useState('');
    const [linkWhatsapp, setLinkWhatsapp] = useState('');
    const [linkYoutube, setLinkYoutube] = useState('');
    const inputSenha = useRef(null);
    const history = useHistory();

    async function loadData() {
        const respLinks = await api.get(`/dados/listLinksUteis`);
        if(respLinks && respLinks.status === 200 && respLinks.data){
            respLinks['data'].forEach(element => {
                if(element.name_link === 'whatsapp') setLinkWhatsapp(element.link);
                else if(element.name_link === 'youtube_init') setLinkYoutube(element.link);
            });
        }
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    const nextInput = (event, inputRef) => {
        if(event.keyCode === 13){
            inputRef.current.focus();
        }
    }

    const handleLogin = async (u = null, p = null) => {
        const senha = inputSenha.current.value;
        setOpenLoading(true);
        const isUser = u ? u : user;
        const validUser = maskLogin(isUser);
        const login = qs.stringify({ 
            user: validUser, 
            senha: p ? p : senha
        });
        const response = await api.post(`/login/login`, login);
        const { data } = response;
        if(data.empresa && data.empresa.id){
            sessionStorage.setItem('dadosFiscais', JSON.stringify(data.dadosFiscais));
            sessionStorage.setItem('dashboard', JSON.stringify(data.dashboard));
            if(Number(data.empresa.acesso) === 1){
                sessionStorage.setItem('infoEmpresa', JSON.stringify(data.empresa));
                if(data.revenda) sessionStorage.setItem('infoRevenda', JSON.stringify(data.revenda));
                else sessionStorage.removeItem('infoRevenda');
                history.push('/');
            }else{
                sessionStorage.setItem('dadosPagamanentoEmpresa', JSON.stringify(data.empresa));
                history.push('/bloqueio');
            }
        }else if(data.painel && data.painel.id_painel){
            sessionStorage.setItem('painel', JSON.stringify(data.painel));
            history.push('/painel');
        }else{
            console.log(response);
            setOpenLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'LOGIN INVALIDO!',
                confirmButtonColor: '#a71a1a',
            });
        }
    }

    useEffect(() => {
        const parms = window.location.search;
        const parmsSearch = new URLSearchParams(parms);
        const arrParms = parmsSearch.toString().split("=");
        if(arrParms.length === 2){
            const [u, p] = arrParms;
            handleLogin(u, decodeURIComponent(p));
        }
        // eslint-disable-next-line
    }, [])

    return(
        <Container>
            <AvatarIcon>
                <FaLock />
            </AvatarIcon>
            <Typography component="h1">Login</Typography>
            <FormContainer>
                <FormGrid>
                    <TextField 
                        label="E-mail ou CNPJ/CPF" 
                        variant="outlined"
                        onKeyUp={(e) => nextInput(e, inputSenha)}
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        fullWidth 
                        autoFocus
                    />
                </FormGrid>
                <FormGrid>
                    <InputPassword
                        label="Senha"
                        inputRef={inputSenha}
                        onKeyUp={(e) => { if(e.keyCode === 13) handleLogin() }}
                    />
                </FormGrid>
                <FormGrid>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary"
                        onClick={() => handleLogin()}
                    > 
                        ENTRAR
                    </Button>
                </FormGrid>
                <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            Esqueci a senha
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2" onClick={() => history.push('/cadastroempresa')}>
                            Não tem login? Cadastre-se
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <br /><br />
                    </Grid>
                    <Grid item xs={12} container justify='center'>
                        <a 
                            href={linkWhatsapp}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            <Fab 
                                color="primary" 
                                aria-label="add" 
                                size="medium"
                            >
                                <FaWhatsapp size={25} />
                            </Fab>
                        </a>
                        <div style={{width:30}} />
                        <a 
                            href={linkYoutube}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            <Fab 
                                color="primary" 
                                aria-label="add" 
                                size="medium"
                            >
                                <FaYoutube size={25} />
                            </Fab>
                        </a>
                    </Grid>
                </Grid>
            </FormContainer>
            <Box mt={8}>
            <Typography variant="body2" color="textSecondary" align="center">
                Copyright © &nbsp;
                <Link color="inherit" href="https://renovetecnologia.com" target="_blank">
                    Renove Tecnologia
                </Link>
            </Typography>
            </Box>
            <SimpleBackdrop open={openLoading} />
        </Container>
    );
}

export default Login;