import React, { Fragment } from 'react';
import qs from 'querystring';
import { FaTimes, FaTags, FaTrash, FaPlus, FaCalendarCheck, FaCalendarTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { AlertConfirm, AlertInfo } from '../Alert/Alert';
import { displayDataText } from '../../services/calc';
import api from '../../services/api';
import { Primary, Secondary } from './styled';

const useStyles = makeStyles({
    list: {
        maxWidth: 450,
        minWidth: 320
    },
    icon: {
        minWidth: 24, 
        color: '#222',
    }
});

export default function DrawerInfoEmpresa({ infoEmpresa, state, setState, load, update }) {
    const classes = useStyles();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    function remover() {
        const title = 'REMOVER ESSA EMPRESA DO SISTEMA?';
        setState(false);
        AlertConfirm('', title, '', 'SIM').then(async(resp) => {
            if(resp.isConfirmed){
                load(true);
                const data = qs.stringify({
                    id_empresa: infoEmpresa.id,
                    acesso: 0,
                });
                const response = await api.post(`/empresas/control`, data);
                if(response.status === 200){
                    AlertInfo('success', 'DADOS GRAVADOS!', '');
                    update();
                    return;
                }
                load(false);
                AlertInfo('error', 'FALHA NA OPERAÇÃO!', '');
            }
        });
    }

    async function upCliente() {
        const painel = JSON.parse(sessionStorage.getItem('painel'));
        const data = qs.stringify({ id_painel: painel.id_painel, id_cliente: infoEmpresa.id });
        const response = await api.post(`/painel/addControl`, data);
        if(response.status === 200){
            update();
            return;
        }
    }

    async function addByPainel() {
        load(true);
        setState(false);
        const painel = JSON.parse(sessionStorage.getItem('painel'));
        const empresas = await api.get(`/empresas/updateReference/${infoEmpresa.id}/${painel.x_api_key}`);
        if(empresas && empresas.status === 200 && empresas.data){
            if(empresas.data.success){
                upCliente();
            }
        }
        load(false);
    }

    const list = () => (
        <div
            role="presentation"
            className={classes.list}
        >
            <List>
                <ListItem>
                    <ListItemText primary="Informações da Empresa" />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer(false)} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>

                {infoEmpresa && (
                    <>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaTags /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>
                                    {`
                                        ${infoEmpresa.razao.substring(0,25)}
                                        ${(infoEmpresa.razao.length > 25) ? '...': ''}
                                    `}
                                </Primary>
                                <Secondary>Razão Social</Secondary>
                            </ListItemText>
                        </ListItem>

                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaTags /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>
                                    {`
                                        ${infoEmpresa.fantasia.substring(0,25)}
                                        ${(infoEmpresa.fantasia.length > 25) ? '...': ''}
                                    `}
                                </Primary>
                                <Secondary>Fantasia</Secondary>
                            </ListItemText>
                        </ListItem>

                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaTags /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{ infoEmpresa.cnpj }</Primary>
                                <Secondary>CNPJ</Secondary>
                            </ListItemText>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaTags /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{ infoEmpresa.ie }</Primary>
                                <Secondary>Incrição Estadual</Secondary>
                            </ListItemText>
                        </ListItem>

                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaTags /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{ `${infoEmpresa.cidade}/${infoEmpresa.uf}` }</Primary>
                                <Secondary>Cidade</Secondary>
                            </ListItemText>
                        </ListItem>

                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaCalendarCheck /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{displayDataText(infoEmpresa.data_ativacao)}</Primary>
                                <Secondary>Data de Ativação</Secondary>
                            </ListItemText>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon className={classes.icon}> <FaCalendarTimes /> </ListItemIcon>
                            <ListItemText disableTypography>
                                <Primary>{displayDataText(infoEmpresa.data_expiracao)}</Primary>
                                <Secondary>Data de Expiração</Secondary>
                            </ListItemText>
                        </ListItem>

                        {!infoEmpresa.add && (
                            <ListItem alignItems="flex-start">
                                <Button 
                                    onClick={() => remover()}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                >
                                    <FaTrash style={{ marginRight: '6px' }} />
                                    REMOVER
                                </Button>
                            </ListItem>
                        )}

                        {infoEmpresa.add && (
                            <ListItem alignItems="flex-start">
                                <Button 
                                    onClick={() => addByPainel()}
                                    fullWidth 
                                    variant="contained" 
                                    color="primary"
                                >
                                    <FaPlus style={{ marginRight: '6px' }} />
                                    ADICIONAR PARA MIM
                                </Button>
                            </ListItem>
                        )}
                    </>
                )}
            </List>
        </div>
    );

    return (
        <Fragment>
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </Fragment>
    );
}
