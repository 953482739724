import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuFluter from '../Menu/MenuFluter';
import { TitleBar } from './styled';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function TopBar({ toggle, barName = '' }) {
  const classes = useStyles();
  const history = useHistory();
  const [menuUser, setMenuUser] = useState(null);
  const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')) || {fantasia:barName});

  function logout(){
    if(window && window.ReactNativeWebView){
      const dataPost = JSON.stringify({logout: true})
      window.ReactNativeWebView.postMessage(dataPost);
    }else{
      sessionStorage.removeItem('infoEmpresa');
      sessionStorage.removeItem('dadosFiscais');
      history.push('/');
    }
  }

  const arrMenuUser = [
    { icon: FaSignOutAlt, txt: 'SAIR', func: logout }
  ];

  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <Toolbar>
            <IconButton 
                edge="start" 
                className={classes.menuButton} 
                color="inherit" 
                aria-label="menu"
                onClick={() => toggle((value) => !value)}
                >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <TitleBar defaultValue={infoEmpresa.fantasia} disabled />
            </Typography>
            <IconButton aria-label="show 17 new notifications" color="inherit">
                <Badge badgeContent={0} color="default">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <IconButton 
              color="inherit" 
              aria-label="menu"
              onClick={e => setMenuUser(e.currentTarget)}
            >
                <AccountCircle/>
            </IconButton>
            <MenuFluter 
              anchorEl={menuUser} 
              setAnchorEl={setMenuUser} 
              arrMenu={arrMenuUser}
            />
        </Toolbar>
      </AppBar>
    </div>
  );
}
