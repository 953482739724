export const ValidCliente = (nome, tipopessoa, cpfcnpj, endereco, bairro, codigouf, cidade, cep) => {
    if(nome.length < 3) return { error: 'Nome Invalido!' };
    if(tipopessoa === '0'){
        if(cpfcnpj.length !== 14) return { error: 'CPF Invalido!' };
    }else{
        if(cpfcnpj.length !== 18) return { error: 'CNPJ Invalido!' };
    }
    if(endereco === '') return { error: 'Endereço Invalido!' };
    if(bairro === '') return { error: 'Bairro Invalido!' };
    if(codigouf === '') return { error: 'UF Invalida!' };
    if(!cidade) return { error: 'Cidade Invalida!' };
    if(cep.length !== 9) return { error: 'CEP Invalido!' };

    return null;
}

export const ValidProd = (descricao, codigo_barras, preco, cfop, ncm) => {
    if(descricao.length < 3) return { error: 'Descrição Invalida!' };
    if(codigo_barras.length > 0 && codigo_barras.length < 13) return { error: 'Codigo de Barras Invalido!' };
    if(preco.length === 0) return { error: 'Preço Invalido!' };
    if(!cfop.cfop) return { error: 'CFOP Invalido!' };
    if(ncm.length !== 8) return { error: 'NCM Invalido!' };
    return null;
}

export const ValidTranporte = (nome, cpf, cnpj, endereco, cidade) => {
    if(nome.length < 3) return { error: "Nome Invalido!" };
    if(cpf.length === 0 && cnpj.length === 0) return { error: "Preencha CNPJ ou CPF!" };
    if(cpf.length > 0 && cpf.length < 14) return { error: "CPF invalido!" };
    if(cnpj.length > 0 && cnpj.length < 18) return { error: "CNPJ invalido!" };
    if(endereco.length < 3) return { error: "Endereço invalido!" };
    if(!cidade) return { error: "Cidade Invalida!" };

    return null;
}

export const ValidDados = (data) => {
    const { cfop, nextnfe, nextnfce, serie_nfe, tipopessoa } = data;
    if(tipopessoa === "0"){
        if(Number(serie_nfe) < 920 || Number(serie_nfe) > 969) return { error: 'SERIE DA NFe INVALIDA! DIGITE UMA SERIE DE 920 À 969' };
    }
    if(!cfop) return { error: 'CFOP Invalido!' };
    if(nextnfe === '') return { error: 'Proxima NFe Invalida!' };
    if(nextnfce === '') return { error: 'Proxima NFCe Invalida!' };
    return null;
}

export const ValidNFe = (activeStep, data) => {
    if(activeStep === 0){
        if(data.cfopNota === null) return { error: "CFOP da NFe invalido!" };
        if(data.numero === "" || data.numero < 1) return { error: "Numero da NFe invalido!" };
        if(data.ref_nfe.length > 0){
            if(data.ref_nfe.length !== 44) return { error: "Chave de referência invalida" };
        }
    }else if(activeStep === 1){
        if(!data.infoCliente.id) return { error: "Selecione um cliente!" };
    }else if(activeStep === 2){
        if(!data.arrayProdutos[0]) return { error: "Adicione um produto!" };
    }else if(activeStep === 3){
        //
    }

    return null;
}

export const ValidNFCe = (activeStep, data) => {
    if(activeStep === 0){
        if(!data.arrayProdutos[0]) return { error: "Adicione um produto!" };
    }

    return null;
}

export const ValidEmpresa = (data) => {
    const { tipopessoa, razao, cnpj, ie, endereco, bairro, codigomunicipio, id, senha } = data;
    if(razao.length < 3) return { error: 'Razão social Invalida!' };
    if(tipopessoa === "1" && cnpj.length !== 18) return { error: 'CNPJ Invalido!' };
    if(tipopessoa === "0" && cnpj.length !== 14) return { error: 'CPF Invalido!' };
    if(ie.length < 3) return { error: 'Inscrição Estadual Invalida!' };
    if(endereco.length < 3) return { error: 'Endereço Invalido!' };
    if(bairro.length < 3) return { error: 'Bairro Invalido!' };
    if(codigomunicipio.length < 3) return { error: 'Cidade Invalida!' };
    if(id === null && senha === '') return { error: 'Digite uma senha valida!' };
    return null;
}

export const ValidCadastro = (activeStep, data) => {
    if(activeStep === 0){
        const { cnpj, tipopessoa } = data;
        if(cnpj.length !== 18 && tipopessoa === "1") return { error: 'CNPJ Invalido!' };
        else if(cnpj.length !== 14 && tipopessoa === "0") return { error: 'CPF Invalido!' };
    }else if(activeStep === 1){
        const valid = ValidEmpresa(data);
        return valid;
    }else if(activeStep === 2){
        const valid = ValidDados(data);
        return valid;
    }

    return null;
}