import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Template from '../../components/Template/Template';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertConfirm, AlertInfo } from '../../components/Alert/Alert';
import { ValidNFCe } from '../../services/validForms';
import api from '../../services/api';

import ClienteFiscal from './components/ClienteFiscal';
import ProdutoFiscal from './components/ProdutoFiscal';
import PagamentoFiscal from './components/PagamentoFiscal';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    steps: {
        padding: 0,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Produtos da NFCe', 'Detalhes'];
}

const NFCe = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [openLoad, setOpenLoad] = useState(false);
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [nfce_id] = useState((props.location.state) ? props.location.state.nfce_id : null);
    const steps = getSteps();
    const history = useHistory();

    const [dados_fiscais, setDadosFiscais] = useState(null);
    const [numero, setNumero] = useState("0");
    const [infoCliente, setInfoCliente] = useState({});
    const [arrayProdutos, setArrayProdutos] = useState([]);
    const [pagamento, setPagamento] = useState("01");
    const [numeroParcelas, setNumeroParcelas] = useState(1);
    const [parcelas, setParcelas] = useState([]);
    const [somatoria, setSomatoria] = useState([]);
    const [cpf_cnpj_avulso, setCpfCnpjAvulso] = useState("");
    
    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    async function loadData() {
        setOpenLoad(true);
        const resDadosFiscais = await api.get(`configs/listDadosFiscais/${infoEmpresa.id}`);
        setDadosFiscais(resDadosFiscais.data);
        if(nfce_id){
            const response = await api.get(`/nfce/infoNfceById/${nfce_id}/${infoEmpresa.id}`);
            if(response.status === 200 && response.data){
                const { infoNfce, infoCliente, produtos, duplicatas } = response.data;
                setSomatoria([{
                    frete: infoNfce.frete,
                    seguro: infoNfce.seguro,
                    despesas: infoNfce.despesas,
                    desconto_final: infoNfce.desconto_final,
                }]);
                setNumeroParcelas(duplicatas.length);
                setNumero(infoNfce.numero);
                setInfoCliente(infoCliente);
                setPagamento(infoNfce.pagamento);
                setArrayProdutos(produtos);
                setParcelas(duplicatas);
                setCpfCnpjAvulso(infoNfce.cpf_cnpj_avulso);
            }
        }else{
            setNumero(resDadosFiscais.data.nextnfce);
        }
        setOpenLoad(false);
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return(
                    <ProdutoFiscal
                        arrayProdutos={arrayProdutos}
                        setArrayProdutos={setArrayProdutos}
                        somatoria={somatoria}
                        setSomatoria={setSomatoria}
                        modelo={65}
                        dadosFiscais={dados_fiscais}
                    />
                );
            case 1:
                return(
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <ClienteFiscal 
                                infoCliente={infoCliente}
                                setInfoCliente={setInfoCliente}
                                buttonSize={6}
                                resize={true}
                            />
                            {!infoCliente.id && (
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} container justify="center">
                                        OU
                                    </Grid>
                                    <Grid item xs={false} sm={6} />
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth
                                            size="small"
                                            label="CPF ou CNPJ"
                                            variant="outlined" 
                                            value={cpf_cnpj_avulso}
                                            onChange={(e) => setCpfCnpjAvulso(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PagamentoFiscal
                                pagamento={pagamento}
                                setPagamento={setPagamento}
                                numeroParcelas={numeroParcelas} 
                                setNumeroParcelas={setNumeroParcelas}
                                parcelas={parcelas}
                                setParcelas={setParcelas}
                                somatoria={somatoria}
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return 'Falha ao Exibir dados!';
        }
    }

    const handleSave = () => {
        AlertConfirm('', 'GRAVAR DADOS ATUAIS?', '', 'SIM').then(async(resp) => {
            if(resp.isConfirmed){
                setOpenLoad(true);
                const data = {
                    id: nfce_id,
                    id_empresa: infoEmpresa.id,
                    numero,
                    cliente: JSON.stringify(infoCliente),
                    produtos: JSON.stringify(arrayProdutos),
                    totais: JSON.stringify(somatoria),
                    pagamento,
                    parcelas: JSON.stringify(parcelas),
                    cpf_cnpj_avulso,
                }
                const response = await api.post(`/nfce/setup`, qs.stringify(data));
                console.log(response);
                setOpenLoad(false);
                if(response.status === 200){
                    AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                        history.push('/notafiscal');
                    });
                }else{
                    AlertInfo('error', 'FALHA AO GRAVAR DADOS!');
                }
            }
        });
    }

    const handleNext = () => {
        const data = {
            infoCliente,
            arrayProdutos,
            pagamento,
        }
        const valid = ValidNFCe(activeStep, data);
        if(!valid){
            if(activeStep === 1){
                handleSave();
            }else{
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }else{
            AlertInfo('error', valid.error, '');
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return(
        <Template menuActive="notafiscal">
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical" className={classes.steps}>
                    {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                        <Typography>{getStepContent(index)}</Typography>
                        <div className={classes.actionsContainer}>
                            <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                            >
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                {activeStep === steps.length - 1 ? 'Finalizar' : 'Proximo'}
                            </Button>
                            </div>
                        </div>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
            </div>
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}

export default NFCe;