import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Template from '../../components/Template/Template';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import AutoCompleteCfop from '../../components/Select/AutoCompleteCfop';
import { AlertConfirm, AlertInfo } from '../../components/Alert/Alert';
import { arrayCSOSN, arrayCST } from '../../data/options';
import { ValidDados } from '../../services/validForms';
import { displayMoney, converterMoeda } from '../../services/displayValue';
import api from '../../services/api';

const DadosIniciais = () => {
    const [crt, setCrt] = useState('1');
    const [cfop, setCfop] = useState({});
    const [csosn, setCsosn] = useState('');
    const [cst, setCst] = useState('');
    const [origem, setOrigem] = useState('0');
    const [nextnfe, setNextnfe] = useState('');
    const [nextnfce, setNextnfce] = useState('');
    const [token, setToken] = useState('');
    const [ambiente, setAmbiente] = useState('');
    const [adicional_padrao, setAdicionalPadrao] = useState('');
    const [openLoad, setOpenLoad] = useState(false);
    const [serie_nfe, setSerieNFe] = useState("1");
    const [serie_nfce, setSerieNFCe] = useState("1");
    const [aliq_icms_padrao, setAliqIcmsPadrao] = useState('');
    const [aliq_pis_padrao, setAliqPisPadrao] = useState('');
    const [aliq_cofins_padrao, setAliqCofinsPadrao] = useState('');
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    async function loadData() {
        setOpenLoad(true);
        const response = await api.get(`configs/listDadosFiscais/${infoEmpresa.id}`);
        if (response.status === 200) {
            setCrt(response.data.crt);
            setCfop({ cfop: response.data.cfop, natureza: response.data.natureza });
            setCsosn(response.data.csosn);
            setCst(response.data.cst);
            setOrigem(response.data.origem);
            setNextnfe(response.data.nextnfe);
            setNextnfce(response.data.nextnfce);
            setToken(response.data.token);
            setAmbiente(response.data.ambiente);
            setAdicionalPadrao(response.data.adicional_padrao);
            setSerieNFe(response.data.serie_nfe);
            setSerieNFCe(response.data.serie_nfce);
            setAliqIcmsPadrao(displayMoney(response.data.aliq_icms_padrao));
            setAliqPisPadrao(displayMoney(response.data.aliq_pis_padrao));
            setAliqCofinsPadrao(displayMoney(response.data.aliq_cofins_padrao));
            sessionStorage.setItem('dadosFiscais', JSON.stringify(response.data));
        }
        setOpenLoad(false);
    }

    function hadleSubmit() {
        const tipopessoa = infoEmpresa.tipopessoa;
        if (tipopessoa === "0" && (Number(serie_nfe) < 920 || Number(serie_nfe) > 969)) {
            AlertInfo('error', 'SERIE NFe INCORRETA PARA EMITENTE PESSOA FISICA');
        } else {
            AlertConfirm('', 'SALVAR ESTES DADOS', '', 'SIM').then(async (resp) => {
                if (resp.isConfirmed) {
                    setOpenLoad(true);
                    const data = {
                        crt, csosn, cst, origem, nextnfe, nextnfce, token, ambiente,
                        serie_nfe, serie_nfce, adicional_padrao,
                        id_empresa: infoEmpresa.id,
                        cfop: cfop.cfop,
                        natureza: cfop.natureza,
                        aliq_icms_padrao: converterMoeda(aliq_icms_padrao),
                        aliq_pis_padrao: converterMoeda(aliq_pis_padrao),
                        aliq_cofins_padrao: converterMoeda(aliq_cofins_padrao)
                    };
                    const valid = ValidDados(data);
                    if (!valid) {
                        const response = await api.post(`/configs/editCodigosFiscais`, qs.stringify(data));
                        if (response.status === 200) {
                            AlertInfo('success', 'DADOS GRAVADOS COM SUCESSO!', '');
                            loadData();
                        } else {
                            AlertInfo('error', 'FALHA AO GRAVAR!', '');
                        }
                    } else {
                        AlertInfo('error', valid.error, '');
                    }
                    setOpenLoad(false);
                }
            });
        }
    }

    return (
        <Template menuActive="dados">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h2>DADOS FISCAIS</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectValueForLabel
                        label="REGIME TRIBUTARIO"
                        value={crt}
                        setValue={setCrt}
                        variant="outlined"
                        options={[
                            { value: 1, label: 'Simples Nacional' },
                            { value: 2, label: 'Simples Nacional, excesso sublimite de receita bruta' },
                            { value: 3, label: 'Regime Normal' },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    {Number(crt) === 3 && (
                        <SelectValueForLabel
                            label="CST"
                            value={cst}
                            setValue={setCst}
                            options={arrayCST}
                            variant="outlined"
                        />
                    )}
                    {Number(crt) !== 3 && (
                        <SelectValueForLabel
                            label="CSOSN"
                            value={csosn}
                            setValue={setCsosn}
                            options={arrayCSOSN}
                            variant="outlined"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <AutoCompleteCfop
                        value={cfop}
                        setValue={setCfop}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectValueForLabel
                        label="AMBIENTE"
                        value={ambiente}
                        variant="outlined"
                        setValue={setAmbiente}
                        options={[
                            { value: 1, label: 'PRODUÇÃO' },
                            { value: 2, label: 'HOMOLOGAÇÃO' },
                        ]}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        type="number"
                        label="SERIE NFe"
                        variant="outlined"
                        value={serie_nfe}
                        onChange={(e) => setSerieNFe(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        type="number"
                        label="NUMERO DA PROXIMA NFe"
                        variant="outlined"
                        value={nextnfe}
                        onChange={(e) => setNextnfe(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        type="number"
                        label="SERIE NFCe"
                        variant="outlined"
                        value={serie_nfce}
                        onChange={(e) => setSerieNFCe(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        type="number"
                        label="NUMERO DA PROXIMA NFCe"
                        variant="outlined"
                        value={nextnfce}
                        onChange={(e) => setNextnfce(e.target.value)}
                    />
                </Grid>
                
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="TOKEN NFCe"
                        variant="outlined"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                    />
                </Grid>
                <Grid item xs={false} sm={8}>
                    <TextField
                        fullWidth
                        label="TEXTO ADICIONAL"
                        variant="outlined"
                        value={adicional_padrao}
                        onChange={(e) => setAdicionalPadrao(String(e.target.value).toUpperCase())}
                    />
                </Grid>

                {Number(crt) === 3 && (
                    <>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="ALIQUOTA DE ICMS"
                                variant="outlined"
                                value={aliq_icms_padrao}
                                onChange={(e) => setAliqIcmsPadrao(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="ALIQUOTA DE PIS"
                                variant="outlined"
                                value={aliq_pis_padrao}
                                onChange={(e) => setAliqPisPadrao(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="ALIQUOTA DE COFINS"
                                variant="outlined"
                                value={aliq_cofins_padrao}
                                onChange={(e) => setAliqCofinsPadrao(e.target.value)}
                            />
                        </Grid>
                    </>
                )}

                <Grid item xs={12}><hr /></Grid>

                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={hadleSubmit}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}

export default DadosIniciais;