import styled from 'styled-components';

export const PaperContainerCard = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 25px;
`;
export const PaperLabel = styled.span`
    font-size: 13pt;
    color: #65748b;
`;
export const PaperValue = styled.span`
    font-size: 22pt;
    color: #000;
    font-weight: bold;
`;