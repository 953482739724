import React, { useState, useEffect } from 'react';
import { FaCalendarMinus, FaInfinity } from 'react-icons/fa';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Template from '../../components/Template/Template';
import CardHome from '../../components/Cards/CardHome';
import GraphicColumn from '../../components/Graphics/GraphicColumn';
import ViewList from '../../components/Views/ViewList';
import { displayMoney, displayDataBrl } from '../../services/displayValue';
import { abvMes } from '../../services/calc';
import api from '../../services/api';

const Home = () => {
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [isLimited] = useState(Number(infoEmpresa.limite) > 0 ? true : false);
    const [daysRest] = useState(Number(moment(infoEmpresa.data_expiracao).diff(moment(), 'days')));
    const [limiteLabel] = useState(displayMoney(infoEmpresa.limite));
    const [dataLabel] = useState(displayDataBrl(infoEmpresa.data_expiracao));
    const [dashboard, setDashboard] = useState(JSON.parse(sessionStorage.getItem('dashboard')) || {});
    const [nfeNumber, setNfeNumber] = useState(dashboard.card.nfe.nfe_count || 0);
    const [nfeValue, setNfeValue] = useState(dashboard.card.nfe.nfe_value || '0.00');
    const [nfceNumber, setNfceNumber] = useState(dashboard.card.nfce.nfce_count || 0);
    const [nfceValue, setNfceValue] = useState(dashboard.card.nfce.nfce_value || '0.00');
    const [grafico, setGrafico] = useState([ {name: 'a'}, {name: 'b'}, {name: 'c'}, { name: 'd'} ]);
    const [state, setState] = useState(false);

    async function loadData() {
        const response = await api.get(`/login/login/${infoEmpresa.id}`);
        if(response.status === 200 && response.data){
            sessionStorage.setItem('dashboard', JSON.stringify(response.data.dashboard));
            setDashboard(response.data.dashboard);
            setNfeNumber(response.data.dashboard.card.nfe.nfe_count || 0);
            setNfeValue(response.data.dashboard.card.nfe.nfe_value || '0.00');
            setNfceNumber(response.data.dashboard.card.nfce.nfce_count || 0);
            setNfceValue(response.data.dashboard.card.nfce.nfce_value || '0.00');
        }
    }

    useEffect(() => {
        const obj = dashboard.grafico;
        const arrKey = abvMes(3);
        const newDataGrafico = obj.map((item, index) => {
            return { name: arrKey[index], values: item };
        });
        setGrafico(newDataGrafico);
        setState(true);
        // eslint-disable-next-line
    }, [dashboard]);

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    useEffect(() => {
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({stopLoad: true})
            window.ReactNativeWebView.postMessage(dataPost);
        }
    }, []);

    return(
        <Template menuActive="home">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <CardHome
                        title="NFe"
                        count={nfeNumber}
                        label="Emissões este mês"
                        legend={`Total: R$ ${displayMoney(nfeValue)}`}
                        displayButton="/nfe"
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <CardHome
                        title="NFCe"
                        count={nfceNumber}
                        label="Emissões este mês"
                        legend={`Total: R$ ${displayMoney(nfceValue)}`}
                        displayButton="/nfce"
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <CardHome
                        title="LICENÇA"
                        count={isLimited ? <FaInfinity /> : daysRest}
                        label="Dias restantes de uso"
                        legend={isLimited?`Limite R$ ${limiteLabel}/mês`:`Expira em: ${dataLabel}`}
                        displayButton={(isLimited || daysRest < 6) ? "/renovar" : null}
                    />
                </Grid>
                <Grid item xs={false} sm={3} />

                {state && (
                    <Grid item xs={12} sm={6}>
                        <GraphicColumn data={grafico} />
                    </Grid>
                )}
                {state && (
                    <Grid item xs={12} sm={6}>
                        <Typography>Informações</Typography>
                        {dashboard.logs.nfe.update_at && (
                            <ViewList>
                                <FaCalendarMinus/>
                                ULTIMA NFe EMITIDA: 
                                {displayDataBrl(dashboard.logs.nfe.update_at)} 
                            </ViewList>
                        )}
                        {dashboard.logs.nfce.update_at && (
                            <ViewList>
                                <FaCalendarMinus/>
                                ULTIMA NFCe EMITIDA: 
                                {displayDataBrl(dashboard.logs.nfce.update_at)} 
                            </ViewList>
                        )}
                    </Grid>
                )}
            </Grid>
        </Template>
    );
}

export default Home;