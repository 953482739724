import moment from 'moment';

const arrAbvMes = ["0", "JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];

export const calcParcelas = (numeroParcela) => {
    const data = moment().add(numeroParcela, 'months');
    const dataFormat = moment(data).format('YYYY-MM-DD');
    return dataFormat;
}

export const abvMes = (repet) => {
    let arrResp = [];
    for(var i=repet; i >= 0; i--){
        const key = moment().subtract(i, 'months').format('M');
        arrResp.push(arrAbvMes[key]);
    }
    return arrResp;
}

export const displayDataText = (data) => {
    const dt = moment(data).format('DD/MM/YYYY');
    return dt;
}

export const americDate = (data = new Date()) => moment(data).format('YYYY-MM-DD');
export const BrasilDate = (data = new Date()) => moment(data).format('DD/MM/YYYY');

export const addMountfromDate = (inicialData, index) => {
    const data = moment(inicialData).add('month', index).format('YYYY-MM-DD');
    return data;
}

export const startMes = (parseData = new Date()) => {
    const data = moment(parseData).startOf('month');
    const dataFormat = americDate(data);
    return dataFormat;
}

export const endMes = (parseData = new Date()) => {
    const data = moment(parseData).endOf('month');
    const dataFormat = americDate(data);
    return dataFormat;
}