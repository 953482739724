import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Template from '../../components/Template/Template';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertConfirm, AlertInfo } from '../../components/Alert/Alert';
import { ValidNFe } from '../../services/validForms';
import api from '../../services/api';

import HeaderFiscal from './components/HeaderFiscal';
import ClienteFiscal from './components/ClienteFiscal';
import ProdutoFiscal from './components/ProdutoFiscal';
import FreteFiscal from './components/FreteFiscal';
import PagamentoFiscal from './components/PagamentoFiscal';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    steps: {
        padding: 0,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Cabeçalho da NFe', 'Destinatario', 'Produtos', 'Detalhes'];
}

const NFe = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [openLoad, setOpenLoad] = useState(false);
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [nfe_id] = useState((props.location.state) ? props.location.state.nfe_id : 0);
    const steps = getSteps();
    const history = useHistory();

    const [dados_fiscais, setDadosFiscais] = useState(null);
    const [tabela_icms, setTabelaIcms] = useState([]);
    const [cfopNota, setCfopNota] = useState({});
    const [numero, setNumero] = useState("0");
    const [finalidade, setFinalidade] = useState("1");
    const [ref_nfe, setRefNfe] = useState("");
    const [infoCliente, setInfoCliente] = useState({});
    const [arrayProdutos, setArrayProdutos] = useState([]);
    const [pagamento, setPagamento] = useState("01");
    const [numeroParcelas, setNumeroParcelas] = useState(1);
    const [parcelas, setParcelas] = useState([]);
    const [transporte, setTransporte] = useState("9");
    const [infoTransp, setInfoTransp] = useState({});
    const [infoVeiculo, setInfoVeiculo] = useState({});
    const [volume, setVolume] = useState("");
    const [especie, setEspecie] = useState("");
    const [marca, setMarca] = useState("");
    const [numeracao, setNumeracao] = useState("");
    const [peso_liquido, setPesoLiquido] = useState("");
    const [peso_bruto, setPesoBruto] = useState("");
    const [adicional, setAdicional] = useState('');
    const [somatoria, setSomatoria] = useState([]);
    
    // eslint-disable-next-line
    useEffect(() => loadData(), []);
    
    useEffect(() => {
        if(infoCliente && infoCliente.id && tabela_icms.length > 0){
            if(infoCliente.uf !== infoEmpresa.uf){
                const getAliqIcms = tabela_icms.filter(i => i.uf_origem === infoEmpresa.uf && i.uf_destino === infoCliente.uf);
                if(getAliqIcms.length === 1) setDadosFiscais({...dados_fiscais, aliq_icms_padrao: getAliqIcms[0].aliq_icms});
            }
        }
        // eslint-disable-next-line
    }, [infoCliente]);

    useEffect(() => {
        if(finalidade === '4') setPagamento("90");
    }, [finalidade]);

    async function loadData() {
        setOpenLoad(true);
        const response = await api.get(`/nfe/createNfe/${nfe_id}/${infoEmpresa.id}`);
        console.log(response);
        if(response && response.status === 200 && response.data){
            if(Number(nfe_id) > 0){
                const { infoNfe, infoCliente, produtos, duplicatas } = response.data.infoNf;
                setSomatoria([{
                    frete: infoNfe.frete,
                    seguro: infoNfe.seguro,
                    despesas: infoNfe.despesas,
                    desconto_final: infoNfe.desconto_final,
                }]);
                setNumeroParcelas(duplicatas.length);
                setCfopNota({ cfop: infoNfe.cfop_nfe, natureza: infoNfe.natureza });
                setNumero(infoNfe.numero);
                setFinalidade(infoNfe.finalidade);
                setRefNfe(infoNfe.ref_nfe);
                setInfoCliente(infoCliente);
                setPagamento(infoNfe.pagamento);
                setTransporte(infoNfe.transporte);
                setArrayProdutos(produtos);
                setAdicional(infoNfe.adicional);
                setParcelas(duplicatas);
                setInfoTransp(response.data.transporte);
                setInfoVeiculo(response.data.veiculo);
                setVolume(infoNfe.volume);
                setEspecie(infoNfe.especie);
                setMarca(infoNfe.marca);
                setNumeracao(infoNfe.numeracao);
                setPesoLiquido(infoNfe.peso_liquido);
                setPesoBruto(infoNfe.peso_bruto);
            }else{
                const cfopFiscal = response.data.dados_fiscais.cfop;
                const natuzaFiscal = response.data.dados_fiscais.natureza;
                setCfopNota({ cfop: cfopFiscal, natureza: natuzaFiscal });
                setNumero(response.data.dados_fiscais.nextnfe);
                setAdicional(response.data.dados_fiscais.adicional_padrao);
            }

            setDadosFiscais(response.data.dados_fiscais);
            setTabelaIcms(response.data.tabela_icms);
        }
        setOpenLoad(false);
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return(
                    <HeaderFiscal
                        cfopNota={cfopNota}
                        setCfopNota={setCfopNota}
                        numero={numero}
                        setNumero={setNumero} 
                        finalidade={finalidade}
                        setFinalidade={setFinalidade}
                        ref_nfe={ref_nfe}
                        setRefNfe={setRefNfe}
                    />
                );
            case 1:
                return(
                    <ClienteFiscal 
                        infoCliente={infoCliente}
                        setInfoCliente={setInfoCliente}
                        buttonSize={4}
                    />
                );
            case 2:
                return(
                    <ProdutoFiscal
                        arrayProdutos={arrayProdutos}
                        setArrayProdutos={setArrayProdutos}
                        somatoria={somatoria}
                        setSomatoria={setSomatoria}
                        modelo={55}
                        dadosFiscais={dados_fiscais}
                    />
                );
            case 3:
                return(
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <FreteFiscal
                                transporte={transporte} 
                                setTransporte={setTransporte} 
                                setInfoVeiculo={setInfoVeiculo} 
                                setInfoTransp={setInfoTransp}
                                setVolume={setVolume} 
                                setEspecie={setEspecie} 
                                setMarca={setMarca}
                                setNumeracao={setNumeracao} 
                                setPesoLiquido={setPesoLiquido} 
                                setPesoBruto={setPesoBruto}
                                infoTransp={infoTransp} 
                                infoVeiculo={infoVeiculo}
                                volume={volume}
                                especie={especie} 
                                marca={marca} 
                                numeracao={numeracao} 
                                peso_liquido={peso_liquido} 
                                peso_bruto={peso_bruto}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <PagamentoFiscal
                                pagamento={pagamento}
                                setPagamento={setPagamento}
                                numeroParcelas={numeroParcelas} 
                                setNumeroParcelas={setNumeroParcelas}
                                parcelas={parcelas}
                                setParcelas={setParcelas}
                                somatoria={somatoria}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={12} container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <TextField 
                                    fullWidth
                                    label="Adicional"
                                    variant="outlined" 
                                    value={adicional}
                                    onChange={(e) => setAdicional(e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            default:
                return 'Falha ao Exibir dados!';
        }
    }

    const handleSave = () => {
        AlertConfirm('', 'GRAVAR DADOS ATUAIS?', '', 'SIM').then(async(resp) => {
            if(resp.isConfirmed){
                setOpenLoad(true);
                const data = {
                    id: nfe_id,
                    id_empresa: infoEmpresa.id,
                    cfopNfe: cfopNota.cfop,
                    natureza: cfopNota.natureza,
                    numero,
                    finalidade,
                    ref_nfe,
                    cliente: JSON.stringify(infoCliente),
                    produtos: JSON.stringify(arrayProdutos),
                    totais: JSON.stringify(somatoria),
                    pagamento,
                    parcelas: JSON.stringify(parcelas),
                    transporte,
                    transp_id: (infoTransp && infoTransp.id_transporte) ? infoTransp.id_transporte : 0,
                    veiculo_id: (infoVeiculo && infoVeiculo.id_veiculos) ? infoVeiculo.id_veiculos : 0,
                    volume,
                    especie,
                    marca,
                    numeracao,
                    peso_liquido,
                    peso_bruto,
                    adicional,
                }
                let url;
                if(nfe_id){
                    url = `/nfe/alterar`;
                }else{
                    url = `/nfe/gravar`;
                }
                const response = await api.post(url, qs.stringify(data));
                console.log(response);
                setOpenLoad(false);
                if(response.status === 200){
                    AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                        history.push('/notafiscal');
                    });
                }else{
                    AlertInfo('error', 'FALHA AO GRAVAR DADOS!');
                }
            }
        });
    }

    const handleNext = () => {
        const data = {
            cfopNota,
            numero,
            finalidade,
            ref_nfe,
            infoCliente,
            arrayProdutos,
            pagamento,
            transporte,
            adicional,
        }
        const valid = ValidNFe(activeStep, data);
        if(!valid){
            if(activeStep === 3){
                handleSave();
            }else{
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }else{
            AlertInfo('error', valid.error, '');
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return(
        <Template menuActive="notafiscal">
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical" className={classes.steps}>
                    {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                        <Typography>{getStepContent(index)}</Typography>
                        <div className={classes.actionsContainer}>
                            <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                            >
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                {activeStep === steps.length - 1 ? 'Finalizar' : 'Proximo'}
                            </Button>
                            </div>
                        </div>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
            </div>
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}

export default NFe;