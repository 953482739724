import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SelectValueForLabel from '../../components/Select/SelectValueForLabel';
import AutoCompleteCfop from '../../components/Select/AutoCompleteCfop';
import { arrayCSOSN, arrayOrigem, arrayCST } from '../../data/options';

export default function Review(props) {
    const { statecrt, statecfop, statecsosn, statecst, stateorigem, statenextnfe,
        statenextnfce, statetoken, stateambiente, stateSerieNfe, stateSerieNFCe, tipopessoa } = props;

    const [crt, setCrt] = statecrt;
    const [cfop, setCfop] = statecfop;
    const [csosn, setCsosn] = statecsosn;
    const [cst, setCst] = statecst;
    const [origem, setOrigem] = stateorigem;
    const [nextnfe, setNextnfe] = statenextnfe;
    const [nextnfce, setNextnfce] = statenextnfce;
    const [token, setToken] = statetoken;
    const [ambiente, setAmbiente] = stateambiente;
    const [serie_nfe, setSerieNFe] = stateSerieNfe;
    const [serie_nfce, setSerieNFCe] = stateSerieNFCe;
    
    useEffect(() => {
        if(tipopessoa === "0") setSerieNFe("920");
        else setSerieNFe("1");
        // eslint-disable-next-line
    }, [tipopessoa]);

  return (
    <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
            <SelectValueForLabel 
                label="REGIME TRIBUTARIO"
                value={crt}
                setValue={setCrt}
                options={[
                    {value: 1, label: 'Simples Nacional'},
                    {value: 2, label: 'Simples Nacional, excesso sublimite de receita bruta'},
                    {value: 3, label: 'Regime Normal'},
                ]}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <SelectValueForLabel 
                label="CSOSN"
                value={csosn}
                setValue={setCsosn}
                options={arrayCSOSN}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <SelectValueForLabel 
                label="CST"
                value={cst}
                setValue={setCst}
                options={arrayCST}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <AutoCompleteCfop 
                value={cfop}
                setValue={setCfop}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <SelectValueForLabel 
                label="ORIGEM" 
                value={origem}
                setValue={setOrigem}
                options={arrayOrigem}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <SelectValueForLabel 
                label="AMBIENTE"
                value={ambiente}
                setValue={setAmbiente}
                options={[
                    {value: 1, label: 'PRODUÇÃO'},
                    {value: 2, label: 'HOMOLOGAÇÃO'},
                ]}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField 
                fullWidth
                type="number"
                label="SERIE NFe" 
                value={serie_nfe}
                onChange={(e) => setSerieNFe(e.target.value)}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField 
                fullWidth
                type="number"
                label="SERIE NFCe" 
                value={serie_nfce}
                onChange={(e) => setSerieNFCe(e.target.value)}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField 
                fullWidth
                type="number"
                label="PROXIMA NFe" 
                value={nextnfe}
                onChange={(e) => setNextnfe(e.target.value)}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField 
                fullWidth
                type="number"
                label="PROXIMA NFCe" 
                value={nextnfce}
                onChange={(e) => setNextnfce(e.target.value)}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField 
                fullWidth
                label="TOKEN NFCe" 
                value={token}
                onChange={(e) => setToken(e.target.value)}
            />
        </Grid>
    </Grid>
  );
}