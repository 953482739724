import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Template from '../../components/Template/Template';
import TableData from '../../components/Table/TableData';
import SearchList from '../../components/Search/SearchList';
import { AlertInfo } from '../../components/Alert/Alert';
import { displayId, displayMoney } from '../../services/displayValue';
import api from '../../services/api';

const Produtos = () => {
    const [activeSearch, setActivSearch] = useState(false);
    const [load, setLoad] = useState(false);
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const history = useHistory();
    
    const [rows, setRows] = useState([]);
    const crt = Number(infoEmpresa.crt);
    const columns = [
        { id: 'id', label: 'CODIGO', minWidth: 70, format: displayId },
        { id: 'descricao', label: 'DESCRIÇÃO', minWidth: 170 },
        { id: 'preco', label: 'PREÇO R$', minWidth: 170, format: displayMoney, align: 'right' },
        { id: 'cfop', label: 'CFOP', minWidth: 50 },
        { id: crt === 3 ? 'cst':'csosn', label: crt === 3 ? 'CST':'CSOSN', minWidth: 50 },
    ];

    const listAll = async() => {
        setLoad(true);
        try {
            const response = await api.get(`/produtos/listProdutos/${infoEmpresa.id}`);
            if(response && response.status === 200 && response.data){
                setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'Falha ao Buscar dados', 'Verifique a conexão com a internet');
        }
        setLoad(false);
    }

    const routeCadastro = async (json = {}) => {
        const location = {
            pathname: '/cadastroproduto',
            state: { infoProd: json },
        }
        history.push(location);
    }

    return (
        <Template menuActive="produtos">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => routeCadastro()}
                    >
                        NOVO
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={listAll}
                    >
                        LISTAR
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    {/* <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                    // onClick={listAll}
                    >
                        RELATORIOS
                    </Button> */}
                </Grid>
                <Grid item xs={false} sm={2} />
                <Grid item xs={12} sm={4} container justify="flex-end" alignItems="center">
                    <SearchList
                        active={activeSearch}
                        url={`/produtos/buscaProds/${infoEmpresa.id}`}
                        setList={setRows}
                        setLoad={setLoad}
                    />
                    <Fab
                        color="primary"
                        aria-label="add"
                        size="medium"
                        onClick={() => setActivSearch(!activeSearch)}
                    >
                        <FaSearch />
                    </Fab>
                </Grid>

                {rows.length > 0 && (
                    <>
                        <Grid item xs={12} sm={12}>
                            <TableData
                                columns={columns}
                                rows={rows}
                                clickFunction={routeCadastro}
                            />
                        </Grid>
                        <Grid item xs={false} sm={10}></Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                label="Total de Produtos"
                                variant="outlined"
                                inputProps={{ style: { textAlign: 'right' } }}
                                size="small"
                                value={rows.length}
                                onChange={() => { }}
                                readOnly
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <SimpleBackdrop open={load} />
        </Template>
    );
}

export default Produtos;