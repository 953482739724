import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import Template from '../../components/Template/Template';
import TableData from '../../components/Table/TableData';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { IconForCode } from '../../components/Icons/IconsCode';
import { displayId, displayMoney, displayDataBrl } from '../../services/displayValue';
import api, { mdfeApi, spedemdfURL } from '../../services/api';

const EmitirMdfe = () => {
    const [infoEmpresa] = useState(JSON.parse(sessionStorage.getItem('infoEmpresa')));
    const [openLoad, setOpenLoad] = useState(false);
    const [sequencia_aleatoria] = useState("14039718");
    const [modal, setModal] = useState("1");
    const [uf_start, setUfStart] = useState(infoEmpresa.uf);
    const [uf_end, setUfEnd] = useState("TO");
    const [data_hora_saida, setDataHoraSaida] = useState("");
    const [percurso, setPercurso] = useState([]);
    const dadosEntrega = [{
        codigomunicipio: '1721000',
        cidade: 'PALMAS',
        chnfe: ['15240317697635000192550010000005031844705762']
    }];

    async function teste() {
        const data = qs.stringify({
            dadosEmpresa: sessionStorage.getItem('infoEmpresa'),
            dadosFiscais: sessionStorage.getItem('dadosFiscais'),
            dadosMDFe: JSON.stringify({
                sequencia_aleatoria, modal, uf_start, uf_end, percurso
            }),
            dadosEntrega: JSON.stringify(dadosEntrega)
        });
        const response = await mdfeApi.post(`/mdfe/montaMdf`, data);
        console.log(response);
    }

    function damdfe() {
        window.open(`${spedemdfURL}/mdfe/damdfe`);
    }

    return(
        <Template menuActive="mdfe">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h1>TESTE</h1>
                </Grid>
                <Grid item xs={12}>
                    <button onClick={() => teste()}>TESTAR AGORA</button>
                </Grid>
                <Grid item xs={12}>
                    <button onClick={() => damdfe()}>DAMDFE</button>
                </Grid>
            </Grid>
            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}

export default EmitirMdfe;