import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTachometerAlt, FaUsers, FaFileCode, FaBoxes, FaFolderOpen } from 'react-icons/fa';
import { FaBuilding, FaTasks, FaFingerprint, FaCogs, FaWallet, FaHeadset } from 'react-icons/fa';
import { FaChevronCircleDown, FaChevronCircleUp, FaTruck, FaWhatsapp } from 'react-icons/fa';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import { SideArea, HeaderMenu, MenuItem, LinkMenu, ContainerSuporte, ContainerSideArea } from './Styled';
import { removeMask } from '../../services/mask';


const SideBar = ({ left, menuActive }) => {
    const [subMenu, setSubMenu] = useState(sessionStorage.getItem('subMenu') || null);
    const [infoRevenda] = useState(JSON.parse(sessionStorage.getItem('infoRevenda')) || null);

    const colapseMenu = (expand) => {
        setSubMenu((value) => {
            if(value === expand) {
                sessionStorage.setItem('subMenu', '');
                return null;
            }else{
                sessionStorage.setItem('subMenu', expand);
                return expand;
            }
        });
    }

    return(
        <SideArea left={left}>
            <ContainerSideArea>
                <div>
                    <HeaderMenu />
                    <Link to="/">
                        <MenuItem active={(menuActive === 'home') ? true : false}>
                            <LinkMenu>
                                <FaTachometerAlt/>
                                <Typography component="h6">INICIO</Typography>
                            </LinkMenu>
                        </MenuItem>
                    </Link>

                    <MenuItem onClick={() => colapseMenu('cadastros')}>
                        <LinkMenu>
                            <FaFolderOpen/>
                            <Typography component="h6">CADASTROS</Typography>
                        </LinkMenu>
                        {(subMenu === 'cadastros') ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                    </MenuItem>
                    <Collapse in={(subMenu ===  'cadastros') ? true : false}>
                        <Link to="/clientes">
                            <MenuItem active={(menuActive === 'clientes') ? true : false} subMenu>
                                <LinkMenu>
                                    <FaUsers/>
                                    <Typography component="h6">CLIENTES</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>

                        <Link to="/produtos">
                            <MenuItem active={(menuActive === 'produtos') ? true : false} subMenu>
                                <LinkMenu>
                                    <FaBoxes/>
                                    <Typography component="h6">PRODUTOS</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>

                        <Link to="/transportadoras">
                            <MenuItem active={(menuActive === 'transportadoras') ? true : false} subMenu>
                                <LinkMenu>
                                    <FaTruck/>
                                    <Typography component="h6">TRANSPORTADORAS</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>
                    </Collapse>

                    <Link to="/notafiscal">
                        <MenuItem active={(menuActive === 'notafiscal') ? true : false}>
                            <LinkMenu>
                                <FaFileCode/>
                                <Typography component="h6">NFe | NFCe</Typography>
                            </LinkMenu>
                        </MenuItem>
                    </Link>

                    {/* <Link to="/mdfe">
                        <MenuItem active={(menuActive === 'mdfe') ? true : false}>
                            <LinkMenu>
                                <FaFileCode/>
                                <Typography component="h6">MDFe</Typography>
                            </LinkMenu>
                        </MenuItem>
                    </Link> */}
                    
                    <MenuItem onClick={() => colapseMenu('config')}>
                        <LinkMenu>
                            <FaCogs/>
                            <Typography component="h6">DEFINIÇÕES</Typography>
                        </LinkMenu>
                        {(subMenu === 'config') ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                    </MenuItem>
                    <Collapse in={(subMenu ===  'config') ? true : false}>
                        <Link to="/certificado">
                            <MenuItem active={(menuActive === 'certificado') ? true : false} subMenu>
                                <LinkMenu>
                                    <FaFingerprint/>
                                    <Typography component="h6">CERTIFICADO DIGITAL</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>
                        <Link to="/dados">
                            <MenuItem active={(menuActive === 'dados') ? true : false} subMenu>
                                <LinkMenu>
                                    <FaTasks/>
                                    <Typography component="h6">DADOS FISCAIS</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>
                        <Link to="/configurar">
                            <MenuItem active={(menuActive === 'configurar') ? true : false} subMenu>
                                <LinkMenu>
                                    <FaCogs/>
                                    <Typography component="h6">CONFIGURAÇÕES</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>
                        <Link to="/empresa">
                            <MenuItem active={(menuActive === 'empresa') ? true : false} subMenu>
                                <LinkMenu>
                                    <FaBuilding/>
                                    <Typography component="h6">EMPRESA</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>
                        <Link to="/plano">
                            <MenuItem active={(menuActive === 'plano') ? true : false} subMenu>
                                <LinkMenu>
                                    <FaWallet/>
                                    <Typography component="h6">PAGAMENTOS</Typography>
                                </LinkMenu>
                            </MenuItem>
                        </Link>
                    </Collapse>
                </div>

                <div>
                    <ContainerSuporte
                        href={`https://wa.me/55${(infoRevenda && infoRevenda.id_painel) ? removeMask(infoRevenda.celular) : '9182287910'}`} 
                        target='_blank'
                        rel="noopener noreferrer"
                    >
                        <div>
                            <FaHeadset size={22} />
                            <span>SUPORTE: <br/> {(infoRevenda && infoRevenda.id_painel) ? infoRevenda.nome : 'RENOVE'}</span>
                        </div>
                        <div>
                            <FaWhatsapp size={22} />
                            <span>{(infoRevenda && infoRevenda.id_painel) ? infoRevenda.celular : '(91) 98228-7910'}</span>
                        </div>
                    </ContainerSuporte>
                </div>
            </ContainerSideArea>            
        </SideArea>
    );
}

export default SideBar;