import styled from 'styled-components';

export const CardContainer = styled.div`
    width: 100%;
    height: 150px;
    background-color: #a71a1a;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    padding: 2px 8px;
`;

export const LineOne = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const LineTwo = styled.div`
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LineThree = styled.div`
    text-align: end;
    font-size: 18px;
`;

export const Title = styled.div`
    font-size: 30px;
    font-weight: bold;
`;

export const NumberCenter = styled.div`
    font-size: 35px;
    font-weight: bold;
`;