import { createMuiTheme } from '@material-ui/core/styles';

const colorTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#a71a1a",
        },
        secondary: {
            main: "#000"
        },
        success: {
            main: "#059d6b"
        }
    },
});

export default colorTheme;