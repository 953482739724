import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from '../../services/api';

export default function AutoCompleteCity({ codigouf, value, setValue, ...rest }) {
    const [options, setOptions] = useState([]);

    // eslint-disable-next-line
    useEffect(() => loadCity(), [codigouf]);
    // eslint-disable-next-line
    useEffect(() => loadValue(), [value, options]);

    async function loadCity() {
        if(value && value.codigouf) setValue(null);
        const response  = await api.get(`/dados/listCidades/${codigouf}`);
        setOptions(response.data);
    }

    function loadValue() {
        if(value){
            if(!value.cidades && options.length > 0){
                const newValue = options.filter((city) => city.municipio === value);
                setValue(newValue[0]);
            }
        }
    }

    return (
        <Autocomplete
            value={(value) ? value : null}
            onChange={(event, newValue) => {
                console.log(newValue);
                setValue(newValue);
            }}
            options={options}
            getOptionLabel={(option) => option.cidades}
            fullWidth
            renderInput={(params) => (
                <TextField {...params} label="CIDADE" {...rest} />
            )}
        />
    );
}
